

import {computed, defineComponent, inject, onMounted} from "vue";
import {useStore} from "vuex";
import Toastify from "toastify-js";
import {useI18n} from "vue-i18n";

export default defineComponent({

  setup(){
    const authPlugin = inject<any>('authPlugin')
    const store= useStore()
    const loading = computed(() => store.state.user.loading)
    const status = computed(() => store.state.user.status)
    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const logout = ()=>{
      return authPlugin.logout()
    }

    onMounted(()=>{
      store.dispatch('user/GET_USER_STATUS')
    })

    const hideDropdown = () => {
      cash("#user-profile-dropdown").dropdown("hide")
    }


    const copyToken= () =>{
      const $i = cash("#user-profile-dropdown-input")
      if($i.length < 0){
        hideDropdown()
        return
      }
      store.dispatch('user/GET_TOKEN')
        .then((response)=>{
          $i.val(response.data.token)
          $i[0].select()
          var successful = document.execCommand('copy');
          if(successful){
            Toastify({ text: "Token Copied \n"+$i.val()+"!", duration: 3000, newWindow: true, close: true, gravity: "bottom", position: "middle", backgroundColor: "#c8447f", stopOnFocus: true }).showToast()
          }
        }).finally(()=>{
          hideDropdown()
      })

    }

    return {
      t,
      logout,
      status,
      loading,
      copyToken,
      hideDropdown
    }
  }

})

