<template>
  <div :id="identifier" class="modal">
    <div class="modal__content modal__content--graph modal__content--xl p-10">
      <h1 class="text-xl text-center font-bold text-theme-1 uppercase mt-5 mb-5 ">
        {{title}} <LoadingIcon icon="puff" class="loading loading__icon" v-show="loading"/>
      </h1>
      <div class="h-auto mb-7">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  props: {
    identifier: {
      type : String,
      required: true
    },
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },

  },
   setup(){
      return {}
    }
  })

</script>
