<template>

  <div
    class="text-tiny text-center p-1 bg-theme-1 hover:bg-theme-3 text-white absolute bottom-0 right-0 "
  >
    <Tippy
      tag="a"
      :href="`https://google.com/search?q=site:${source.hostname}+${query}`"
      target="_blank"
      class="p-3"
      v-if="!loading && source"
      content="discover more from this news source"
      :id="'discover-more-from-source-'+id"
    >
      {{source.name}}
    </Tippy>

    <LoadingIcon
      v-show="loading"
      color="#eceeee"
      icon="puff" class="loading loading__icon pl-2"/>

  </div>
</template>

<script lang="ts">

import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";


export default defineComponent({

  props: ['id','query','source_id'],
  setup(props){

    const store= useStore();
    const loading=ref(false)
    const source=ref(null)

    onMounted(() => {
      loading.value=true
      store.dispatch('sources/GET_SOURCE',props.source_id).then((response)=>{
        source.value= response.data
      }).finally(()=>{
        loading.value=false
      })
    })

    return {
      loading,
      source
    }
  }
})
</script>
