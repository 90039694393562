export const message = {
  "MENU_EVERYTHING": "Alles",
  "MENU_BACKING": "Fakten und Daten",
  "MENU_INDIVIDUAL": "Personen",
  "MENU_CASUAL": "Ursachen",
  "MENU_RAMIFICATION": "Konsequenzen",
  "FILTER_YEAR": "Zeit",
  "FILTER_ORDER": "Reihenfolge",
  "COPY_PLUGIN_TOKEN": "Plugin-Token kopieren",
  "USER_LOGOUT_TITLE": "Logout",
  "NOTIFICATIONS_TITLE": "Benachrichtigungen",
  "BTN_EXPLORE_MORE": "Mehr erforschen",
  "PILL_MODAL_WIKI": "Erkunden Sie die folgenden Artikel, die mit Ihren Themen und <em>{ title }</em> verknüpft sind",
  "BTN_BOOKMARK": "Lesezeichen",
  "EXPLANATION_LABEL_TITLE": "Was ist diese Karte?",
  "CARD_ARTICLE_DROPDOWN_COPY": "Artikelreferenz kopieren",
  "CARD_ARTICLE_DROPDOWN_REFRESH": "Mögliche Blickwinkel aktualisieren",
  "CARD_ARTICLE_DROPDOWN_WC": "Als Wortwolke anzeigen",
  "CARD_ARTICLE_DROPDOWN_SIMILAR": "Ähnliche Artikel über JECT.AI",
  "CARD_ARTICLE_DROPDOWN_SIMILAR_GOOGLE": "Ähnliche Artikel über Google",
  "CARD_ARTICLE_DROPDOWN_EXPLAIN": "Erkläre diese Karte",
  "CARD_COMBINATION_DROPDOWN_EXPLAIN": "Erkläre diese Karte",
  "CONTENT_COMBINATIONS_TITLE": "JECT.AI hat interessante Kombinationen von Themen identifiziert, über die man nachdenken sollte",
  "CARD_COMBINATION_EXPLORE": "Erforsche Nachrichtenwinkel, die <em>{ entities }</em> und <em>{ topic }</em> beinhalten könnten.",
  "CARD_COMBINATION_EXPLAIN": "JECT.AI hat diese Themen entdeckt, um sie aus Millionen von Artikeln zu kombinieren, die es entdeckt und indiziert hat. Die Artikel wurden aufgrund von berechneten Ähnlichkeiten zwischen Begriffen wie <em>{topic}</em> und den in den Artikeln genannten Themen gefunden. JECT.AI berechnete, dass jeder Artikel ausreichend ähnlich ist, basierend auf der von Ihnen gewählten Strategie und den von Ihnen definierten Einstellungen. Es wählte dann Themen aus, die in den meisten der abgerufenen Artikel berichtet wurden, und präsentierte sie.",
  "CARD_INTELLIGENCE_DROPDOWN_EXPLAIN": "Erkläre diese Karte",
  "CARD_INTELLIGENCE_TITLE": "JECT.AI hat verwandte Personen entdeckt, ausgeglichen nach Geschlecht und ethnischer Zugehörigkeit",
  "CARD_INTELLIGENCE_JOURNALISTS": "Entdecken Sie die verschiedenen Journalisten, die über verwandte Geschichten schreiben",
  "CARD_INTELLIGENCE_SCHOLARS": "Beschäftigen Sie sich mit der Bandbreite an Wissenschaftlern, die zu verwandten Forschungen beigetragen haben",
  "CARD_INTELLIGENCE_TWITTER": "Experten, die auf Twitter aktiv sind",
  "CARD_INTELLIGENCE_EXPLAIN_A": "JECT.AI hat diese Journalisten, Wissenschaftler und andere Experten entdeckt, die über Themen geschrieben und veröffentlicht haben, die mit Ihrem Thema oder Ihren Themen in Verbindung stehen. Die Journalisten sind die Namen von Journalisten, die Artikel geschrieben haben, die aus den Millionen von Artikeln entdeckt und indiziert wurden. Die Wissenschaftler und anderen Experten\n sind die Namen von Wissenschaftlern, die in Quellen wie Google Scholar als Autoren genannt werden.",
  "CARD_INTELLIGENCE_EXPLAIN_B": "Außerdem ermutigt JECT.AI zu vielfältigeren Stimmen in den Nachrichten. Es versucht, jede Gruppe von präsentierten Journalisten und Wissenschaftlern nach Geschlecht und ethnischer Zugehörigkeit auszugleichen, basierend auf ihren Namen.",
  "CARD_LANDING_DROPDOWN_ABOUT": "Über diese Karte",
  "CARD_LANDING_DROPDOWN_MORE": "Mehr Blickwinkel, über die nur wenige schreiben",
  "CARD_LANDING_DROPDOWN_EXPLORE": "Erforsche Themen an der Peripherie",
  "CARD_LANDING_TITLE": "JECT hat mehr als {total} verwandte Geschichten entdeckt",
  "CARD_LANDING_SUBTITLE": "<em>{total}</em> davon sind positiv",
  "CARD_LANDING_CONTENT_MANY": "Erforsche neue Blickwinkel, über die viele schreiben",
  "CARD_LANDING_CONTENT_SOME": "Erforsche neue Blickwinkel, über die einige schreiben",
  "CARD_LANDING_CONTENT_FEW": "Und neue Blickwinkel erkunden, über die nur wenige schreiben",
  "CARD_LANDING_EXTRA_CONTENT": "<em>{pastWeek}</em> wurden in der letzten Woche veröffentlicht,\n<em>{pastMonth}</em> im letzten Monat und\n{pastYear} im letzten Jahr, die meisten wurden in <em>{mostSources}</em> veröffentlicht",
  "CARD_LANDING_EXTRA_SUBTITLE": "Darüber hinaus bietet JECT auch eine Reihe von Platzhaltern, von denen einige nützlich sein könnten:",
  "CARD_LANDING_EXPLAIN_A": "JECT.AI hat diese Themen aus Millionen von Artikeln, die es entdeckt und indiziert hat, entdeckt. Die Artikel werden aufgrund von berechneten Ähnlichkeiten zwischen Begriffen wie <em>{ query }</em> und Themen, die in den Artikeln vorkommen, abgerufen.",
  "CARD_LANDING_EXPLAIN_B": "JECT.AI berechnete, dass jeder Artikel ausreichend ähnlich ist, basierend auf der von Ihnen gewählten Strategie und den von Ihnen definierten Einstellungen. Es berechnete dann Themen, die in vielen der Artikel, in einigen dieser Artikel und in einigen wenigen berichtet wurden",
  "TOOLTIP_QUEST_DASHBOARD": "Zurück zu den entdeckten Winkeln wechseln",
  "TOOLTIP_QUEST_EXPLORE": "Siehe Wissenschaftsjournalismus-Features",
  "DISCOVER_LOADING_MESSAGE": "JECT.AI entdeckt Inhalte für Sie",
  "TOOLTIP_DIMENSION_EVIDENCE": "Fakten und Daten - Quantitative Beweise (z. B. Zahlen und Maße)",
  "TOOLTIP_DIMENSION_INDIVIDUAL": "Personen - Wer sind die Hauptakteure? Die Sichtweise eines Individuums",
  "TOOLTIP_DIMENSION_CAUSAL": "Ursachen - Der Hintergrund, die Geschichte, was hat etwas verursacht",
  "TOOLTIP_DIMENSION_QUIRKY": "Ungewöhnliches - Satire, nicht das Offensichtliche (Karikaturen)",
  "TOOLTIP_DIMENSION_RAMIFICATION": "Konsequenzen - Was für die Zukunft?",
  "TOOLTIP_DIMENSION_VISUALISATION": "VISUALISIERUNG - Diagramme und Infografiken (demnächst)",
  "TOOLTIP_DIMENSION_ANY": "ALLES - Entdecken Sie ohne Grenzen",
  "QUEST_ABOUT_TITLE": "Über",
  "QUEST_ABOUT_CONTENT": "<p>JECT.AI bietet eine fachliche Anleitung für Journalisten, die über Wissenschaft schreiben. Diese Anleitung beinhaltet:</p>\n<ul class=\"list-disc ml-4 mb-5\">\n<li>Typische Zielgruppen für Wissenschaftsberichte, von den Begeisterten bis zu den Uninteressierten. Das Publikum zu kennen ist der Schlüssel zu einer effektiven Kommunikation</li>\n<li>Erprobte Metaphern, um Wissenschaft schnell zu kommunizieren. Die aktuellen Metaphern kommunizieren über Impfstoffe, Klimawandel und KI</li>\n<li>Indikatoren für guten Journalismus über Wissenschaft. Nutzen Sie diese, um über neue Artikel nachzudenken, sie zu verfeinern und zu verbessern, bevor Sie sie veröffentlichen</li>\n<li>Unterschiedliche Rollen, die Wissenschaft und Öffentlichkeit in Ihren Geschichten spielen können. Verwenden Sie sie, um überzeugendere Erzählungen zu entwickeln</li>\n<li>Einfache Erklärungen, die verwendet werden können, um wissenschaftliche Prozesse für ein nicht-wissenschaftliches Publikum zu beschreiben</li>\n</ul>\n<p>Dieser fachliche Leitfaden wurde vom EU-finanzierten H2020 QUEST-Projekt entwickelt. Erfahren Sie mehr über den Leitfaden, die Toolbox und die Podcasts hier [<a href=\"https://questproject.eu\" target=\"_blank\">https://questproject.eu</a>]</p>",
  "QUEST_PERSONAS_TITLE": "Science Audiences",
  "QUEST_PERSONAS_PILL_MORE": "Mehr",
  "QUEST_METAPHORS_TITLE": "Metaphern",
  "QUEST_STORY_TITLE": "Story-Rollen",
  "QUEST_EXPLAINERS_TITLE": "Explainers",
  "QUEST_INDICATORS_TITLE": "Indikatoren",
  "TOOLTIP_QUEST_FEATURE_PERSONA": "PERSONAS - Serie von wissenschaftsfeindlichen Leser-Personas",
  "TOOLTIP_QUEST_FEATURE_STORY_ROLE": "STORY ROLES - an den Wissenschaftsjournalismus angepasste Storytelling-Rollen ",
  "TOOLTIP_QUEST_FEATURE_EXPLAINER": "EXPLAINERS - Erklärungen zur Multi-Source-Forschung, zur Vertrauenswürdigkeit von Journalen und Peer-Review usw.",
  "TOOLTIP_QUEST_FEATURE_METAPHOR": "METAPHORS - Metaphern, um 1 oder 2 einfache Ideen effektiv zu kommunizieren",
  "TOOLTIP_QUEST_FEATURE_INDICATOR": "QUALITÄTSINDIKATOREN - Elemente der Praxisleistung, die zur Bewertung der Qualität verwendet werden",
  "LABEL_OPTIONS_PUBLISH_DATE": "Veröffentlichungsdatum",
  "LABEL_OPTIONS_PUBLISH_DATE_ANY": "Letzte Jahre",
  "LABEL_OPTIONS_PUBLISH_DATE_1YEAR": "Vergangenes Jahr",
  "LABEL_OPTIONS_PUBLISH_DATE_6MONTHS": "Vergangene 6 Monate",
  "LABEL_OPTIONS_PUBLISH_DATE_1MONTH": "Vergangener Monat",
  "LABEL_OPTIONS_PUBLISH_DATE_1WEEK": "Vergangene Woche",
  "LABEL_OPTIONS_PUBLISH_DATE_2DAYS": "Vergangene 48 Stunden",
  "LABEL_FILTER_PUBLISH_DATE_ANY": "Jahre",
  "LABEL_FILTER_PUBLISH_DATE_1YEAR": "Jahr",
  "LABEL_FILTER_PUBLISH_DATE_1MONTH": "Monat",
  "LABEL_FILTER_PUBLISH_DATE_1WEEK": "Woche",
  "LABEL_FILTER_PUBLISH_DATE_2DAYS": "48 Stunden",
  "LABEL_FILTER_PUBLISH_BREAKING": "Breaking",
  "LABEL_OPTIONS_SORT_TIME": "Zeit",
  "LABEL_OPTIONS_SORT_RELEVANCE": "Relevanz",
  "LABEL_OPTIONS_SORT_RANDOM": "Zufällig",
  "LABEL_OPTIONS_SORT_SENTIMENT_POS": "Positiv",
  "LABEL_OPTIONS_SORT_SENTIMENT_NEG": "Negativ",
  "LABEL_OPTIONS_SOURCE_SCIENCE": "Wissenschaft",
  "LABEL_OPTIONS_SOURCE_NEWS": "Nachrichten",
  "LABEL_OPTIONS_SOURCE_ALL": "Alle",
  "LABEL_OPTIONS_SOURCE": "Quelle",
  "PLACEHOLDER_SEARCH_BOX": "Beschreiben Sie Ihre Nachricht mit Themen",
  "PAYMENT": {
    "TITLE": "Preisgestaltung",
    "PRICE": "&euro;/mo",
    "PURCHASE": "JETZT KAUFEN",
    "FREELANCERS": {
      "TITLE": "FREELANCERS",
      "FEATURES": "<li>Echtzeit-Nachrichten-Aggregation &amp; Suche</li>\n<li>NLP-Anreicherung</li>\n<li>Kreativitäts- und Intelligenzunterstützung</li>\n<li>Prioritäre E-Mail-Unterstützung</li>\n<li>Zugang zum Help Center</li>"
    },
    "NEWSROOMS": {
      "TITLE": "NEWSROOMS",
      "FEATURES": "<li>Freelancing Plan</li>\n<li>Integrationen in gängige CMS ( Wordpress, et.al)</li>\n<li><em>beinhaltet bis zu 10 Benutzerlizenzen</em</li>"
    },
    "EDUCATIONAL": {
      "TITLE": "BILDUNGSEINRICHTUNGEN",
      "FEATURES": "<li>Freelancing Plan</li>\n<li>Integrationen in gängige CMS ( Wordpress, et.al)</li>\n<li><em>beinhaltet bis zu 10 Benutzerlizenzen</em</li>"
    }
  },
  "ACCOUNT_BOOKMARKS_MENU": "Lesezeichen",
  "BOOKMARKS_EMPTY": "Lesezeichen Leer",
  "BOOKMARK_META_DESCRIPTION": "Mit Lesezeichen am <em>{date}</em> markiert mit der Suchanfrage <strong>{query}</strong>",
  "PILL_MODAL_EMTPY_WIKI": "Für <em>{title}</em> gibt es nichts mehr zu erforschen"
}


