<template>
  <div class="dropdown" :id="id">
    <div
      class="dropdown-toggle w-12 h-8">
      <button
        class="dropdown-toggle inline-block bg-theme-1 text-white w-auto"
      >
        {{ selectedName }}
        <ChevronDownIcon class="w-4 h-4 ml-2 inline-block"></ChevronDownIcon>
      </button>
    </div>
    <div class="dropdown-box w-20 " id="id">
      <div class="dropdown-box__content box dark:bg-dark-1 p-2">
        <a v-for="(option) in data" :key="option.id"
           href="#"
           class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-purple-200 dark:hover:bg-dark-2 rounded-md"
           :class=" isSelected(option.id) ? 'bg-theme-1 text-white' : ''"
           @click.prevent="setSelected(option.id)"
        >{{ option.name }}</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import {definition} from "./dropdown";

export default defineComponent(definition())
</script>
