import {ActionContext, ActionTree} from "vuex";
import {State as RootState} from "@/store";
import {State as LocalState} from "./state";
import {Mutations} from "./mutations";
import {MutationTypes} from "./mutation-types";

// Action enums
enum ActionTypes {
  ADD = "ADD",
  READ = "READ",
}

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.READ]({commit}: AugmentedActionContext): void;
  [ActionTypes.ADD]({commit}: AugmentedActionContext, body: any): void;

}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.READ]({commit}: AugmentedActionContext) {
    commit(MutationTypes.READ,null)
  },
  [ActionTypes.ADD]({commit}: AugmentedActionContext, notification) {
    commit(MutationTypes.ADD, notification)

  }


};
