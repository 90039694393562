import {State as RootState} from "@/store";
import {GetterTree} from "vuex";
import {State as LocalState} from "./state";

// Getters types
export type Getters = {
  isEnabled(state: LocalState): any
  hasQuery(state: LocalState): any
  search(state: LocalState): any
  searchPath(state: LocalState): any
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  isEnabled: ((state) => (dimension) => {
    return state.dimensions[dimension] &&
      state.dimensions[dimension].data.total > 0

  }),
  hasQuery: ((state) => {
    const ret = state.search.query !== ''
    return ret
  }),
  search: ((state) => {
    return state.search
  }),

  hasResults: ((state) => {

    const keys = Object.keys(state.dimensions)
    let total =0

    keys.forEach((dimension)=>{
      total += state.dimensions[dimension].data.total
    })

    return total > 0
  }),
  searchPath: (() => (path, search) => {
    const url = `${path}?`
      + `q=${search.query}`
      + `&dimension=${search.dimension}`
      + `&time=${search.time}`
      + `&sort=${search.sort}`
      + `&category=${search.category}`
      + `&sources=${search.sources}`
      + `&countries=${search.countries}`
      + `&sourceLang=${search.sourceLang}`
      + `&lang=${search.lang}`
    return url
  })

};
