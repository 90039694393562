<template>
  <div
    :key="card.id"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box card card-landing"
    id="cards-{{ type }}-key"
  >
    <div
      class="flex justify-between border-b border-gray-200 px-5 py-4"
    >
      <div>
        <Tippy
          tag="a"
          href="#"
          v-if="viewState === 'primary'"
          class="w-5 h-5 graph-icon graph-icon-inline"
          content="Entities Frequency"
          :id="type+'-card-graph-1'"
          @click.prevent="showGraph('graph-1')"
        >
          <BarChart2Icon class="w-5 h-5"/>
        </Tippy>
        <Tippy
          tag="a"
          href="#"
          class="w-5 h-5 graph-icon graph-icon-inline ml-3"
          v-if="viewState === 'primary'"
          content="Sources Distribution"
          :id="type+'-card-graph-sources'"
          @click.prevent="showGraph('graph-sources')"
        >
          <DiscIcon class="w-5 h-5"/>
        </Tippy>
        <Tippy
          tag="a"
          href="#"
          v-if="viewState === 'primary'"
          class="w-5 h-5 graph-icon graph-icon-inline ml-3"
          content="Country Distribution"
          :id="type+'-card-graph-countries'"
          @click.prevent="showGraph('graph-countries')"
        >
          <PieChartIcon class="w-5 h-5"/>
        </Tippy>

        <Tippy
          tag="a"
          href="#"
          v-if="viewState === 'primary'"
          class="w-5 h-5 graph-icon graph-icon-inline ml-3"
          content="Sentiment over time"
          :id="type+'-card-graph-sentiment'"
          @click.prevent="showGraph('graph-sentiment')"
        >
          <ActivityIcon class="w-5 h-5"/>
        </Tippy>

      </div>
      <div>
        <div class=" pt-1 ml-3"
             v-if="viewState !== 'primary'">
          <a
            href=""
            class="w-5 h-5 text-gray-600 "
            @click.prevent="showState('primary')"
          >
            <XIcon class="w-5 h-5"/>
          </a>
        </div>
        <div class="dropdown pt-1 ml-3"
             :id="type+'-dropdown'"
             v-if="viewState === 'primary'">
          <a

            href="javascript:;"
            class="dropdown-toggle w-5 h-5 text-gray-600"
          >
            <MoreVerticalIcon class="w-5 h-5"/>
          </a>
          <div class="dropdown-box w-80">
            <div class="dropdown-box__content box p-2">
              <a
                href="#"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white rounded-md"
                @click.prevent="showState('explain')"
              >
                {{ t('CARD_LANDING_DROPDOWN_ABOUT') }}
              </a>
              <a
                href="#"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white rounded-md"
                @click.prevent="refreshKey()"
              >
                {{ t('CARD_LANDING_DROPDOWN_MORE') }}
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white rounded-md"
                @click.prevent="showState('extra')"
              >
                {{ t('CARD_LANDING_DROPDOWN_EXPLORE') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-5">
      <h1>{{ t('CARD_LANDING_TITLE', {'total': card.primary.totalStories}) }}</h1>

      <div v-if="viewState === 'primary'" class="text-base">
        <h3 v-html="t('CARD_LANDING_SUBTITLE',{'total':card.primary.positiveStories})"></h3>


        <h4 v-if="topEntities.length">{{ t('CARD_LANDING_CONTENT_MANY') }}</h4>
        <div class="card-pills">
          <CardPill
            v-for="(concept,conceptKey) in topEntities"
            :key="conceptKey"
            :title="concept.label"
            :type="concept['generic-type']"
            size="3"
            hasWiki="true"
            :id="type+'-top-'+conceptKey"
          />
          <Tippy
            v-if="topEntities.length  > 0"
            tag="span"
            content="Visualize entities over time"
            class="pill pill__graph"
            :id="type+'-top-entities-card-graph-2-content'"
          >
            <a
              href="#"
              class="pill__action"
              @click.prevent="showEntitiesGraph(topEntities,'top')">
              <ActivityIcon class="inline-block w-5 h-5"/>
            </a>
          </Tippy>
        </div>

        <h4 v-if="middleEntities.length">{{ t('CARD_LANDING_CONTENT_SOME') }}</h4>
        <div class="card-pills">
          <CardPill
            v-for="(concept,conceptKey) in middleEntities"
            :key="conceptKey"
            :title="concept.label"
            :type="concept['generic-type']"
            size="3"
            hasWiki="true"
            :id="type+'-middle-'+conceptKey"
          />
          <Tippy
            v-if="middleEntities.length  > 0"
            tag="a"
            href="#"
            content="Visualize entities over time"
            class="graph-pill"
            :id="type+'-middle-entities-card-graph-3-content'"
            @click.prevent="showEntitiesGraph(middleEntities,'middle')"
          >
            <ActivityIcon class="inline-block w-5 h-5"/>
          </Tippy>
        </div>

        <h4 v-if="bottomEntities.length">{{ t('CARD_LANDING_CONTENT_FEW') }}</h4>
        <div class="card-pills">
          <CardPill
            v-for="(concept,conceptKey) in bottomEntities"
            :key="conceptKey"
            :title="concept.label"
            :type="concept['generic-type']"
            size="3"
            hasWiki="true"
            :id="type+'-bottom-'+conceptKey"

          />
          <Tippy
            v-if="bottomEntities.length  > 0"
            tag="a"
            href="#"
            content="Visualize entities over time"
            class="graph-pill"
            :id="type+'-bottom-entities-card-graph-4-content'"
            @click.prevent="showEntitiesGraph(bottomEntities,'bottom')"
          >
            <ActivityIcon class="inline-block w-5 h-5"/>
          </Tippy>
        </div>
      </div>

      <div v-if="viewState === 'extra'">

        <p class="mb-3"
           v-html="t('CARD_LANDING_EXTRA_CONTENT',{'pastWeek':card.secondary.publishedDates.pastWeek,'pastMonth':card.secondary.publishedDates.pastMonth,'pastYear':card.secondary.publishedDates.pastYear,'mostSources':mostSources})"></p>
        <p class="mb-3">{{ t('CARD_LANDING_EXTRA_SUBTITLE') }}</p>
        <div class="card-pills mt-3">
          <CardPill
            v-for="(concept,conceptKey) in card.secondary.entities.wildcard"
            :key="conceptKey"
            :title="concept.label"
            :type="concept['generic-type']"
            size="3"
            hasWiki="true"
            :id="type+'-secondary-'+conceptKey"
          />

        </div>
      </div>
      <div v-if="viewState === 'explain'">
        <h3 class="block font-bold mb-5">{{ t('EXPLANATION_LABEL_TITLE') }}</h3>
        <p v-html="t('CARD_LANDING_EXPLAIN_A', {'query':query})"></p>
        <p>
          {{ t('CARD_LANDING_EXPLAIN_B') }}
        </p>
      </div>


      <VisualizationDialog
        identifier="graph-1-superlarge-modal-size-preview"
        title="JECT has discovered how many of the articles each angle is mentioned in"
      >
        <ul class="spark-graph spark-graph__top">
          <li>Think about angles that are reported less frequently than others</li>
          <li>Think about angles inspired by large differences in the number of articles reporting topics</li>
        </ul>

        <Chart
          class="js-chart"
          v-if="graphData"
          type="bar"
          :data="graphData"
          :height="150"
          :options="options"
        />

      </VisualizationDialog>


      <VisualizationDialog
        identifier="graph-entities-superlarge-modal-size-preview"
        title="JECT has discovered how many articles were reported in over time"
      >
        <ul class="spark-graph spark-graph__top">
          <li>Think about exploring angles that are receiving increasing coverage</li>
          <li>Think about exploring angles that have more reported more frequently previously, to revisit them</li>
          <li/>
        </ul>

        <VisualizationControls
          @click.prevent="toggleGraphSelection('entitiesGraphData')"
          :state="graphSelected"
        />
        <Chart
          type="line"
          :width="400"
          :height="200"
          :data="entitiesGraphData.data"
          :options="entitiesGraphData.options"
        />
      </VisualizationDialog>

      <VisualizationDialog
        identifier="graph-sources-superlarge-modal-size-preview"
        title="JECT has discovered stories from the following publication
          sources"
      >
        <ul class="spark-graph">
          <li>
            Think about articles from less common publication sources
          </li>
        </ul>
        <Chart
          v-if="sourcesGraph && sourcesGraph.data"
          type="doughnut"
          :data="sourcesGraph.data"
          :width="400"
          :height="200"
          :options="sourcesGraph.options"
        />

        <p class="text-center font-bold">Sources Distributiion</p>
        <p class="text-xs italic text-center"> ( showing > 3% ) </p>
      </VisualizationDialog>

      <VisualizationDialog
        identifier="graph-countries-superlarge-modal-size-preview"
        title="JECT has discovered stories in publications from the following
          countries"
      >
        <ul class="spark-graph">
          <li>
            Think about how articles from {{ countriesGraph.lowCountries[0] }}, {{ countriesGraph.lowCountries[1] }} or
            {{ countriesGraph.lowCountries[2] }} report the topic. What new angles emerge?
          </li>
          <li>
            Contrast how articles from {{ countriesGraph.countries[1] }} and {{ countriesGraph.lowCountries[0] }} report
            the topic, to discover new angles
          </li>
        </ul>
        <Chart
          v-if="countriesGraph.data"
          type="doughnut"
          :data="countriesGraph.data"
          :width="400"
          :height="200"
          :options="countriesGraph.options"
        />


      </VisualizationDialog>

      <VisualizationDialog
        identifier="graph-sentiment-superlarge-modal-size-preview"
        title="JECT has discovered stories that, overall, were more positive and
          negative during the time periods shown"
      >
        <Chart
          v-if="sentimentGraph.data"
          type="line"
          :data="sentimentGraph.data"
          :width="400"
          :height="150"
          :options="sentimentGraph.options"
        />
        <ul class="spark-graph">
          <li>
            Think about new angles that convey sentiments that are different to most of the coverage of the topic
          </li>
          <li>
            What new ideas does this sentiment profile give you?
          </li>
        </ul>

      </VisualizationDialog>


    </div>


  </div>
</template>
<script lang="ts">

import {inject, ref, defineComponent, computed, onMounted} from 'vue';
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import moment from "moment";
import VisualizationDialog from "@/views/dimensions/cards/VisualizationDialog.vue";
import VisualizationControls from "@/views/dimensions/cards/VisualizationControls.vue";


export default defineComponent({
  components: {VisualizationControls, VisualizationDialog},
  props: ['type', 'card', 'query'],
  component: [
    VisualizationDialog,
    VisualizationControls
  ],
  setup(props) {
    const {t} = useI18n()
    const store = useStore()
    const lodash = inject<any>('lodash')
    const topEntities = computed(() => props.card.primary.entities.top)
    const middleEntities = computed(() => props.card.primary.entities.middle)
    const bottomEntities = ref([])
    const timeFilter = computed(() => store.state.main.search.time)


    const setRandomEntities = () => {
      bottomEntities.value = lodash.sampleSize(props.card.primary.entities.bottom, 9);
    }
    const refreshKey = () => {
      setRandomEntities()
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }


    const viewState = ref('primary')
    const showState = (state) => {
      viewState.value = state
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }

    const mostSources = computed(() => {
      return props.card.secondary.mostSourceNames.slice(0, 3).join(", ")
    })

    onMounted(() => {
      setRandomEntities()
    })

    const graphData = computed(() => {
      const ret = {
        labels: [],
        datasets: [{
          label: 'Frequency',
          barPercentage: 2,
          barThickness: 6,
          maxBarThickness: 8,
          hidden:false,
          minBarLength: 2,
          backgroundColor: [],
          data: []
        },
        ]
      }

      const pos = ["top", "middle", "bottom"]
      pos.forEach((a) => {

        ret.labels = ret.labels.concat(lodash.map(props.card.primary.entities[a], (e) => e.label))
        ret.datasets[0].data = ret.datasets[0].data.concat(lodash.map(props.card.primary.entities[a], (e) => e.count))
      })

      for (const i in ret.labels) {
        const color = Math.floor(Math.random() * 16777215).toString(16)
        ret.datasets[0].backgroundColor.push("#" + color)
      }

      return ret

    })
    const entitiesGraphData = ref(
      {
        "data": {'datasets':[]},
        "options": {
          legend: {
            display: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        }
      }
    );
    const showGraph = (graph: string) => {
      graphSelected.value=true
      cash(`#${graph}-superlarge-modal-size-preview`).modal("show");
    }
    const showEntitiesGraph = (entities, graph: string) => {

      const data = {
        labels: [],
        datasets: [],
      };

      // Add all the timestamps for all the entities in this section
      entities.forEach((e) => {
        Object.keys(e.ts).forEach((k) => {

          let key = k
          if (timeFilter.value.startsWith('year')) {
            const parts = k.split('-')
            key = parts[0] + '-' + parts[1]
          }

          if (data.labels.indexOf(key) < 0) {
            data.labels.push(key)
          }
        })
      })


      data.labels = lodash.sortBy(data.labels)
      entities.forEach((e) => {

        const d = {
          label: e.label,
          data: [],
          borderWidth: 2,
          borderColor: "#" + (Math.floor(Math.random() * 16777215).toString(16)),
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        }


        const dates = Object.keys(e.ts)
        const groupedTs = {}

        dates.forEach((ts) => {

          let key = ts
          if (timeFilter.value.startsWith('year')) {
            const parts = ts.split('-')
            key = parts[0] + '-' + parts[1]
          }
          if (key in groupedTs) {
            groupedTs[key] = groupedTs[key] + e.ts[ts]
          } else {
            groupedTs[key] = e.ts[ts]
          }
        })

        data.labels.forEach((k) => {
          d.data.push(
            groupedTs[k] || 0
          )
        })

        data.datasets.push(d)
      })
      entitiesGraphData.value = {
        "data": data,
        "options": {
          legend: {
            display: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        }
      }
      cash('#graph-entities-superlarge-modal-size-preview').modal("show");

    }

    const sourcesGraph = computed(() => {

      let sources = null

      try {
        sources = store.state.main.dimensions[store.state.main.search.dimension].data.cards.visualization.sources
      } catch (e) {

      }


      const data = {
        labels: [],
        datasets: [{
          label: "Sources",
          backgroundColor: [],
          hoverBackgroundColor: [],
          borderWidth: 5,
          borderColor: "#ffff",
          hidden:false,
          data: []
        }]
      }

      if (sources == null) {
        return {
          data: data,
          options: {
            cutoutPercentage: 40,
          }
        }
      }

      const totalSum = lodash.sumBy(sources.items, (e) => {
        return e.quantity
      })

      lodash.reduce(sources.items, (e, n) => {
        if (n.quantity / totalSum > 0.03) {
          e.push(n.name)
        }
        return e
      }, data.labels)

      data.labels.forEach((name) => {
        data.datasets[0].data.push(
          lodash.find(sources.items, (i) => i.name === name).quantity || 0
        )
      })

      for (const i in data.labels) {
        const color = Math.floor(Math.random() * 16777215).toString(16)
        data.datasets[0].backgroundColor.push("#" + color)
      }


      return {
        data: data,
        options: {
          cutoutPercentage: 40,
        }
      }


    })
    const countriesGraph = computed(() => {

      let countryData = null

      try {
        countryData = store.state.main.dimensions[store.state.main.search.dimension].data.graph.country || null

      } catch (e) {
      }

      if (countryData == null) {
        return {
          data: {datasets:[]},
          countries: [],
          lowCountries: [],
          options: {
            cutoutPercentage: 40,
          }
        }
      }

      const data = {
        labels: lodash.map(countryData, (e) => {
          if (e.key === null) {
            return 'None'
          }
          return e.key
        }),
        datasets: [{
          backgroundColor: [
            "#FF8B26",
            "#FFC533",
            "#285FD3",
            "#c8447f",
            "#20272d",
            "#E6F3FF",
            "#2449AF",
            "#284EB2",
            "#395EC1"
          ],
          hoverBackgroundColor: [
            "#FF8B26",
            "#FFC533",
            "#285FD3",
            "#c8447f",
            "#20272d",
            "#E6F3FF",
            "#2449AF",
            "#284EB2",
            "#395EC1"
          ],
          hidden:false,
          borderWidth: 5,
          borderColor: "#fff",
          data: lodash.map(countryData, (e) => {
            return parseInt(e.value)
          })
        },
        ]
      }

      const countries = data.labels.filter((e) => {
        return e !== 'None'
      })

      return {
        data: data,
        countries: countries,
        lowCountries: [countries.pop(), countries.pop(), countries.pop()],
        options: {
          cutoutPercentage: 40,
        }
      }


    })
    const sentimentGraph = computed(() => {

      const sentimentData = store.state.main.dimensions[store.state.main.search.dimension].data.graph.sentiment || null

      const data = {
        labels: lodash.map(sentimentData, (e) => {
          return moment(e.time).format("DD/MM/YYYY");
        }),
        datasets: [{
          label: 'Sentiment',
          barPercentage: 0.5,
          barThickness: 6,
          fill: false,
          maxBarThickness: 8,
          minBarLength: 2,
          backgroundColor: "#3160d8",
          data: lodash.map(sentimentData, (e) => {
            return e.value
          })

        },
        ]
      }

      return {
        data: data,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
                callback: function (value) {
                  return value;
                }
              },
              gridLines: {
                color: "#D8D8D8",
                zeroLineColor: "#D8D8D8",
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false
              }
            }]
          }
        }
      }


    })
    const graphSelected=ref(true)

    const toggleGraphSelection = (graph) => {

      if(graph === 'graphData'){
        // graphData.value.datasets.forEach( e=>{
        //   e.hidden=graphSelected.value
        // })
      }

      if(graph === 'entitiesGraphData'){
        entitiesGraphData.value.data.datasets.forEach( e=>{
          e.hidden=graphSelected.value
        })
      }

      if(graph === 'sourcesGraph'){
        // sourcesGraph.value.data.datasets[0].labels.forEach( e=>{
        //
        //   console.log(e)
        //   e.hidden=graphSelected.value
        // })
      }

      if(graph === 'countriesGraph'){
        // countriesGraph.value.data.datasets.forEach( e=>{
        //   e.hidden=graphSelected.value
        // })
      }


      graphSelected.value = !graphSelected.value
    }


    return {
      t,
      refreshKey,
      viewState,
      showState,
      topEntities,
      middleEntities,
      bottomEntities,
      mostSources,
      sourcesGraph,
      countriesGraph,
      sentimentGraph,
      showGraph,
      showEntitiesGraph,
      entitiesGraphData,
      graphSelected,
      toggleGraphSelection,
      graphData,
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              fontSize: "12",
              fontColor: "#777777",
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              fontSize: "12",
              fontColor: "#777777",
              callback: function (value) {
                return value;
              }
            },
            gridLines: {
              color: "#D8D8D8",
              zeroLineColor: "#D8D8D8",
              borderDash: [2, 2],
              zeroLineBorderDash: [2, 2],
              drawBorder: false
            }
          }]
        }
      }
    }
  }
})
</script>
