import {ActionContext, ActionTree} from "vuex";
import {State as RootState} from "@/store";
import {State as LocalState} from "./state";
import {Mutations} from "./mutations";
import {MutationTypes} from "./mutation-types";
import {client as JECT} from "@/utils/ject";

// Action enums
enum ActionTypes {
  GET_USER_STATUS = "GET_USER_STATUS",
  GET_TOKEN = "GET_TOKEN",
  TOKEN_LOGIN = "TOKEN_LOGIN"
}

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_USER_STATUS]({commit}: AugmentedActionContext): void;
  [ActionTypes.GET_TOKEN]({commit}: AugmentedActionContext): void;
  [ActionTypes.TOKEN_LOGIN]({commit}: AugmentedActionContext, token:string): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_USER_STATUS]({commit}: AugmentedActionContext) {
    console.log("[AUTH Store] Getting User Status")
    commit(MutationTypes.LOADING, true)
    JECT.user( )
      .then((response)=>{
        console.log(response.data)
        commit(MutationTypes.SET_STATUS,response.data)
      })
      .catch((error)=>{
        console.error(error)
        commit(MutationTypes.SET_STATUS, {'active':false})
      })
      .finally(()=>{
        commit(MutationTypes.LOADING, false)
      })
  },
 [ActionTypes.GET_TOKEN]({commit}: AugmentedActionContext) {

    commit(MutationTypes.LOADING, true)
   console.log("[AUTH Store] Getting Token")
   return new Promise((resolve,reject) => {
      console.log("Copying PLUGIN TOKEN")
      JECT.token()
        .then((response)=>{
          resolve(response)
        })
        .catch((error)=>{
          reject(error)
        })
        .finally(()=>{
          commit(MutationTypes.LOADING, false)
        })

    })
  },
  [ActionTypes.TOKEN_LOGIN]({commit}: AugmentedActionContext, token:string) {

    commit(MutationTypes.LOADING, true)
    console.log("[AUTH Store] Token Login")
    return new Promise((resolve,reject) => {
      console.log("Setting PLUGIN TOKEN",token)
      commit(MutationTypes.SET_TOKEN, token)

      JECT.user( )
        .then((response)=>{
          commit(MutationTypes.SET_USER,response.data)
        })
        .catch((error)=>{
          console.error(error)
          commit(MutationTypes.SET_TOKEN, '')
          commit(MutationTypes.SET_USER, false)
        })
        .finally(()=>{
          commit(MutationTypes.LOADING, false)
        })

    })
  }

};
