
import {defineComponent, ref} from 'vue';

export default defineComponent({
    props:['select','state'],
    setup(props){
      return {
      }
    }
  })

