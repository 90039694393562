

import {computed, defineComponent} from 'vue';

export default defineComponent({
  props:['sentiment'],
  setup(props){

    const theme =computed(()=> {
      if(props.sentiment === 'positive'){
        return 'text-theme-9'
      }
      if(props.sentiment === 'negative'){
        return 'text-theme-6'
      }
      if(props.sentiment === 'neutral'){
        return 'text-theme-1'
      }

      return 'text-black'

    })
    const icon = computed(()=>{
      if(props.sentiment === 'positive'){
        return 'smile'
      }
      if(props.sentiment === 'negative'){
        return 'frown'
      }
      if(props.sentiment === 'neutral'){
        return 'meh'
      }

      return 'meh'
    })

    return {
      icon,
      theme,
    }
  }
})

