import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

// Getters types
export type Getters = {
  all(state:LocalState):any
};


// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  all: (state: LocalState) => {
    return state.sources
  }

};
