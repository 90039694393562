export const message = {

  "MENU_EVERYTHING": "Tutto",
  "MENU_BACKING": "Prove",
  "MENU_INDIVIDUAL": "Persone",
  "MENU_CASUAL": "Causale",
  "MENU_RAMIFICATION": "Conseguenze",
  "FILTER_YEAR": "Tempo",
  "FILTER_ORDER": "Ordine",
  "COPY_PLUGIN_TOKEN": "Copia token del plugin",
  "USER_LOGOUT_TITLE": "Logout",
  "NOTIFICATIONS_TITLE": "Notifiche",
  "BTN_EXPLORE_MORE": "Esplora di più",
  "PILL_MODAL_WIKI": "Esplora i seguenti articoli che collegano <em>{ titolo }</em> ai tuoi argomenti",
  "BTN_BOOKMARK": "Segnalibro",
  "EXPLANATION_LABEL_TITLE": "Cos'è questa scheda?",
  "CARD_ARTICLE_DROPDOWN_COPY": "Copia riferimento articolo",
  "CARD_ARTICLE_DROPDOWN_REFRESH": "Aggiorna i possibili angoli",
  "CARD_ARTICLE_DROPDOWN_WC": "Mostra come nuvola di parole",
  "CARD_ARTICLE_DROPDOWN_SIMILAR": "Storie simili tramite JECT.AI",
  "CARD_ARTICLE_DROPDOWN_SIMILAR_GOOGLE": "Storie simili tramite Google",
  "CARD_ARTICLE_DROPDOWN_EXPLAIN": "Spiega questa carta",
  "CARD_COMBINATION_DROPDOWN_EXPLAIN": "Spiega questa scheda",
  "CONTENT_COMBINATIONS_TITLE": "JECT.AI ha identificato interessanti combinazioni di argomenti su cui riflettere",
  "CARD_COMBINATION_EXPLORE": "Esplora gli angoli di notizie che potrebbero coinvolgere <em>{ entità }</em> e <em>{ argomento }</em>",
  "CARD_COMBINATION_EXPLAIN": "JECT.AI ha scoperto questi argomenti da combinare da milioni di articoli che ha scoperto e indicizzato. Gli articoli vengono recuperati a causa delle somiglianze calcolate tra termini come <em>{topic}</em> e gli argomenti riportati negli articoli. JECT.AI ha calcolato che ogni articolo è sufficientemente simile in base alla strategia che hai selezionato e alle impostazioni che hai definito. Ha quindi selezionato e presentato gli argomenti riportati nella maggior parte degli articoli recuperati",
  "CARD_INTELLIGENCE_DROPDOWN_EXPLAIN": "Spiega questa scheda",
  "CARD_INTELLIGENCE_TITLE": "JECT.AI ha scoperto persone affini, bilanciate per sesso ed etnia",
  "CARD_INTELLIGENCE_JOURNALISTS": "Esplora i diversi giornalisti che stanno scrivendo di storie correlate",
  "CARD_INTELLIGENCE_SCHOLARS": "Impegnati con l'ampiezza degli scienziati che hanno contribuito alla ricerca correlata",
  "CARD_INTELLIGENCE_TWITTER": "Esperti che sono attivi su Twitter",
  "CARD_INTELLIGENCE_EXPLAIN_A": "JECT.AI ha scoperto questi giornalisti, scienziati e altri esperti che hanno scritto su temi pubblicati relativi al tuo argomento o ai tuoi argomenti. I giornalisti sono i nomi dei giornalisti che hanno scritto articoli scoperti tra i milioni di articoli che ha scoperto e indicizzato. Gli scienziati e altri esperti sono i nomi degli scienziati nominati come autori in fonti come Google Scholar",
  "CARD_INTELLIGENCE_EXPLAIN_B": "Inoltre, JECT.AI incoraggia voci più diverse nelle notizie. Cerca di bilanciare ogni serie di giornalisti e scienziati presentati per genere e per etnia, sulla base dei loro nomi",
  "CARD_LANDING_DROPDOWN_ABOUT": "Informazioni su questa scheda",
  "CARD_LANDING_DROPDOWN_MORE": "Altri angoli di cui scrivere",
  "CARD_LANDING_DROPDOWN_EXPLORE": "Esplora gli angoli del jolly",
  "CARD_LANDING_TITLE": "JECT ha scoperto più di {totale} storie correlate",
  "CARD_LANDING_SUBTITLE": "<em>{totale}</em> di queste sono positive",
  "CARD_LANDING_CONTENT_MANY": "Esplora nuovi angoli di cui molti scrivono",
  "CARD_LANDING_CONTENT_SOME": "Esplora nuovi angoli di cui alcuni scrivono",
  "CARD_LANDING_CONTENT_FEW": "Ed esplora nuovi angoli di cui solo pochi scrivono",
  "CARD_LANDING_EXTRA_CONTENT": "<em>{pastWeek}</em> sono stati pubblicati nell'ultima settimana,\n<em>{pastMonth}</em> nell'ultimo mese e \n{pastYear} nell'ultimo anno, la maggior parte sono stati pubblicati in <em>{mostSources}</em>",
  "CARD_LANDING_EXTRA_SUBTITLE": "Inoltre, JECT offre anche una serie di angoli jolly, alcuni dei quali potresti trovare utili:",
  "CARD_LANDING_EXPLAIN_A": "JECT.AI ha scoperto questi argomenti a partire da milioni di articoli che ha scoperto e indicizzato. Gli articoli sono stati recuperati a causa delle somiglianze computerizzate tra termini come <em>{ query }</em> e gli argomenti riportati negli articoli.",
  "CARD_LANDING_EXPLAIN_B": "JECT.AI ha calcolato che ogni articolo è sufficientemente simile in base alla strategia che hai selezionato e alle impostazioni che hai definito. Ha poi calcolato gli argomenti riportati in molti degli articoli, alcuni di questi articoli, e solo alcuni",
  "TOOLTIP_QUEST_DASHBOARD": "Passa di nuovo agli angoli scoperti",
  "TOOLTIP_QUEST_EXPLORE": "Vedi le caratteristiche del giornalismo scientifico",
  "DISCOVER_LOADING_MESSAGE": "JECT.AI sta scoprendo contenuti per te",
  "TOOLTIP_DIMENSION_EVIDENCE": "PROVE - Prove quantitative (per esempio numeri e misure)",
  "TOOLTIP_DIMENSION_INDIVIDUAL": "Persone - Chi sono gli attori principali? Il punto di vista di un individuo",
  "TOOLTIP_DIMENSION_CAUSALE": "CAUSALE - Lo sfondo, la storia, cosa ha causato qualcosa",
  "TOOLTIP_DIMENSION_QUIRKY": "Bizzarro - Satira, non l'ovvio (vignette)",
  "TOOLTIP_DIMENSION_RAMIFICATION": "Conseguenze - Cosa per il futuro?",
  "TOOLTIP_DIMENSION_VISUALISATION": "Visualizzazione - Grafici e infografiche (in arrivo)",
  "TOOLTIP_DIMENSION_ANY": "TUTTO - Scoprire senza limiti",
  "QUEST_ABOUT_TITLE": "Di",
  "QUEST_ABOUT_CONTENT": "<p>JECT.AI fornisce una guida specializzata ai giornalisti che scrivono di scienza. Questa guida include:</p>\n<ul class=\"list-disc ml-4 mb-5\">\n<li>Pubblico tipico per le storie di scienza, dall'entusiasta al disimpegnato. Conoscere il proprio pubblico è la chiave per comunicare efficacemente</li>. <li>Metafore collaudate per comunicare rapidamente la scienza. Le metafore attuali comunicano sui vaccini, sul cambiamento climatico e sull'IA</li>\n<li>Indicatori del buon giornalismo sulla scienza. Usali per riflettere sui nuovi articoli, per raffinarli e migliorarli, prima di pubblicarli</li>\n<li>Diversi ruoli che la scienza e il pubblico possono giocare nelle tue storie. Usali per sviluppare narrazioni più avvincenti</li>\n<li>Semplici spiegazioni che possono essere usate per descrivere i processi scientifici a un pubblico non scientifico</li>\n</ul>\n<p>Questa guida specializzata è stata sviluppata dal progetto H2020 QUEST, finanziato dall'UE. Per saperne di più sulla guida, gli strumenti e i podcast qui [<a href=\"https://questproject.eu\" target=\"_blank\">https://questproject.eu</a>]</p>",
  "QUEST_PERSONAS_TITLE": "Pubblico scientifico",
  "QUEST_PERSONAS_PILL_MORE": "Di più",
  "QUEST_METAPHORS_TITLE": "Metafore",
  "QUEST_STORY_TITLE": "Ruoli della storia",
  "QUEST_EXPLAINERS_TITLE": "Spiegatori",
  "QUEST_INDICATORS_TITLE": "Indicatori",
  "TOOLTIP_QUEST_FEATURE_PERSONA": "PERSONAGGI - serie di personalità di lettori che rifiutano la scienza",
  "TOOLTIP_QUEST_FEATURE_STORY_ROLE": "STORY ROLES - ruoli narrativi adattati al giornalismo scientifico",
  "TOOLTIP_QUEST_FEATURE_EXPLAINER": "EXPLAINERS - spiegazioni della ricerca multi-fonte, di come le riviste e la peer-review possono essere attendibili, ecc",
  "TOOLTIP_QUEST_FEATURE_METAPHOR": "METAFORE - metafore per comunicare efficacemente 1 o 2 idee semplici",
  "TOOLTIP_QUEST_FEATURE_INDICATOR": "INDICATORI DI QUALITÀ - elementi di performance della pratica utilizzati per valutare la qualità",
  "LABEL_OPTIONS_PUBLISH_DATE": "Data di pubblicazione",
  "LABEL_OPTIONS_PUBLISH_DATE_ANY": "Anni passati",
  "LABEL_OPTIONS_PUBLISH_DATE_1YEAR": "Anno passato",
  "LABEL_OPTIONS_PUBLISH_DATE_6MONTHS": "Ultimi 6 mesi",
  "LABEL_OPTIONS_PUBLISH_DATE_1MONTH": "Mese passato",
  "LABEL_OPTIONS_PUBLISH_DATE_1WEEK": "Ultima settimana",
  "LABEL_OPTIONS_PUBLISH_DATE_2DAYS": "Ultime 48 ore",
  "LABEL_FILTER_PUBLISH_DATE_ANY": "anni",
  "LABEL_FILTER_PUBLISH_DATE_1YEAR": "anno",
  "LABEL_FILTER_PUBLISH_DATE_1MESE": "mese",
  "LABEL_FILTER_PUBLISH_DATE_1WEEK": "settimana",
  "LABEL_FILTER_PUBLISH_DATE_2DAYS": "48 ore",
  "LABEL_FILTER_PUBLISH_BREAKING": "Interruzione",
  "LABEL_OPTIONS_SORT_TIME": "Tempo",
  "LABEL_OPTIONS_SORT_RELEVANCE": "Rilevanza",
  "LABEL_OPTIONS_SORT_RANDOM": "Casuale",
  "LABEL_OPTIONS_SORT_SENTIMENT_POS": "Positivo",
  "LABEL_OPTIONS_SORT_SENTIMENT_NEG": "Negativo",
  "LABEL_OPTIONS_SOURCE_SCIENCE": "Scienza",
  "LABEL_OPTIONS_SOURCE_NEWS": "Notizie",
  "LABEL_OPTIONS_SOURCE_ALL": "Tutti",
  "LABEL_OPTIONS_SOURCE": "Fonte",
  "PLACEHOLDER_SEARCH_BOX": "Descrivi la tua notizia con gli argomenti",
  "PAYMENT": {
    "TITLE": "PREZZO",
    "PRICE": "&euro;/mo",
    "PURCHASE": "ACQUISTA ORA",
    "FREELANCERS": {
      "TITLE": "FREELANCERS",
      "FEATURES": "<li>Aggregazione di notizie in tempo reale &amp; ricerca</li>\n<li>Arricchimento NLP</li>\n<li>Supporto creatività e intelligenza</li>\n<li>Supporto email prioritario</li>\n<li>Accesso centro assistenza</li>"
    },
    "NEWSROOMS": {
      "TITLE": "NOTIZIE",
      "FEATURES": "<li>Piano di freelance</li> <li>Integrazioni nei principali CMS (Wordpress, ecc.) </li> <li><em>include fino a 10 licenze utente</em></li>"
    },
    "EDUCATIONAL": {
      "TITLE": "ISTITUZIONI EDUCATIVE",
      "FEATURES": "<li>Piano di freelance</li> <li>Integrazioni nei principali CMS (Wordpress, ecc.) </li> <li><em>include fino a 10 licenze utente</em></li>"
    }
  },
  "ACCOUNT_BOOKMARKS_MENU": "Segnalibri",
  "BOOKMARKS_EMPTY": "Segnalibri Vuoto",
  "BOOKMARK_META_DESCRIPTION": "È stato inserito nei preferiti  <em>{date}</em> utilizzando la query <strong>{query}</strong>",
  "PILL_MODAL_EMTPY_WIKI": "Non c'è più nulla da esplorare per <em>{title}</em>"
}

