import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

import Main from "../layouts/main/Main.vue";
import Dimensions from "../views/dimensions/Main.vue";
import Bookmarks from "../views/dimensions/Bookmarks.vue";
import ErrorPage from "../views/error-page/Main.vue";
import Quest from "../views/quest/Main.vue";
import Payment from "../views/payment/Main.vue";
import Plugin from "../views/plugin/Main.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "main",
    component: Main,
    props: (route) => {

      return {
        time: route.query.time || 'month',
        sort: route.query.sort || 'relevance',
        category: route.query.source || 'news|science',
        sources: route.query.source || '',
        countries: route.query.countries || '',
        sourceLang: route.query.sourceLang || 'en',
        lang: route.query.lang || 'en',
        q: route.query.q || '',
        dimension: route.query.dimension || 'all'
      }
    },
    children:[
      {
        path: "",
        name: "dimension",
        component: Dimensions,
      },
      {
        path: "bookmarks",
        name: "bookmarks",
        component: Bookmarks
      },
      {
        path: "payment",
        name: "payment",
        component: Payment,
      },
      {
        path: "quest",
        name: "quest",
        component: Quest,
      },
    ]
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
  },
  {
    path: "/plugin",
    name: "plugin",
    component: Plugin,
    props: (route) => {
      return {
        error:route.query.error || false
      }
    }
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {left: 0, top: 0};
  },
});


export default router;
