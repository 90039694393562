<template>
  <div class="dropdown"
      id="user-profile-dropdown"
  >
    <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
    >
      <img
          :alt="$auth.user.value.name"
          :src="$auth.user.value.picture"
      />
    </div>
    <div class="dropdown-box w-56 ">
      <div
          class="dropdown-box__content box bg-white dark:bg-dark-6 text-theme-1"
      >
        <div class="p-4 border-b border-theme-4 ">
          <div class="inline-block">
            <div class="font-lg text-theme-3">{{ $auth.user.value.name }}</div>
            <div class="text-xs text-theme-3 mt-0.5 dark:text-gray-600">
              {{ $auth.user.value.email }}
            </div>
          </div>
          <div class="inline-block ml-3 h-full" v-if="loading"
          >
            <LoadingIcon
              color="#c8447f"
              icon="puff" class="loading loading__userinfo_icon"/>
          </div>
        </div>
        <div class="p-2"
            v-if="!status.active"
        >
          <router-link
            :to="{ name: 'payment' }"
            tag="a"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 hover:text-white rounded-md"
            @click="hideDropdown"

          >
                <CreditCardIcon class="w-4 h-4 mr-2"/>
                Subscribe Now!
          </router-link>
        </div>
        <div class="p-2" >

          <router-link
            :to="{ name: 'bookmarks' }"
            tag="a"
            class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 hover:text-white dark:hover:bg-dark-3 rounded-md"
          >
            <BookmarkIcon class="w-4 h-4 mr-2"/>
            {{ t('ACCOUNT_BOOKMARKS_MENU')}}
          </router-link>

        </div>
        <div class="p-2">
          <a
              href="#"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 hover:text-white dark:hover:bg-dark-3 rounded-md"
              @click.prevent="copyToken"
          >
            <CodepenIcon class="w-4 h-4 mr-2"/>
            {{ t('COPY_PLUGIN_TOKEN')}}
          </a>
          <input id="user-profile-dropdown-input" class="color-black" value="token" hidden/>
        </div>
        <div class="p-2 border-t border-theme-40">

          <a
              v-if="$auth.isAuthenticated"
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 hover:text-white dark:hover:bg-dark-3 rounded-md"
              @click.prevent="logout"
          >
            <ToggleRightIcon class="w-4 h-4 mr-2"/>
            {{ t('USER_LOGOUT_TITLE') }}
          </a>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts">

import {computed, defineComponent, inject, onMounted} from "vue";
import {useStore} from "vuex";
import Toastify from "toastify-js";
import {useI18n} from "vue-i18n";

export default defineComponent({

  setup(){
    const authPlugin = inject<any>('authPlugin')
    const store= useStore()
    const loading = computed(() => store.state.user.loading)
    const status = computed(() => store.state.user.status)
    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const logout = ()=>{
      return authPlugin.logout()
    }

    onMounted(()=>{
      store.dispatch('user/GET_USER_STATUS')
    })

    const hideDropdown = () => {
      cash("#user-profile-dropdown").dropdown("hide")
    }


    const copyToken= () =>{
      const $i = cash("#user-profile-dropdown-input")
      if($i.length < 0){
        hideDropdown()
        return
      }
      store.dispatch('user/GET_TOKEN')
        .then((response)=>{
          $i.val(response.data.token)
          $i[0].select()
          var successful = document.execCommand('copy');
          if(successful){
            Toastify({ text: "Token Copied \n"+$i.val()+"!", duration: 3000, newWindow: true, close: true, gravity: "bottom", position: "middle", backgroundColor: "#c8447f", stopOnFocus: true }).showToast()
          }
        }).finally(()=>{
          hideDropdown()
      })

    }

    return {
      t,
      logout,
      status,
      loading,
      copyToken,
      hideDropdown
    }
  }

})

</script>
