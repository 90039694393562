<template>

  <!-- BEGIN: Notifications -->
  <div class="intro-x dropdown relative mr-auto" id="global-notifications">
    <a
      class="dropdown-toggle notification cursor-pointer"
      :class=" unread > 0 ? 'notification--bullet' : '' "
    >
      <BellIcon class="notification__icon dark:text-gray-300"/>

    </a>
    <div class="notification-content pt-2 dropdown-box show">
      <div
        class="notification-content__box dropdown-box__content box dark:bg-dark-6"
      >
        <div class="notification-content__title">{{ t('NOTIFICATIONS_TITLE') }} ({{total}})</div>
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="cursor-pointer relative flex items-center"
          :class="{ 'mt-5': notification.id }"
        >
          <div class="ml-2 overflow-hidden">
            <div class="flex items-center">
              <p class="font-medium truncate mr-5">
                {{notification.title}}<br/>
                <span class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                  {{ time(notification.when) }}
                </span>
              </p>
            </div>
            <div class="w-full truncate text-gray-600 mt-0.5">
                {{notification.content}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Notifications -->

</template>

<script lang="ts">

import {computed, defineComponent, inject, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

export default defineComponent({

  setup(){
    const timeago = inject<any>('ta')
    const store = useStore()
    const openned = ref(false)
    const notifications = computed( () => store.state.notifications.notifications)
    const unread = computed( () => store.getters['notifications/unread'])
    const total = computed( () => store.getters['notifications/total'])
    const { t } = useI18n()

    onMounted(()=>{
      cash("#global-notifications").on("shown", ()=>{
        store.dispatch("notifications/READ")

      })
    })

    const time = (dateStr) =>{
      return timeago.format(Date.parse(dateStr))
    }

    return {
      t,
      unread,
      total,
      notifications,
      openned,
      time
    }

  }
})

</script>

