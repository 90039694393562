import {App} from "vue";


const postMessage = (id, data) => {
  window.top.postMessage({
    'id':'ject.ai.message.'+id,
    'when': new Date(),
    'value': data
  }, '*')
}

const insertText = (text) => {
  postMessage('insertText',text);
}

const bus = {
  insertText
}
const install = (app: App): void => {
  app.config.globalProperties.$pluginBus = bus
  app.provide("plugin.eventbus", bus);
};


export { install as default };
