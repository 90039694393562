<template>
  <div class="content">
    <span class="search--loader" v-show="bookmarks.length <=0 ">
      <p class="inline-block p-5 rounded-md bg-white bg-opacity-25 text-3xl text-white ">
        {{ t('BOOKMARKS_EMPTY') }}
      </p>
    </span>

    <h1 class="text-3xl text-white font-black my-5" v-if="bookmarks.length > 0 ">Bookmarks</h1>

    <div ref='scrollComponent' v-show="bookmarks.length > 0 ">
      <div class="intro-y grid grid-cols-12 gap-6 ">
        <ArticleComponent
            v-for="(bookmark) in bookmarks"
            :id="bookmark.content.article.id"
            :article="bookmark.content.article"
            :key="bookmark.content.article.id"
            dimension="bookmark"
            class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box"
            :query="bookmark.content.query"
            :bookmarkDate="bookmark.created"
        />
      </div>
    </div>
  </div>


</template>

<script lang="ts">
import {computed, defineComponent, onMounted, onUnmounted, onActivated, ref} from "vue";
import ArticleComponent from "./Article.vue";
import {useStore} from "@/store";
import {linkTo} from "@/layouts/side-menu";
import {filters} from "@/views/dimensions/filters";
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {
    ArticleComponent,
  },
  created() {
    const store = useStore();
    store.dispatch('main/LOAD_MORE_BOOKMARKS', null)
  },
  setup() {
    const store = useStore();
    const scrollComponent = ref(null)
    const query = computed(() => {
      return store.state.main.search.query
    })
    const bookmarks = computed(() => store.state.main.bookmarks.displayed)

    const handleScroll = () => {
      const element = scrollComponent.value
      if (element.getBoundingClientRect().bottom - 300 < window.innerHeight) {
        store.dispatch('main/LOAD_MORE_BOOKMARKS', null)
      }
    }

    onActivated(()=>{
      console.log("Activated")

    })


    onMounted(() => {
      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })


    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
    return {
      query,
      bookmarks,
      scrollComponent,
      filters,
      linkTo,
      t
    }
  }

});
</script>
