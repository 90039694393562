import {reactive} from "vue";

export const state = {
  loading: false,
  status:{
    active:false
  },
  token: '',
  auth:{
    isAuthenticated: false,
    user: false,
    popupOpen: false,
    error: null,
    loading: false,
  }
};


// Declare state
export type State = typeof state;
