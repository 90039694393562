// Mutations enums
export enum MutationTypes {
  SELECT_DIMENSION = "SELECT_DIMENSION",
  SELECT_LANGUAGE = "SELECT_LANGUAGE",
  SET_DIMENSION_DATA = "SET_DIMENSION_DATA",
  CLEAR_DIMENSION_DATA = "CLEAR_DIMENSION_DATA",
  CLEAR_DATA = "CLEAR_DATA",
  SET_SEARCH_LOADING = "SET_SEARCH_LOADING",
  DIMENSION_SET_STATE = "DIMENSION_SET_STATE",
  SET_GRAPH = "SET_GRAPH",
  SET_CURRENT_PAGE = "SET_CURRENT_PAGE",
  SET_SEARCH = "SET_SEARCH",
  SET_CARD_DATA = "SET_CARD_DATA",
  BOOKMARK = "BOOKMARK",
  BOOKMARKS = "BOOKMARKS",
  BOOKMARKS_PAGE = "BOOKMARKS_PAGE",
  BOOKMARKS_CLEAR = "BOOKMARKS_CLEAR"
}
