import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

// Getters types
export type Getters = {
  transform(state:LocalState):any
};


// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  transform: (state: LocalState):(string | number)[][] =>  {

    return state.data.map(word => [word.el, word.count*20]);
  },

};
