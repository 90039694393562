export const message = {
  "MENU_EVERYTHING": "Alt",
  "MENU_BACKING": "BAKGRUNN",
  "MENU_INDIVIDUAL": "PERSON",
  "MENU_CASUAL": "ÅRSAK",
  "MENU_RAMIFICATION": "KONSEKVENS",
  "FILTER_YEAR": "Tid",
  "FILTER_ORDER": "Bestilling",
  "COPY_PLUGIN_TOKEN": "Kopier plugin-token",
  "USER_LOGOUT_TITLE": "Logg ut",
  "NOTIFICATIONS_TITLE": "Varsler",
  "BTN_EXPLORE_MORE": "Utforsk mer",
  "PILL_MODAL_WIKI": "Utforsk følgende artikler som knytter <em>{title}</em> til emnene dine",
  "BTN_BOOKMARK": "Bokmerke",
  "EXPLANATION_LABEL_TITLE": "Hva er dette kortet?",
  "CARD_ARTICLE_DROPDOWN_COPY": "Kopier artikkelhenvisning",
  "CARD_ARTICLE_DROPDOWN_REFRESH": "Oppdater mulige vinkler",
  "CARD_ARTICLE_DROPDOWN_WC": "Vis som ordsky",
  "CARD_ARTICLE_DROPDOWN_SIMILAR": "Lignende historier via JECT.AI",
  "CARD_ARTICLE_DROPDOWN_SIMILAR_GOOGLE": "Lignende historier via Google",
  "CARD_ARTICLE_DROPDOWN_EXPLAIN": "Forklar dette kortet",
  "CARD_COMBINATION_DROPDOWN_EXPLAIN": "Forklar dette kortet",
  "CONTENT_COMBINATIONS_TITLE": "JECT.AI har identifisert interessante kombinasjoner av emner å tenke på",
  "CARD_COMBINATION_EXPLORE": "Utforsk nyhetsvinkler som kan involvere <em>{entities}</em> og <em>{topic}</em>.",
  "CARD_COMBINATION_EXPLAIN": "JECT.AI har oppdaget disse emnene for å kombinere fra millioner av artikler som de har oppdaget og indeksert. Artiklene blir hentet på grunn av beregnet likhet mellom termer som <em>{topic}</em> og rapporterte emner i artiklene. JECT.AI beregnet at hver artikkel er tilstrekkelig lik basert på strategien du valgte og innstillingene du definerte. Deretter valgte og presenterte emner rapportert i de fleste av de hentede artiklene ",
  "CARD_INTELLIGENCE_DROPDOWN_EXPLAIN": "Forklar dette kortet",
  "CARD_INTELLIGENCE_TITLE": "JECT.AI har oppdaget relaterte mennesker, balansert etter kjønn og etnisitet",
  "CARD_INTELLIGENCE_JOURNALISTS": "Utforsk de forskjellige journalistene som skriver om relaterte historier",
  "CARD_INTELLIGENCE_SCHOLARS": "Engasjer deg med bredden av forskere som har bidratt til relatert forskning",
  "CARD_INTELLIGENCE_TWITTER": "Eksperter som er aktive på Twitter",
  "CARD_INTELLIGENCE_EXPLAIN_A": "JECT.AI har oppdaget disse journalistene, forskerne og andre eksperter som har skrevet på publisert på ntemaer relatert til temaet eller emnene dine. Journalistene er navnene på journalister som har skrevet artikler oppdaget fra millioner av artikler at den har oppdaget og indeksert. Forskerne og andre eksperter nar navnene på forskere som er oppkalt som forfattere i kilder som Google Scholar. ",
  "CARD_INTELLIGENCE_EXPLAIN_B": "Videre oppfordrer JECT.AI til flere varierte stemmer i nyheter. Den søker å balansere hvert sett presenterte journalister og forskere etter kjønn og etter etnisitet, basert på navnene deres.",
  "CARD_LANDING_DROPDOWN_ABOUT": "Om dette kortet",
  "CARD_LANDING_DROPDOWN_MORE": "Flere vinkler få skriver om",
  "CARD_LANDING_DROPDOWN_EXPLORE": "Utforsk vinkler med jokertegn",
  "CARD_LANDING_TITLE": "JECT har oppdaget mer enn {totalt} relaterte historier",
  "CARD_LANDING_SUBTITLE": "<em>{total}</em> av disse er positive",
  "CARD_LANDING_CONTENT_MANY": "Utforsk nye vinkler som mange skriver om",
  "CARD_LANDING_CONTENT_SOME": "Utforsk nye vinkler som noen skriver om",
  "CARD_LANDING_CONTENT_FEW": "Og utforsk nye vinkler som bare noen få skriver om",
  "CARD_LANDING_EXTRA_CONTENT": "<em>{pastWeek}</em> ble publisert den siste uken, \n <em>{pastMonth}</em> den siste måneden og \n {pastYear} det siste året, mest ble publisert i <em>{mostSources}</em> ",
  "CARD_LANDING_EXTRA_SUBTITLE": "Videre tilbyr JECT også et sett med jokertegnvinkler, hvorav noen kan være nyttige:",
  "CARD_LANDING_EXPLAIN_A": "JECT.AI har oppdaget disse emnene fra millioner av artikler som de har oppdaget og indeksert. Artiklene blir hentet på grunn av beregnet nlikhet mellom ord som <em>{query}</em> og rapporterte emner i artiklene. ",
  "CARD_LANDING_EXPLAIN_B": "JECT.AI beregnet at hver artikkel er tilstrekkelig lik basert på strategien du valgte og innstillingene du definerte. Den beregnet deretter emner rapportert i mange av artiklene, noen av disse artiklene og bare noen få",
  "TOOLTIP_QUEST_DASHBOARD": "Bytt tilbake til oppdagede vinkler",
  "TOOLTIP_QUEST_EXPLORE": "Se vitenskapelig journalistikkfunksjoner",
  "DISCOVER_LOADING_MESSAGE": "JECT.AI oppdager innhold for deg",
  "TOOLTIP_DIMENSION_EVIDENCE": "BAKGRUNN – Bakgrunnsstoff i form av målingar og tal",
  "TOOLTIP_DIMENSION_INDIVIDUAL": "PERSON – Finn nøkkelpersonar knytt til eit emne eller ein person",
  "TOOLTIP_DIMENSION_CAUSAL": "ÅRSAK – Den underliggande historia. Kva førte til situasjonen eller handlinga?",
  "TOOLTIP_DIMENSION_QUIRKY": "UNDERLEG – Særeigen satire og karikaturteikningar (Kjem snart!)",
  "TOOLTIP_DIMENSION_RAMIFICATION": "KONSEKVENS – Kva har situasjonen, temaet eller hendinga å seie for framtida? (Kjem snart!)",
  "TOOLTIP_DIMENSION_VISUALISATION": "VISUALISERING – Grafar og infografikk. (Kjem snart!)",
  "TOOLTIP_DIMENSION_ANY": "ALT - Oppdag uten grenser",
  "QUEST_ABOUT_TITLE": "Om",
  "QUEST_ABOUT_CONTENT": "<p> JECT.AI gir spesialveiledning til journalister som skriver om vitenskap. Denne veiledningen inkluderer: </p> \n <ul class = \" list-disc ml-4 mb-5 \">\n<li>Typiske målgrupper for vitenskapshistorier, fra entusiastiske til frikoblede. Å vite publikum er nøkkelen til å kommunisere effektivt</li>\n<li>Testede metaforer for å kommunisere vitenskap raskt. De nåværende metaforene kommuniserer om vaksiner , klimaendringer og AI</li>\n<li>Indikatorer for god journalistikk om vitenskap. Bruk dem til å reflektere over nye artikler, for å foredle og forbedre dem, før du publiserer</li>\n<li>Ulike roller som vitenskapen og publikum kan spille i historiene dine. Bruk dem til å utvikle mer overbevisende fortellinger</li>\n<li>Enkle forklarere som kan brukes til å beskrive vitenskapelige prosesser for ikke-vitenskapelige publikum </li> \n </ul> \n <p> Denne spesialveiledningen ble utviklet av det EU-finansierte H2020 QUEST-prosjektet. Lær mer om veiledning, verktøykasse og podcaster her [<a href = \"https: // questproje ct.eu \"target = \" _blank \"> https://questproject.eu </a>] </p>",
  "QUEST_PERSONAS_TITLE": "Science Audiences",
  "QUEST_PERSONAS_PILL_MORE": "Mer",
  "QUEST_METAPHORS_TITLE": "Metaforer",
  "QUEST_STORY_TITLE": "Historieroller",
  "QUEST_EXPLAINERS_TITLE": "Forklarere",
  "QUEST_INDICATORS_TITLE": "Indikatorer",
  "TOOLTIP_QUEST_FEATURE_PERSONA": "PERSONAS - serie med vitenskapelig avvisende leserpersoner",
  "TOOLTIP_QUEST_FEATURE_STORY_ROLE": "STORY ROLES - historieroller tilpasset vitenskapsjournalistikk",
  "TOOLTIP_QUEST_FEATURE_EXPLAINER": "FORKLARERE - forklaringer på flerkildeforskning, hvordan tidsskrifter og fagfellevurderinger kan stole på osv.",
  "TOOLTIP_QUEST_FEATURE_METAPHOR": "METAPHORS - metaforer for å kommunisere 1 eller 2 enkle ideer effektivt",
  "TOOLTIP_QUEST_FEATURE_INDICATOR": "KVALITETSINDIKATORER - elementer av praksisytelse som brukes til å vurdere kvaliteten",
  "LABEL_OPTIONS_PUBLISH_DATE": "Publiseringsdato",
  "LABEL_OPTIONS_PUBLISH_DATE_ANY": "De siste årene",
  "LABEL_OPTIONS_PUBLISH_DATE_1YEAR": "Siste år",
  "LABEL_OPTIONS_PUBLISH_DATE_6MONTHS": "Siste 6 måneder",
  "LABEL_OPTIONS_PUBLISH_DATE_1MONTH": "Forrige måned",
  "LABEL_OPTIONS_PUBLISH_DATE_1WEEK": "Forrige uke",
  "LABEL_OPTIONS_PUBLISH_DATE_2DAYS": "De siste 48 timene",
  "LABEL_FILTER_PUBLISH_DATE_ANY": "år",
  "LABEL_FILTER_PUBLISH_DATE_1YEAR": "år",
  "LABEL_FILTER_PUBLISH_DATE_1MONTH": "måned",
  "LABEL_FILTER_PUBLISH_DATE_1WEEK": "uke",
  "LABEL_FILTER_PUBLISH_DATE_2DAYS": "48 timer",
  "LABEL_FILTER_PUBLISH_BREAKING": "Breaking",
  "LABEL_OPTIONS_SORT_TIME": "Tid",
  "LABEL_OPTIONS_SORT_RELEVANCE": "Relevans",
  "LABEL_OPTIONS_SORT_RANDOM": "Tilfeldig",
  "LABEL_OPTIONS_SORT_SENTIMENT_POS": "Positivt",
  "LABEL_OPTIONS_SORT_SENTIMENT_NEG": "Negativt",
  "LABEL_OPTIONS_SOURCE_SCIENCE": "Vitenskap",
  "LABEL_OPTIONS_SOURCE_NEWS": "Nyheter",
  "LABEL_OPTIONS_SOURCE_ALL": "Alt",
  "LABEL_OPTIONS_SOURCE": "Kilde",
  "PLACEHOLDER_SEARCH_BOX": "Beskriv nyhetshistorien din med emner",
  "PAYMENT": {
    "TITLE": "Prissetting",
    "PRICE": "& euro; / mo",
    "PURCHASE": "KJØP NÅ",
    "FREELANCERS": {
      "TITLE": "FREELANCERS",
      "FEATURES": "<li> Nyhetsaggregering i sanntid og søk</li>\n<li>NLP-berikelse</li>\n<li>Kreativitet og intelligensstøtte</li>\n<li>Prioritet e-poststøtte</li>\n<li>Tilgang til brukerstøtten </li> "
    },
    "NEWSROOMS": {
      "TITLE": "NYHETSRUM",
      "FEATURES": "<li> Frilanseringsplan</li>\n<li>Integrasjoner i store CMS (Wordpress, et.al)</li>\n<li><em> inkluderer opptil 10 brukerlisenser </ em> </li> "
    },
    "EDUCATIONAL": {
      "TITLE": "UTDANNINGSINSTITUSJONER",
      "FEATURES": "<li> Frilanseringsplan</li>\n<li>Integrasjoner i store CMS (Wordpress, et.al)</li>\n<li><em> inkluderer opptil 10 brukerlisenser </ em> </li> "
    }
  },
  "ACCOUNT_BOOKMARKS_MENU": "Bokmerker",
  "BOOKMARKS_EMPTY": "Bokmerker tomme",
  "BOOKMARK_META_DESCRIPTION": "Har blitt bokmerket <em>{date}</em> ved hjelp av spørring <strong>{query}</strong>",
  "PILL_MODAL_EMTPY_WIKI": "Det er ingenting mer å utforske for <em>{title}</em>"
}



