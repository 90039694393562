<template>
   <span
     class="pill"
     :class="bg"
     :name="identifier+'-root'"
   >
      <a href="#"
         class="pill__action pill__action_sm hidden plugin:inline-block pl-2"
         @click.prevent="insertText()">
        <Edit2Icon size="12"/>
      </a>
     <a
       class="pill__action"
       href="#"
       @click.prevent="show(identifier)"
       :name="identifier"
     >
             {{ title }}
      </a>
      <div class="tooltip-content ">

           <TippyContent  :to="identifier" :options="{interactive: true}">
              <div class="relative flex items-center py-1 text-left spark spark__light">
                <div class="ml-4 mr-auto h-auto w-80">
                  <h1 class="text-3xl text-theme-1 uppercase">
                    <span class="mr-2">{{ title }}</span>
                    <LoadingIcon icon="puff"
                                 class="loading loading__icon" v-show="loading"/>
                    <AlertCircleIcon class="feather-icon" v-show="!loading && error"/>
                  </h1>
                  <div class="font-medium dark:text-gray-300 leading-relaxed items-center">
                  </div>
                  <div class="text-black"></div>
                  <hr class="mt-3 mb-3"/>
                  <div class="text-gray-600 mb-5" v-html="html">
                  </div>
                  <div class="h-30 text-center " v-show="html !== ''" >
                      <a href="javascript:;"
                         data-toggle="modal"
                         v-show="!hideExplore"
                         :data-target="'#'+identifier+'-superlarge-modal-size-preview'"
                         class="button mr-1 mb-2 inline-block plugin:hidden"
                         @click="entity(id)"
                      >{{ t('BTN_EXPLORE_MORE') }}</a>
                     <div :id="identifier+'-superlarge-modal-size-preview'" class="modal">
                         <div class="modal__content modal__content--wiki modal__content--xl p-10">
                            <h1 class="text-3xl text-theme-1 uppercase mt-5 mb-5">
                              {{entityHtmlTitle}}<LoadingIcon icon="puff" class="loading loading__icon" v-show="loading"/>
                            </h1>
                            <div class="h-auto mb-7" v-html="entityHtml">
                            </div>
                           <div class="h-auto" v-show="!loading">

                             <div v-if="entityLinks.length > 0">
                               <h2 v-html="t('PILL_MODAL_WIKI', {'title':title})"></h2>
                               <ul>
                                 <li class="pb-3" v-for="item in entityLinks" :key="item.id">
                                   <a :href="item.url" target="_blank">
                                     {{item.title}}
                                   </a>
                                 </li>
                               </ul>
                             </div>
                             <div v-if="entityLinks.length <= 0">
                                <h2 class="text-center" v-html="t('PILL_MODAL_EMTPY_WIKI', {'title':title})"></h2>
                             </div>

                           </div>
                         </div>
                     </div>
                  </div>
                </div>
              </div>
          </TippyContent>
      </div>
   </span>

</template>
<script lang="ts">
import {ref, computed, inject, defineComponent} from "vue";
import {slugify} from 'transliteration';
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";


export default defineComponent({
  props: ['title', 'type', 'size', 'id','hasWiki','hideExplore'],
  setup: function (props: any) {
    const store = useStore();
    const { t } = useI18n()
    const eventBus = inject<any>('plugin.eventbus')
    const cache = {};

    const loading = ref(true)
    const error = ref(false)

    const content = ref({})
    const html = ref('')
    const entityHtmlTitle = ref('')
    const entityHtml = ref('')
    const entityLinks = ref([])
    const identifier = computed(() => {
      return slugify(props.id) + '-' + slugify(props.title) + '-tooltip-content'
    })

    const typeString = computed(() => {
      if (props.type === undefined) {
        return "Undefined"
      }

      return props.type.split("/").pop()

    })
    const bg = computed(() => {
      // `this` points to the vm instance
      if (props.type === undefined) {
        return "bg-gray-100"
      }

      if (props.type.toLowerCase().endsWith('thing')) {
        return 'bg-theme-100';
      }

      if (props.type.toLowerCase().endsWith('place')) {
        return 'bg-theme-101';
      }

      if (props.type.toLowerCase().endsWith('person')) {
        return 'bg-theme-102';
      }

      if (props.type.toLowerCase().endsWith('organization')) {
        return 'bg-theme-103';
      }

      if (props.type.toLowerCase().endsWith('organisation')) {
        return 'bg-theme-104';
      }

      if (
             props.type.toLowerCase() === 'metaphor-target'
          || props.type.toLowerCase() === 'metaphor-source'
      ) {
        return 'bg-theme-12 text-theme-1 hover:bg-theme-1 hover:text-white ';
      }

      return 'bg-theme-1';
    })
    function insertText(){
      console.log('eventBus',eventBus)
      eventBus.insertText(props.title)
    }

    function show(id) {
      loading.value = true
      error.value = false

      if (!cache[id]) {
        store.dispatch('main/GET_SPARK',{
              "label": props.title,
              "type": props.type.split("/").pop().toLowerCase(),
              "size": props.size,
              "hasWiki": !(props.hasWiki === 'false')
          }

        ).then((response) => {
          cache[id] = response.data
          html.value = cache[id].output.html

        }).catch(() => {
          error.value = true
        }).finally(() => {
          loading.value = false
        })
      } else {

        loading.value = false
        html.value = cache[id].output.html
      }


    }

    function entity() {
      loading.value = true
      error.value = false
      entityHtmlTitle.value = ''
      entityHtml.value = ''
      entityLinks.value = []

      store.dispatch('main/GET_ENTITIES',
        {
          "entity": props.title,
        }
      ).then((response) => {
        entityHtmlTitle.value = response.data.wiki.title
        entityHtml.value = response.data.wiki.summary
        entityLinks.value = response.data.search
      }).catch(() => {
        error.value = true
      }).finally(() => {
        loading.value = false
      })
    }


    return {
      t,
      bg,
      typeString,
      content,
      identifier,
      html,
      entityHtml,
      entityHtmlTitle,
      entityLinks,
      loading,
      error,
      show,
      entity,
      insertText,
    }

  }

})


</script>

