<template>
  <div
    v-for="(persona) in personas"
    :key="persona.name"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box"
    id="{{persona.name}}"
  >
    <div
      class="flex justify-between border-b border-gray-200  px-5 py-4"
    >
      <div></div>
      <div>
        <Tippy
          tag="a"
          href="javascript:;"
          data-toggle="modal"
          :data-target="'#'+persona.id+'-description-modal'"
          class="
            intro-x
            w-8 h-8
            flex
            items-center justify-center
            persona-info"
          :content="t('BTN_SHOW_PERSONA_DESCRIPTION')"
          :id="'quest-persona-'+persona.id"
        >
          <InfoIcon class="w-9 h-9 "/>
        </Tippy>
        <div :id="persona.id+'-description-modal'" class="modal">
          <div class="modal__content modal__content--wiki modal__content--xl p-10">
            <div class="h-auto mb-7">
              {{persona.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-5 text-base">
      <div class="h-40 xxl:h-56 image-fit">
        <img
          :alt="persona.name"
          class="rounded-md"
          :src="persona.backgroundImage"
        />
        <span
          class="text-tiny p-1 bg-theme-1 text-theme-12 absolute bottom-0 right-0 ">
          {{
            persona.type
          }}
        </span>
      </div>
      <span class="block font-medium text-base mt-5"
      >{{ persona.title }}
              </span>
      <div class="text-gray-700  mt-2 mb-5 ">
        {{ persona.abstract }}
      </div>
      <div v-if="persona.howTos ">

       <span
         class="inline-block px-2 py-1 rounded-full text-theme-1 mr-1 text-sm my-1 bg-theme-12 hover:bg-theme-1 hover:text-white"
         v-for="(howTo,key) in persona.howTos "
         :key="key"
       >
           <a
             href="javascript:;"
             :name="to(`${key}-${howTo.type}`,persona)"
           >
                   {{ howTo.type }}
            </a>
            <div class="tooltip-content">
                 <TippyContent :to="to(`${key}-${howTo.type}`,persona)" :options="{'interactive':true}">
                    <div class="relative flex items-center py-1 text-left">
                      <div class="ml-4 mr-auto h-auto w-80">
                        <h1 class="text-3xl text-theme-1 uppercase mt-5 mb-5">
                          <span class="mr-2">{{ howTo.type }}</span>
                        </h1>
                        <div class="text-gray-600 mb-5">
                          {{ howTo.prompt }}
                        </div>
                        <div class="h-30 text-center">
                            <a
                              class="button mr-1 mb-2 inline-block bg-theme-1 text-white"
                              v-if="howTo.descriptions[0]"
                              :href="'https://google.com/search?q='+howTo.descriptions[0]" target="_blank">
                              {{ t('QUEST_PERSONAS_PILL_MORE')}}
                            </a>
                        </div>
                      </div>
                    </div>
                </TippyContent>
            </div>
       </span>

      </div>
    </div>
  </div>

</template>
<script lang="ts">

import {defineComponent, ref, onMounted} from "vue";
import {slugify} from 'transliteration';
import {client as JECT} from "@/utils/ject";
import {useI18n} from "vue-i18n";


export default defineComponent({
  setup() {

    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const personas = ref({})
    const to = (key,persona)=>{
      return slugify(key+'-'+persona.name+'-'+persona.type)
    }

    onMounted(()=>{

      JECT.quest('personas').then((response)=>{
        personas.value = response.data
      }).catch((error)=>{
        console.error(error)
      })

    })

    return {
      t,
      to,
      personas,
      slugify
    }


  }
})


</script>
