

import {ref, defineComponent} from 'vue';
import {useI18n} from "vue-i18n";

export default defineComponent({
  props: ['type', 'card', 'query'],
  setup(props) {

    const { t  } = useI18n()

    const combinationKeys = ["person","organisation","place","thing"]
    const viewState = ref('primary')

    const showState = (state) => {
      viewState.value = state
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }


    const filterConcepts = (entities) =>{

      const ret = []

      for(const key of combinationKeys){
        if(entities[0][key]){
          ret.push(
            entities[0][key]
          )
        }
      }

      return ret

    }

    const joinEntities = (entities) =>{

      const ret = []

      for(const key of combinationKeys){
        if(entities[0][key]){
          ret.push(
            entities[0][key].label
          )
        }
      }

      return ret.join(", ")

    }


    return {
      t,
      viewState,
      showState,
      joinEntities,
      combinationKeys,
      filterConcepts

    }

  }
})

