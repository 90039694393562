import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import {State as LocalState } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.LOADING](state: S, loading:boolean): void;
  [MutationTypes.SET_TOKEN](state: S, token:string): void;
  [MutationTypes.SET_POPUP](state: S, open:boolean): void;
  [MutationTypes.SET_USER](state: S, user:any): void;
  [MutationTypes.SET_AUTHENTICATED](state: S, authenticated:boolean): void;
  [MutationTypes.SET_ERROR](state: S, error:string): void;
  [MutationTypes.SET_AUTH_LOADING](state: S, loading:boolean): void;
  [MutationTypes.SET_STATUS](state: S, status:any): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.LOADING](state: LocalState, loading:boolean){
    console.log("[AUTH] Setting loading ",loading)
    state.loading = loading
  },
  [MutationTypes.SET_AUTH_LOADING](state: LocalState, loading:boolean){
    console.log("[AUTH] Setting AUTH loading ",loading)
    state.auth.loading = loading
  },

  [MutationTypes.SET_TOKEN](state: LocalState, token:string){
    console.log("[AUTH] Setting token ",token)
    state.token = token
  },
  [MutationTypes.SET_POPUP](state: LocalState, open:boolean){
    console.log("[AUTH] Setting popup ",open)
    state.auth.popupOpen = open
  },
  [MutationTypes.SET_AUTHENTICATED](state: LocalState, authenticated:boolean){
    console.log("[AUTH] Setting isAuthenticated ", authenticated)
    state.auth.isAuthenticated = authenticated
  },

  [MutationTypes.SET_USER](state: LocalState, user:any){
    console.log("[AUTH] Setting user ", user)
    state.auth.user = user || {
      'name':false,
      'picture':false,
      'email':false
    }
  },

  [MutationTypes.SET_ERROR](state: LocalState, error:any){
    console.log("[AUTH] Setting error", error)
    state.auth.error = error
  },
  [MutationTypes.SET_STATUS](state: LocalState, status:any){
    console.log("[AUTH] Setting status", status)
    state.status = status
  },

};
