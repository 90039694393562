import {ref} from "vue";

export const filters = (t) => {

  return ref([
    {
      id: 'time-filter',
      'title': t('FILTER_YEAR'),
      'selected': 'month',
      'options': [
        {'id': 'years', 'name': t('LABEL_FILTER_PUBLISH_DATE_ANY')},
        {'id': 'year', 'name': t('LABEL_FILTER_PUBLISH_DATE_1YEAR')},
        {'id': 'month', 'name': t('LABEL_FILTER_PUBLISH_DATE_1MONTH')},
        {'id': 'week', 'name': t('LABEL_FILTER_PUBLISH_DATE_1WEEK')},
        {'id': 'four-eight', 'name': t('LABEL_FILTER_PUBLISH_DATE_2DAYS')}
      ],
    },
    {
      id: 'sort-filter',
      'title': t('FILTER_ORDER'),
      'selected': 'relevance',
      'options': [
        {'id': 'time', 'name': t('LABEL_OPTIONS_SORT_TIME')},
        {'id': 'relevance', 'name': t('LABEL_OPTIONS_SORT_RELEVANCE')},
        {'id': 'random', 'name': t('LABEL_OPTIONS_SORT_RANDOM')},
        {'id': 'sentiment-pos', 'name': t('LABEL_OPTIONS_SORT_SENTIMENT_POS')},
        {'id': 'sentiment-neg', 'name': t('LABEL_OPTIONS_SORT_SENTIMENT_NEG')},
      ],
    }
  ])

}

