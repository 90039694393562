import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import {State as LocalState } from "./state";
import { v4 as uuidv4 } from 'uuid';

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.READ](state: S): void;
  [MutationTypes.ADD](state: S, notification:any): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.READ](state: LocalState){
    const read = []
    for(const n of state.notifications){
      n.read=true
      read.push(n)
    }
    state.notifications=read
  },
  [MutationTypes.ADD](state: LocalState, {title, content}){

    state.notifications.push({
      "id":uuidv4(),
      "title": title,
      "read": false,
      "content": content,
      "when": new Date()
    })
  },

};
