<template>
  <div :id="reference" class="modal">
    <div class="modal__content modal__content--lg p-10">
      <div id="wordcloud" class="mb-5 modal__content--wordcloud">
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import {defineComponent, watch,  computed} from "vue";
import WordCloud from "wordcloud";
import {useStore} from "vuex";

export default defineComponent({

  setup(){
    const store= useStore()
    const cloud = computed(() => store.getters['wordcloud/transform'])
    const reference = "superlarge-modal-word-cloud"

    // Show modal cash("#programmatically-modal").modal("show") // Hide modal cash("#programmatically-modal").modal("hide") // Toggle modal cash("#programmatically-modal").modal("toggle")
    watch(cloud,
      (newValue, oldValue)  => {

        if(WordCloud.isSupported){
          const wordcloud = cash(`#${reference}`).find("#wordcloud")[0]
          WordCloud(
            wordcloud
            ,
            {
              shuffle: false,
              rotateRatio: 0,
              color: '#7a7e8d',
              fontFamily: 'sans-serif',
              list: newValue
            }
          )

          cash(`#${reference}`).modal("show")
        }else{
          cash(`#${reference}`).modal("hide")
        }


      }
    )


    return {
      reference
    }
  }
})

</script>
