<template>
  <div
    v-for="obj in data"
    :key="obj.name"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box"
    id="{{obj.name}}"
  >
    <div
      class="flex justify-between border-b border-gray-200 dark:border-dark-5 px-5 py-4"
    >
    </div>
    <div class="p-5 text-base">
      <div class="h-40 xxl:h-56 image-fit">
        <img
          class="rounded-md"
          :src="require(`@/assets/images/quest/quest_background_image.png`)"
        />
        <span
          class="text-3xl p-1 text-black absolute bottom-3 right-3 ">
          {{ obj.name }}
        </span>
      </div>
      <div class="text-gray-700 dark:text-gray-600 mt-2 mb-5 ">
        {{ obj.description }}
      </div>
    </div>
  </div>

</template>
<script lang="ts">

import {defineComponent,ref, onMounted} from "vue";
import {client as JECT} from '@/utils/ject'

export default defineComponent({
  setup() {

    const data = ref({})

    onMounted(()=>{

      JECT.quest('story-roles').then((response)=>{
        data.value = response.data
      }).catch((error)=>{
        console.error(error)
      })

    })

    return {
      data
    }
  }
})


</script>
