// Action enums
export enum ActionTypes {
  SEARCH = "SEARCH",
  CLEAR_SEARCH = "CLEAR_SEARCH",
  SELECT_DIMENSION = "SELECT_DIMENSION",
  SELECT_LANGUAGE = "SELECT_LANGUAGE",
  LOAD_MORE = "LOAD_MORE",
  GET_SPARK = "GET_SPARK",
  GET_PERSON_SPARK = "GET_PERSON_SPARK",
  GET_ENTITIES = "GET_ENTITIES",
  SET_BOOKMARKED = "SET_BOOKMARKED",
  LOAD_MORE_BOOKMARKS = "LOAD_MORE_BOOKMARKS"
}
