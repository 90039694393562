
import {ref, computed, inject, defineComponent} from "vue";
import {slugify} from 'transliteration';
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";


export default defineComponent({
  props: ['title', 'type', 'size', 'id','hasWiki','hideExplore'],
  setup: function (props: any) {
    const store = useStore();
    const { t } = useI18n()
    const eventBus = inject<any>('plugin.eventbus')
    const cache = {};

    const loading = ref(true)
    const error = ref(false)

    const content = ref({})
    const html = ref('')
    const entityHtmlTitle = ref('')
    const entityHtml = ref('')
    const entityLinks = ref([])
    const identifier = computed(() => {
      return slugify(props.id) + '-' + slugify(props.title) + '-tooltip-content'
    })

    const typeString = computed(() => {
      if (props.type === undefined) {
        return "Undefined"
      }

      return props.type.split("/").pop()

    })
    const bg = computed(() => {
      // `this` points to the vm instance
      if (props.type === undefined) {
        return "bg-gray-100"
      }

      if (props.type.toLowerCase().endsWith('thing')) {
        return 'bg-theme-100';
      }

      if (props.type.toLowerCase().endsWith('place')) {
        return 'bg-theme-101';
      }

      if (props.type.toLowerCase().endsWith('person')) {
        return 'bg-theme-102';
      }

      if (props.type.toLowerCase().endsWith('organization')) {
        return 'bg-theme-103';
      }

      if (props.type.toLowerCase().endsWith('organisation')) {
        return 'bg-theme-104';
      }

      if (
             props.type.toLowerCase() === 'metaphor-target'
          || props.type.toLowerCase() === 'metaphor-source'
      ) {
        return 'bg-theme-12 text-theme-1 hover:bg-theme-1 hover:text-white ';
      }

      return 'bg-theme-1';
    })
    function insertText(){
      console.log('eventBus',eventBus)
      eventBus.insertText(props.title)
    }

    function show(id) {
      loading.value = true
      error.value = false

      if (!cache[id]) {
        store.dispatch('main/GET_SPARK',{
              "label": props.title,
              "type": props.type.split("/").pop().toLowerCase(),
              "size": props.size,
              "hasWiki": !(props.hasWiki === 'false')
          }

        ).then((response) => {
          cache[id] = response.data
          html.value = cache[id].output.html

        }).catch(() => {
          error.value = true
        }).finally(() => {
          loading.value = false
        })
      } else {

        loading.value = false
        html.value = cache[id].output.html
      }


    }

    function entity() {
      loading.value = true
      error.value = false
      entityHtmlTitle.value = ''
      entityHtml.value = ''
      entityLinks.value = []

      store.dispatch('main/GET_ENTITIES',
        {
          "entity": props.title,
        }
      ).then((response) => {
        entityHtmlTitle.value = response.data.wiki.title
        entityHtml.value = response.data.wiki.summary
        entityLinks.value = response.data.search
      }).catch(() => {
        error.value = true
      }).finally(() => {
        loading.value = false
      })
    }


    return {
      t,
      bg,
      typeString,
      content,
      identifier,
      html,
      entityHtml,
      entityHtmlTitle,
      entityLinks,
      loading,
      error,
      show,
      entity,
      insertText,
    }

  }

})


