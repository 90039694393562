<template>
  <div

    :key="card.id"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box card card-landing"
    id="cards-{{ type }}-key"
  >
    <div
      class="flex justify-between border-b border-gray-200 px-5 py-4"
    >
      <div>
        <Tippy
          tag="a"
          href="#"
          class="w-5 h-5  graph-icon graph-icon-inline"
          v-if="viewState === 'primary'"
          content="Ethnicity Distribution"
          :id="type+'-card-graph-donought-ethnicity'"
          @click.prevent="showEthinicityGraph(null,'all')"

        >
          <DiscIcon class="w-5 h-5"/>
        </Tippy>
        <Tippy
          tag="a"
          href="#"
          v-if="viewState === 'primary'"
          class="w-5 h-5 ml-3 graph-icon graph-icon-inline"
          content="Gender Distribution"
          :id="type+'-card-graph-piechart-gender'"
          @click.prevent="showGenderDistributionGraph(null,'all')"
        >
          <PieChartIcon class="w-5 h-5"/>
        </Tippy>
      </div>
      <div>
        <div class=" pt-1 ml-3"
             v-if="viewState !== 'primary'">
          <a
            href=""
            class="w-5 h-5 text-gray-600 "
            @click.prevent="showState('primary')"
          >
            <XIcon class="w-5 h-5"/>
          </a>
        </div>
        <div class="dropdown pt-1 ml-3"
             :id="type+'-dropdown'"
             v-if="viewState === 'primary'">
          <a

            href="javascript:;"
            class="dropdown-toggle w-5 h-5 text-gray-600"
          >
            <MoreVerticalIcon class="w-5 h-5"/>
          </a>
          <div class="dropdown-box w-80">
            <div class="dropdown-box__content box  p-2">
              <a
                href="#"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white rounded-md"
                @click.prevent="showState('explain')"
              >
                {{ t('CARD_INTELLIGENCE_DROPDOWN_EXPLAIN') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-5">
      <h1>{{ t('CARD_INTELLIGENCE_TITLE') }}</h1>

      <div v-if="viewState === 'primary'" class="text-base">

        <div class="mb-4" v-if="journalists.length">
          <h4>{{ t('CARD_INTELLIGENCE_JOURNALISTS') }}</h4>
          <div class="card-pills">

            <AuthorPill
              v-for="(concept,key) in journalists"
              :key="slugify(concept.name)"
              :concept="concept"
              :id="`${key}-${type}`"
              type="journalist"
            />
            <Tippy
              tag="span"
              content="Journalist Ethnicity Distribution"
              class="pill pill__graph"
              :id="type+'-top-entities-card-graph-2-content'"
            >
              <a href="#"
                 class="pill__action"
                 @click.prevent="showEthinicityGraph(journalists,'journalists')"
              >
                <DiscIcon class="inline-block w-5 h-5"/>
              </a>
            </Tippy>
            <Tippy
              tag="span"
              content="Journalist Gender Distribution"
              class="pill pill__graph"
              :id="type+'-top-entities-card-graph-2-content'"
            >
              <a href="#"
                 class="pill__action"
                 @click.prevent="showGenderDistributionGraph(journalists,'journalists')"
              >
                <PieChartIcon class="inline-block w-5 h-5"/>
              </a>
            </Tippy>

          </div>
        </div>
        <div class="mb-4" v-if="scholars.length">
          <h4>{{ t('CARD_INTELLIGENCE_SCHOLARS') }}</h4>
          <div class="card-pills">
            <AuthorPill
              v-for="(concept,key) in scholars"
              :key="slugify(concept.name)"
              :concept="concept"
              :id="`${key}-${type}`"
              type="scholar"
            />
            <!-- TODO: Enable Here -->
<!--            <Tippy-->
<!--              tag="a"-->
<!--              href="#"-->
<!--              content="Scholar Ethnicity Distribution"-->
<!--              class="graph-pill"-->
<!--              :id="type+'-top-entities-card-graph-2-content'"-->
<!--              @click.prevent="showEthinicityGraph(scholars,'scholars')"-->
<!--            >-->
<!--              <DiscIcon class="inline-block w-5 h-5"/>-->
<!--            </Tippy>-->
<!--            <Tippy-->
<!--              tag="a"-->
<!--              href="#"-->
<!--              content="Scholar Gender Distribution"-->
<!--              class="graph-pill"-->
<!--              :id="type+'-top-entities-card-graph-2-content'"-->
<!--              @click.prevent="showGenderDistributionGraph(scholars,'scholars')"-->
<!--            >-->
<!--              <PieChartIcon class="inline-block w-5 h-5"/>-->
<!--            </Tippy>-->
          </div>

        </div>

        <div class="mb-4" v-if="experts.length">
          <h4>{{ t('CARD_INTELLIGENCE_TWITTER') }}</h4>
          <div class="card-pills">
            <AuthorPill
              v-for="(concept,key) in experts"
              :key="slugify(concept.name)"
              :concept="concept"
              :id="`${key}-${type}`"
              type="expert"
            />
            <!-- TODO: Enable Here -->

<!--            <Tippy-->
<!--              tag="a"-->
<!--              href="#"-->
<!--              content="Experts Ethnicity Distribution"-->
<!--              class="graph-pill"-->
<!--              :id="type+'-top-entities-card-graph-2-content'"-->
<!--              @click.prevent="showEthinicityGraph(experts,'experts')"-->
<!--            >-->
<!--              <DiscIcon class="inline-block w-5 h-5"/>-->
<!--            </Tippy>-->
<!--            <Tippy-->
<!--              tag="a"-->
<!--              href="#"-->
<!--              content="Experts Gender Distribution"-->
<!--              class="graph-pill"-->
<!--              :id="type+'-top-entities-card-graph-2-content'"-->
<!--              @click.prevent="showGenderDistributionGraph(experts,'experts')"-->
<!--            >-->
<!--              <PieChartIcon class="inline-block w-5 h-5"/>-->
<!--            </Tippy>-->
          </div>
        </div>
      </div>
      <div v-if="viewState === 'explain'">
        <h3 class="block font-bold mb-5">{{ t('EXPLANATION_LABEL_TITLE') }}</h3>

        <p>{{ t('CARD_INTELLIGENCE_EXPLAIN_A') }}</p>
        <p>{{ t('CARD_INTELLIGENCE_EXPLAIN_B') }}</p>

      </div>



      <VisualizationDialog
        identifier="graph-gender-intelligence-modal-size-preview"
        :title="`JECT has discovered the likely gender of the discovered ${graphData.title}`"
      >

        <ul class="spark-graph spark-graph__top">
          <li >Explore the likely genders of the discovered journalists and scientists</li>
        </ul>

        <Chart
          v-if="graphData"
          type="pie"
          :data="graphData.data"
          :height=200
          :width=400
        />

      </VisualizationDialog>

      <VisualizationDialog
        identifier="graph-ethnicity-intelligence-modal-size-preview"
        :title="`JECT has discovered the likely ethnicity of the discovered ${graphData.title}`"
      >

        <ul class="spark-graph spark-graph__top">
          <li >
            Think about including voices from some of the under-represented countries or regions</li>
        </ul>

        <Chart
          v-if="graphData"
          type="doughnut"
          :data="graphData.data"
          :height=200
          :width=400
        />

      </VisualizationDialog>
    </div>


  </div>
</template>
<script lang="ts">

import {ref, defineComponent, computed, inject} from 'vue';
import {slugify} from "transliteration";
import AuthorPill from "@/views/dimensions/pill/AuthorPill.vue";
import {useI18n} from "vue-i18n";
import VisualizationDialog from "@/views/dimensions/cards/VisualizationDialog.vue";


export default defineComponent({
  props: ['type', 'card', 'query'],
  components: {
    AuthorPill,
    VisualizationDialog
  },
  setup(props) {
    const {t} = useI18n()
    const lodash = inject<any>('lodash')

    const getPeople = function(people, limit){

      let ret = people

      if (!ret) {
        return []
      }

      if (people.length > 10) {
        ret = []
        for (let i = 0; i < 10; i++) {
          ret.push(people[i])
        }
      }

      return ret


    }
    const journalists = computed(() => {
      return getPeople(props.card.primary.journalists,10)

    })
    const scholars = computed(() => {
      return getPeople(props.card.primary.scholars,10)
    })
    const experts = computed(() => {
      return getPeople(props.card.primary.experts,10)
    })


    const viewState = ref('primary')
    const showState = (state) => {
      viewState.value = state
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }


    const _ethnicityData = (entities,type)=>{

      const ret = {
        'title':   type === 'all' ? 'jounalists & sientists' : type,
        'data': {
          labels: ['ethnicity'],
          datasets: [{
            backgroundColor: [],
            hoverBackgroundColor: [],
            borderWidth: 5,
            borderColor: "#ffff",
            data: [1]
          }]
        },
        'options': {}
      }



      const ethnicityData = lodash.countBy(entities,(e)=> e.ethnicity)
      ret.data.labels= lodash.sortBy(Object.keys(ethnicityData))
      ret.data.datasets[0].data= ret.data.labels.map(key => ethnicityData[key]);

      for(const i in ret.data.labels){
        const color = Math.floor(Math.random()*16777215).toString(16)
        ret.data.datasets[0].backgroundColor.push("#"+color)
      }
      return ret
    }

    const _genderData = (entities, type)=>{
      const ret = {
        'title':   type === 'all' ? 'jounalists & sientists' : type,
        'data': {
          labels: ['Female','Male','N/A'],
          datasets: [{
            title:'Journalists',
            backgroundColor: ['#d18d96','#517fa4','#a0aec0'],
            hoverBackgroundColor: ['#ca253b','#295470','#64666b'],
            data: []
          }]
        },
        'options': {}
      }

      const genderCount = lodash.countBy(entities,(e)=> e.gender)

      ret.data.datasets[0].data = [
        genderCount.male,
        genderCount.female,
        entities.length - (genderCount.male + genderCount.female)
      ]

      return ret

    }

    const showGraph = (graph:string) => {
      cash(`#${graph}-intelligence-modal-size-preview`).modal("show");
    }

    const graphData = ref({
      'title':''
    })

    const showEthinicityGraph = (entities, type:string) => {
      graphData.value = _ethnicityData(
        type === 'all' ?  props.card.primary.journalists : entities,type
      )
      showGraph('graph-ethnicity')
    }

    const showGenderDistributionGraph = (entities, type:string) => {
      graphData.value = _genderData(
        type === 'all' ?  props.card.primary.journalists : entities,type
      )
      showGraph('graph-gender')
    }

    return {
      t,
      viewState,
      showState,
      journalists,
      scholars,
      experts,
      graphData,
      showEthinicityGraph,
      showGenderDistributionGraph,
      slugify
    }

  }
})

</script>
