<template>
   <span
     class="pill"
     :class="bg"
     :id="identifier+'-root'"
   >
       <a href="#"
              class="pill__action pill__action_sm hidden plugin:inline-block pl-2"
              @click.prevent="insertText()">
        <Edit2Icon size="12"/>
      </a>
     <a
       class="pill__action plugin:ml-0"
       href="#"
       @click.prevent="show(identifier)"
       :name="identifier"
     >
             {{concept.name}}
      </a>
      <div class="tooltip-content ">
           <TippyContent
               :to="identifier"
               :options="{interactive: true}"
              >
              <div class="relative flex items-center py-1 text-left spark spark__light">
                <div class="ml-4 mr-auto h-auto w-80">
                  <h1 class="text-3xl text-theme-1 uppercase mt-5 mb-5">

                    <span class="mr-2">{{ concept.name }}</span>
                    <LoadingIcon icon="puff"
                                 class="loading loading__icon" v-show="loading"/>
                    <AlertCircleIcon class="feather-icon" v-show="!loading && error"/>

                  </h1>
                  <div class="font-medium dark:text-gray-300 leading-relaxed items-center">
                  </div>
                  <div class="text-black"></div>
                  <hr class="mt-4 mb-4"/>
                  <div class="text-gray-600 mb-5" v-html="html">
                  </div>
                  <div class="h-30 text-center plugin:hidden" v-show="html !== '' && link !==''" >
                      <a :href="link"
                         target="_blank"
                         class="button mr-1 mb-2 inline-block"
                      >{{ t('BTN_EXPLORE_MORE')}}</a>
                  </div>
                </div>
              </div>
          </TippyContent>
      </div>
   </span>

</template>
<script lang="ts">
import {ref, computed, defineComponent, inject} from "vue";
import {slugify} from 'transliteration';
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";


export default defineComponent({
  props: ['concept', 'type','id'],
  setup: function (props: any) {

    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const store = useStore();
    const cache = {};
    const eventBus = inject<any>('plugin.eventbus')

    const loading = ref(true)
    const error = ref(false)

    const content = ref({})
    const html = ref('')

    const link = ref('')

    const identifier = computed(() => {
      return props.id+"-intelligence-"+props.type+"-authors-"+slugify(props.concept.name)+ '-tooltip-content'
    })
    const url = computed(()=> {

      if ( props.concept.story && props.concept.story.url ){
        return props.concept.story.url
      }

      return ''

    })
    const typeString = computed(() => {
      if (props.type === undefined) {
        return "Undefined"
      }

      return props.type.split("/").pop()

    })
    const bg = computed(() => {
      // `this` points to the vm instance
      if (props.type === undefined) {
        return "bg-gray-100"
      }

      if (props.type.toLowerCase().endsWith('thing')) {
        return 'bg-theme-100';
      }

      if (props.type.toLowerCase().endsWith('place')) {
        return 'bg-theme-101';
      }

      if (props.type.toLowerCase().endsWith('person')) {
        return 'bg-theme-102';
      }

      if (props.type.toLowerCase().endsWith('organization')) {
        return 'bg-theme-103';
      }

      if (props.type.toLowerCase().endsWith('organisation')) {
        return 'bg-theme-104';
      }
      return 'bg-theme-1';
    })

    function insertText(){
      console.log('eventBus',eventBus)
      eventBus.insertText(props.concept.name)
    }

    function show(id) {
      loading.value = true
      error.value = false
      if (!cache[id]) {
        store.dispatch('main/GET_PERSON_SPARK',
          {
            type:props.type,
            concept:props.concept
          }
        ).then((response) => {

          cache[id] = response.data
          html.value = cache[id].output.html
          if (props.type === 'journalist'){
            link.value = 'https://www.google.com/search?q='+response.data.metadata
          }

          if(props.type === 'scholar'){
            link.value = response.data.metadata
          }
          if(props.type === 'expert'){
            link.value = props.concept.url
          }

        }).catch(() => {
          error.value = true
        }).finally(() => {
          loading.value = false
        })
      } else {

        loading.value = false
        html.value = cache[id].output.html
      }


    }


    return {
      t,
      link,
      bg,
      typeString,
      content,
      identifier,
      html,
      loading,
      error,
      show,
      insertText
    }

  }

})


</script>

