import {useStore} from "@/store";
import {client as JECT} from "@/utils/ject";

const store =useStore()
const verify= ()=>{

  return new Promise((resolve => {
    if(!store.getters['user/token']){
      resolve(false)
    }

    JECT.extendedUser()
      .then((response)=>{
        resolve(response.data)
      })
      .catch(() =>{
        resolve(false)
      })
  }))
}
export const tokenAuth = {
    verify
}
