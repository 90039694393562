

import {computed, defineComponent, inject, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {slugify} from 'transliteration';

export default defineComponent(
  {

    emits: ['selected'],

    setup(props, ctx) {

      const store = useStore();
      const loading = ref(false)
      const lodash = inject<any>('lodash')
      const id = ref('sources-dropdown')
      const languages = ref([])
      const countries = ref([])
      const sources = ref([])
      const sourcesFiltered = ref([])
      const sourcesFilter = ref(null)
      const selectedLanguage = ref(null)
      const selectedCountries = ref([])
      const selectedAllCountries = ref([])
      const selectedAllSources = ref([])
      const selectedSources = ref([])


      const selectedName = computed(() => {
        if (!selectedLanguage.value) {
          return 'ALL'
        }

        let selection = 'All'

        if (selectedCountries.value.length > 0
          && selectedCountries.value[0] !== 'ALL'){
          selection = 'Some'
        }

        if (selectedSources.value.length > 0
          && selectedSources.value[0] !== 'ALL'){
          selection = 'Some'
        }

        return `${selection} in ${selectedLanguage.value.toUpperCase()} `
      })


      watch(selectedLanguage, (newValue, oldValue) => {
        if (newValue == null || newValue === oldValue) {
          return
        }

        loading.value = true
        countries.value = []

        store.dispatch('sources/GET_COUNTRIES', selectedLanguage.value).then((response) => {
          countries.value = lodash.map(response.data,(x)=>{
            return {
              'id':x,
              'name':x
            }
          })

        }).finally(() => {

          selectedAllCountries.value = ['all']
          loading.value = false

        })
      })
      watch(selectedAllCountries, (newValue) => {

        selectedCountries.value = []
        selectedSources.value = []
        sourcesFilter.value = ''
      })

      watch(selectedCountries, (newValue) => {

        loading.value = true
        sources.value = []
        const q = countries.value.filter((x)=> newValue.includes(x.id) ).map((x)=> x.name )

        store.dispatch('sources/GET_SOURCES',
          {lang: selectedLanguage.value, countries: q }
        ).then((response) => {
          sources.value = response.data
          sourcesFiltered.value = response.data
        }).finally(() => {
          selectedAllSources.value = ['all']
          sourcesFilter.value = ''
          loading.value = false

        })

      })
      watch(selectedAllSources, (newValue) =>{
        selectedSources.value = []
        sourcesFilter.value = ''
      })
      watch(sourcesFilter, (newValue) => {
        const trimmedFilter = sourcesFilter.value.trim().toLowerCase()
        if(trimmedFilter.length <=0){
          sourcesFiltered.value = lodash.clone(sources.value)
          return
        }

        sourcesFiltered.value = sources.value.filter((x) => x.name.trim().toLocaleLowerCase().indexOf(trimmedFilter) >=0 )

      })

      onMounted(() => {
        loading.value = true
        store.dispatch('sources/GET_LANGUAGES').then((response) => {
          languages.value = response.data

          let selected = response.data[0]
          if (
            response.data.indexOf(store.state.main.search.sourceLang) > 0
          ){
            selected =store.state.main.search.sourceLang
          }

          selectedLanguage.value = selected

        }).finally(() => {
          loading.value = false
        })
      })


      const isValidSelection = computed(() => {

        return  selectedLanguage.value !== null
        &&      (selectedAllCountries.value.length > 0 || selectedCountries.value.length > 0 )
        &&      (selectedAllSources.value.length > 0 || selectedSources.value.length > 0 )
      })
      const setSelected = () => {
        cash('#' + id.value).dropdown('hide')

        const selected = {
          'language': selectedLanguage.value,
          'countries': selectedAllCountries.value.length >0 ?  [] : selectedCountries.value ,
          'sources': selectedAllSources.value.length >0 ? [] :  selectedSources.value ,
        }
        ctx.emit('selected',selected)
      }

      return {
        id,
        loading,
        languages,
        countries,
        sources,
        sourcesFilter,
        sourcesFiltered,
        selectedLanguage,
        selectedCountries,
        selectedAllCountries,
        selectedSources,
        selectedAllSources,
        setSelected,
        selectedName,
        isValidSelection,

      }
    }

  }
)

