import {ActionContext, ActionTree} from "vuex";
import {State as RootState} from "@/store";
import {State as LocalState} from "./state";
import {Mutations} from "./mutations";
import {ActionTypes} from "./action-types";
import {MutationTypes} from "./mutation-types";
import {client as JECT} from "@/utils/ject";
import {AxiosPromise} from "axios";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {

  [ActionTypes.SEARCH]({commit}: AugmentedActionContext, search): void;

  [ActionTypes.CLEAR_SEARCH]({commit}: AugmentedActionContext): void;

  [ActionTypes.SELECT_DIMENSION]({commit}: AugmentedActionContext, dimension: string): void;

  [ActionTypes.SELECT_LANGUAGE]({commit}: AugmentedActionContext, locale: string): void;

  [ActionTypes.LOAD_MORE]({commit}: AugmentedActionContext): void;

  [ActionTypes.GET_SPARK]({commit}: AugmentedActionContext, concept): AxiosPromise;

  [ActionTypes.GET_PERSON_SPARK]({commit}: AugmentedActionContext, {type, concept}): AxiosPromise;

  [ActionTypes.GET_ENTITIES]({commit}: AugmentedActionContext, body: any): AxiosPromise;

  [ActionTypes.SET_BOOKMARKED]({commit}: AugmentedActionContext, {id: string, bookmarked: boolean}): void;

  [ActionTypes.LOAD_MORE_BOOKMARKS]({commit}: AugmentedActionContext): void;

}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SEARCH]({state, commit, dispatch}, object) {

    commit(MutationTypes.CLEAR_DATA, null)
    commit(MutationTypes.SET_SEARCH, object)
    commit(MutationTypes.SET_SEARCH_LOADING,true)
    commit(MutationTypes.DIMENSION_SET_STATE, {dimension: object.dimension, s: "loading"})

    console.log("Searcing ",object)
    JECT.search(object.query, object.dimension, object.time, object.category, object.sources, object.countries, object.sort, object.sourceLang)
    .then(response => {
      commit(MutationTypes.SET_DIMENSION_DATA, {dimension: object.dimension, data: response.data})
      commit(MutationTypes.DIMENSION_SET_STATE, {dimension: object.dimension, s: "idle"})

      if(response.data.articles.length <=0){
        console.warn("\t no results",response)
        return
      }
      dispatch('SELECT_DIMENSION', object.dimension)
      for (const key in state.dimensions) {

        if (key === object.dimension) {
          continue
        }
        commit(MutationTypes.DIMENSION_SET_STATE, {dimension: key, s: "loading"})
        JECT.search(object.query, key, object.time, object.category, object.sources, object.countries,  object.sort, object.sourceLang)
        .then(response => {
          commit(MutationTypes.SET_DIMENSION_DATA, {dimension: key, data: response.data})
          commit(MutationTypes.DIMENSION_SET_STATE, {dimension: key, s: "idle"})

        })
        .catch((error) => {
          console.error(error)
          commit(MutationTypes.DIMENSION_SET_STATE, {dimension: key, s: "error"})
        });

      }

    })
    .catch((error) => {
      console.error(error)
      commit(MutationTypes.DIMENSION_SET_STATE, {dimension: object.dimension, s: "error"})
    }).finally(() =>{
      commit(MutationTypes.SET_SEARCH_LOADING,false)
    });
  }
  , [ActionTypes.CLEAR_SEARCH]({commit}) {
    commit(MutationTypes.CLEAR_DATA, null)
  }
  , [ActionTypes.SELECT_LANGUAGE]({commit}, locale) {
    commit(MutationTypes.SELECT_LANGUAGE, locale)
  }
  , [ActionTypes.SELECT_DIMENSION]({state, commit, dispatch}, dimension) {
    commit(MutationTypes.SELECT_DIMENSION, dimension)
    commit(MutationTypes.SET_CURRENT_PAGE, 1)

    if (Object.keys(state.dimensions[dimension].data.cards).length <= 0) {
      commit(MutationTypes.DIMENSION_SET_STATE, {dimension: dimension, s: "loading"})


      JECT.cards(state.dimensions[dimension].data.queryData.queryid).then(response => {
        Object.keys(response.data).forEach(key =>
          commit(MutationTypes.SET_CARD_DATA, {dimension: dimension, type: key, data: response.data[key]})
        )
        dispatch('notifications/ADD', {
          "title": dimension.toUpperCase() + " - Cards Added!",
          "content": "New cards added"
        }, {root: true})

      }).catch((error) => {
        console.error(error)
      }).finally(() => {

        JECT.analysis(state.dimensions[dimension].data.queryData.queryid).then(response => {
          commit(MutationTypes.SET_CARD_DATA, {dimension: dimension, type: 'visualization', data: response.data})
        }).catch((error)=>{
          console.error(error)
        }).finally(()=>{

          Promise.all([
            JECT.graph("sentiment",state.dimensions[dimension].data.queryData.queryid,'month'),
            JECT.graph("country",state.dimensions[dimension].data.queryData.queryid,'month')
            ]
          ).then(response => {
            commit(MutationTypes.SET_GRAPH, {dimension:dimension, type: "sentiment", data: response[0].data})
            commit(MutationTypes.SET_GRAPH, {dimension:dimension, type: "country", data: response[1].data})
          }).catch((error)=>{
            console.log(error)
          }).finally(()=>{
            commit(MutationTypes.DIMENSION_SET_STATE, {dimension: dimension, s: "idle"})
          })
        })
      })
    }

  }
  , [ActionTypes.LOAD_MORE]({state, commit}) {
    if (
      state.dimensions[state.search.dimension].data.currentPage < state.dimensions[state.search.dimension].data.numberOfPages
    ) {
      commit(MutationTypes.SET_CURRENT_PAGE, state.dimensions[state.search.dimension].data.currentPage + 1)
    }

  }
  , [ActionTypes.GET_SPARK]({state}, concept) {

    return JECT.spark(
      concept.label,
      concept.type,
      concept.size,
      state.search.query,
      state.search.lang
    )

  }

  , [ActionTypes.GET_PERSON_SPARK]({state}, {type, concept}) {
    return JECT.personSpark(
      type,
      concept,
      state.search.lang
    )

  }

  , [ActionTypes.GET_ENTITIES]({state}, body) {
    return JECT.entities(
      body.entity,
      state.search.query,
      state.search.lang
    )

  }
  ,
  [ActionTypes.SET_BOOKMARKED]({state, commit}, {id, bookmarked}) {

    if (bookmarked) {
      const article = state.dimensions[state.search.dimension].data.queryData.articles.find(x => x.id === id)
      return JECT.addBookmark(article, state.search.query).then(() => {
        commit(MutationTypes.BOOKMARK, {id: id, bookmark: bookmarked})
      }).catch((error) => {
        console.error(error)
      })
    } else {
      return JECT.removeBookmark(id).then(() => {
        commit(MutationTypes.BOOKMARK, {id: id, bookmark: bookmarked})
      }).catch((error) => {
        console.error(error)
      })
    }

  },

  [ActionTypes.LOAD_MORE_BOOKMARKS]({state, commit}) {

    if (state.bookmarks.total <= 0) {

      JECT.bookmarks().then(response => {
        commit(MutationTypes.BOOKMARKS, response.data)
        commit(MutationTypes.BOOKMARKS_PAGE, 1)
      }).catch(() => {
        commit(MutationTypes.BOOKMARKS_CLEAR, null)
      })

    } else if (state.bookmarks.currentPage < state.bookmarks.numberOfPages) {
      commit(MutationTypes.BOOKMARKS_PAGE, state.bookmarks.currentPage + 1)
    }
  },


};
