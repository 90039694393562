<template>
  <div id="dropdown-search-history" class="dropdown inline-block" data-placement="bottom-start">
    <Tippy
      content="Search History"
    >
      <a
        class="dropdown-toggle button flex items-center inline-block text-white hover:text-theme-3"
      >
        <RotateCcwIcon class="w-7 h-7"/>
      </a>
    </Tippy>
    <div class="dropdown-box w-72">
      <div class="dropdown-box__content box dark:bg-dark-1 p-2">
        <p
          class="text-center font-bold"
          v-if="searchTotal <= 0 "
        >No search history</p>
        <a
          href="#"
          class="block p-2 transition duration-300 ease-in-out bg-white hover:text-theme-3 rounded-md flex"
          v-for="(h,index) in searchHistory"
          :key="h.id">
          <div class="flex-1">
            <a class="font-medium"
               :href="navigateUrl(index)"
               target="_blank"
            >{{h.data.query}}</a>
            <p class="text-gray-600 text-xs">{{ $filters.format(h.data.created) }}</p>
          </div>
          <a
            @click.prevent="deleteHistory(h.id)"
            class="button text-gray-300 hover:text-gray-600"><XIcon/></a>
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import {computed, defineComponent, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default defineComponent({

  setup(){

    const store= useStore();
    const router = useRouter();
    const searchHistory = computed(() => store.getters['searchHistory/all'] )
    const searchTotal = computed(() => store.getters['searchHistory/total'] )

    const navigateUrl = (index)=> {
      const historyQuery =  searchHistory.value[index].data
      return store.getters['main/searchPath'](router.currentRoute.value.path,historyQuery)
    }

    const deleteHistory = (id)=> {

      store.dispatch('searchHistory/DELETE', id)

    }

    onMounted(()=>{
      console.log("window ",window.parent)
      window.top.postMessage('hello', '*')
      store.dispatch('searchHistory/READ', null)
    })

    return {
      searchHistory,
      searchTotal,
      navigateUrl,
      deleteHistory,
    }

  },

})

</script>
