
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "@/store";
import {linkTo} from "@/layouts/side-menu";
import {filters} from "@/views/dimensions/filters";
import AccountDetails from "@/components/account-details/Main.vue";
import LanguagePicker from "@/components/i18n/LanguagePicker.vue";
import DropdownFilter from "@/components/dropdown/Filter.vue";
import SourcesFilter from "@/components/dropdown/SourcesFilter.vue";
import Notifications  from "@/components/notifications/Notifications.vue";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import Wordcloud from "@/components/wordcloud/Wordcloud.vue";
import SearchHistory from "@/components/searchhistory/SearchHistory.vue";
import AdvancedLanguageCountryPicker from "@/components/i18n/AdvancedLanguageCountryPicker.vue";

export default defineComponent({
  components: {
    LanguagePicker,
    DropdownFilter,
    SourcesFilter,
    AccountDetails,
    Notifications,
    Wordcloud,
    SearchHistory
  },
  props:[
    'time',
    'lang',
    'category',
    'sources',
    'sourceLang',
    'countries',
    'sort',
    'dimension',
    'q'],

  setup(props) {


    const store = useStore();
    const router = useRouter();
    const { t,locale } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const dimensions = computed(() => store.state.main.dimensions)
    const search = computed(() => {
      return store.getters['main/search']
    })

    const f = filters(t)
    const questActive = computed(() => router.currentRoute.value.name === "quest" )
    const searchQuery = ref('')
    const sources = ref({
      "language":locale.value,
      "countries":[],
      "sources":[]
    })

    const filterSelected = (data) => {
      const filter = f.value.find((x) => x.id === data.id)
      if(filter && filter.selected !== data.selection){
        filter.selected = data.selection
        doSearch()
      }
    }

    const sourcesFilterSelected = (data) => {
      sources.value = data
      doSearch()
    }

    watch(locale,(newLocale) =>{
      store.dispatch("main/SELECT_LANGUAGE",newLocale)
      searchQuery.value=''
    })

    const doSearch= (save=true) => {
      if (search.value ===''){
        return
      }

      const q = Object.assign({},search.value)
      q.query = searchQuery.value
      q.time = f.value[0].selected
      q.sort = f.value[1].selected
      q.category = "news|science"
      q.countries = sources.value.countries.join("|")
      q.sources = sources.value.sources.join("|")
      q.sourceLang = sources.value.language
      q.lang  = locale.value

      store.dispatch("main/SEARCH",q);

      if(save){
        store.dispatch('searchHistory/ADD',q)
      }
    }

    watch(search, (newValue)=>{
      router.push(
        store.getters['main/searchPath']("/",newValue)
      )
    })

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");

      if(props.q){
        searchQuery.value = props.q
        const q = {
          query: props.q,
            dimension: props.dimension,
          time: props.time,
          sort: props.sort,
          category: 'news|science',
          sourceLang : props.sourceLang,
          countries :  props.countries,
          sources : props.sources,
          lang: props.lang
        }
        store.dispatch("main/SEARCH",q);
      }

    });


    return {
      questActive,
      search,
      dimensions,
      filters,
      filterSelected,
      sourcesFilterSelected,
      linkTo,
      searchQuery,
      doSearch,
      t,
      f
    }
  }

});
