
import {computed, defineComponent, onMounted, onUnmounted, onActivated, ref} from "vue";
import ArticleComponent from "./Article.vue";
import {useStore} from "@/store";
import {linkTo} from "@/layouts/side-menu";
import {filters} from "@/views/dimensions/filters";
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {
    ArticleComponent,
  },
  created() {
    const store = useStore();
    store.dispatch('main/LOAD_MORE_BOOKMARKS', null)
  },
  setup() {
    const store = useStore();
    const scrollComponent = ref(null)
    const query = computed(() => {
      return store.state.main.search.query
    })
    const bookmarks = computed(() => store.state.main.bookmarks.displayed)

    const handleScroll = () => {
      const element = scrollComponent.value
      if (element.getBoundingClientRect().bottom - 300 < window.innerHeight) {
        store.dispatch('main/LOAD_MORE_BOOKMARKS', null)
      }
    }

    onActivated(()=>{
      console.log("Activated")

    })


    onMounted(() => {
      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })


    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
    return {
      query,
      bookmarks,
      scrollComponent,
      filters,
      linkTo,
      t
    }
  }

});
