<template>
  <div
    class="flex items-center px-5 py-3 border-t border-gray-200 dark:border-dark-5"
  >
    <Tippy
      tag="a"
      href=""
      class="
            intro-x
            w-8 h-8
            flex
            items-center justify-center
            bookmark"
      :class="bookmarked ? 'active' : ''"
      content="Bookmark"
    >
      <BookmarkIcon class="w-3 h-3 " />
    </Tippy>

  </div>
</template>

<script lang="ts">
import { defineComponent} from "vue";
export default defineComponent({
  props:{
    'bookmarked':{
      type:Boolean,
      default:false
    }
  }
})

</script>
