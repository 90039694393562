

import {computed, defineComponent, inject, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

export default defineComponent({

  setup(){
    const timeago = inject<any>('ta')
    const store = useStore()
    const openned = ref(false)
    const notifications = computed( () => store.state.notifications.notifications)
    const unread = computed( () => store.getters['notifications/unread'])
    const total = computed( () => store.getters['notifications/total'])
    const { t } = useI18n()

    onMounted(()=>{
      cash("#global-notifications").on("shown", ()=>{
        store.dispatch("notifications/READ")

      })
    })

    const time = (dateStr) =>{
      return timeago.format(Date.parse(dateStr))
    }

    return {
      t,
      unread,
      total,
      notifications,
      openned,
      time
    }

  }
})

