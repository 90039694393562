<template>
  <div
    :key="article.id"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box"
    :id="article.id"
  >
    <div
      class="flex justify-between border-b border-gray-200 px-5 py-4"
    >
      <div>
        <Tippy
          tag="a"
          href="#"
          class="
            intro-x
            w-8 h-8
            flex
            items-center justify-center
            bookmark"
          :class="article.bookmarked ? 'active' : ''"
          :content="t('BTN_BOOKMARK')"
          :id="'bookmard-'+article.id"
          @click.prevent="setBookmark(article.id, !article.bookmarked)"
        >
          <BookmarkIcon class="w-3 h-3 "/>
        </Tippy>

      </div>

        <div>
          <div class=" pt-1 ml-3"
               v-if="viewState !== 'primary'">
            <a
              href=""
              class="w-5 h-5 text-gray-600"
              @click.prevent="showState('primary')"
            >
              <XIcon class="w-5 h-5" />
            </a>
          </div>
          <div class="dropdown pt-1 ml-3"
               :id="article.id+'-dropdown'"
               v-if="viewState === 'primary'">
            <a

              href="javascript:;"
              class="dropdown-toggle w-5 h-5 text-gray-600"
            >
              <MoreVerticalIcon class="w-5 h-5" />
            </a>
            <div class="dropdown-box w-80">
              <div class="dropdown-box__content box p-2">
                <a
                  href="#"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white  rounded-md"
                  @click.prevent="copyReference(article.title+' '+article.url)"
                >
                  {{ t('CARD_ARTICLE_DROPDOWN_COPY')}}

                  <input :id="article.id+'-input'" hidden/>
                </a>

                <a
                  href="#"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white  rounded-md"
                  @click.prevent="refreshKey()"
                >
                  {{ t('CARD_ARTICLE_DROPDOWN_REFRESH')}}
                </a>

                <a href="#"
                   class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white  rounded-md plugin:hidden"
                   @click.prevent="showWordCloud()"
                >
                  {{ t('CARD_ARTICLE_DROPDOWN_WC')}}

                </a>

                <a
                  :href="similarUrl"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white  rounded-md plugin:hidden"
                  target="_blank"

                >
                  {{ t('CARD_ARTICLE_DROPDOWN_SIMILAR')}}
                </a>


                <a
                  :href="`https://google.com/search?q=${article.title}`"
                  target="_blank"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white  rounded-md plugin:hidden"
                >
                  {{ t('CARD_ARTICLE_DROPDOWN_SIMILAR_GOOGLE')}}
                </a>

                <a
                  href="#"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white  rounded-md"
                  @click.prevent="showState('explain')"
                >
                  {{ t('CARD_ARTICLE_DROPDOWN_EXPLAIN')}}
                </a>

              </div>
            </div>
          </div>
        </div>

    </div>
    <div class="p-5">

      <div v-if="viewState  === 'primary'">
        <div class="h-40 xxl:h-56 image-fit"
             v-if="article.image !=='' && article.image.indexOf('file://') !==0 && article.image !== 'Unknown'"
        >
          <img
            :alt="article.image"
            class="rounded-md"
            :src="article.image"
          />

          <SourceTag :source_id="article.content.sourceid"
                     :id="`${article.id}-${dimension}`"
                     :query="query"
                      v-if="article.content && article.content.sourceid"
                    />
        </div>
        <a class="block font-medium text-base mt-5"
           target="_blank"
           v-if="article.type !== '' && article.type !=='archive'"
           :href="article.url"
        >{{ article.title }}
        </a>

        <a href="javascript:;"
           data-toggle="modal"
           class="block font-medium text-base mt-5"
           :data-target="'#'+article.id+'-superlarge-modal-size-preview'"
           v-if="article.type ==='archive' || article.type === ''"
        >{{ article.title }}</a>
        <div :id="article.id+'-superlarge-modal-size-preview'" class="modal">
          <div class="modal__content modal__content--wiki modal__content--xl p-10">
            <h1 class="text-3xl text-theme-1 uppercase mt-5 mb-5">
              {{ article.title }}
            </h1>
            <div
              v-if="article && article.content && article.content.body"
              class="h-auto mb-7" v-html="article.content.body"></div>
          </div>
        </div>


        <p v-if="article.content">
          <TimeAgoComponent :date="article.content.published"/>
          <SentimentComponent :sentiment="article.content.sentiment_verdict"/>
        </p>
        <div class="text-gray-700 dark:text-gray-600 text-base mt-2 mb-5 " v-if="article.content">
          {{ article.content.snippet }}
        </div>
        <div>
          <CardPill
            v-for="(concept,conceptKey) in entities"
            :key="conceptKey"
            :title="concept.label"
            :type="concept['generic-type']"
            size="3"
            hasWiki="true"
            :id="`article-${dimension}-${conceptKey}-${article.id}`"
          />
        </div>
      </div>

      <div v-if="viewState === 'explain'">
        <h3 class="block font-bold text-base mb-5">What is this card?</h3>
        <p>
          JECT.AI has retrieved this article from millions that it has discovered and indexed. It is retrieved because
          of computed similarities between terms such as <em class="font-medium italic">{{ query }}</em> and topics reported in the article. JECT.AI
          computed that the article is sufficiently similar based on the strategy you selected and settings you defined.
        </p>
      </div>
    </div>

    <div class="text-theme-16 p-5 border-t border-gray-200  text-center" v-if="bookmarkDate"
      v-html="t('BOOKMARK_META_DESCRIPTION', {'date':time(bookmarkDate),'query': query})">
    </div>
  </div>
</template>
<script lang="ts">

import {computed, defineComponent, inject, onMounted, ref, watch} from "vue";
import {useStore} from "@/store";
import SentimentComponent from "@/components/sentiment/Sentiment.vue";
import TimeAgoComponent from "@/components/timeago/TimeAgo.vue";
import SourceTag from "@/components/article/SourceTag.vue";
import Toastify from "toastify-js";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import moment from "moment";

export default defineComponent({
  props: ['article','dimension','query','bookmarkDate'],
  components: {
    SentimentComponent,
    TimeAgoComponent,
    SourceTag
  },
  setup(props) {
    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
    const store = useStore()
    const router = useRouter()
    const lodash = inject<any>('lodash')
    const entities = ref([])
    const viewState = ref('primary')

    // console.log(props.article)


    const hideDropdown = () => {
      cash("#"+props.article.id+"-dropdown").dropdown("hide")
    }
    const similarUrl = computed( ()=>{
      const similarSearch = Object.assign({},store.state.main.search)
      similarSearch.query=props.article.title
      return store.getters['main/searchPath'](router.currentRoute.value.path,similarSearch)
    })


    watch(
      () => props.article.content,
      () => {
        setRandomEntities()
      }
    )

    const refreshKey = () =>{
      setRandomEntities()
      hideDropdown()
    }

    const setRandomEntities = () => {


      entities.value = []
      if (props.article && props.article.content && props.article.content.concepts) {
        if (props.dimension === "individual"){
          let build = []
          const people = lodash.filter(props.article.content.concepts,(e) => e['generic-type'] ==='http://dbpedia.org/ontology/Person')
          const rest = lodash.filter(props.article.content.concepts,(e) => e['generic-type'] !=='http://dbpedia.org/ontology/Person')
          build = build.concat(people)
          if ( people.length < 7){
            build =  build.concat(lodash.sampleSize(rest, 7 - people.length))
          }

          entities.value = build

        }else{
          entities.value = lodash.sampleSize(props.article.content.concepts, 7)
        }
      }

    }
    const setBookmark = (id, bookmark) => {
      store.dispatch('main/SET_BOOKMARKED', {
        id: id,
        bookmarked: bookmark
      })
    }
    onMounted(() => {
      setRandomEntities()
    })

    const showState = (state) =>{
      viewState.value = state
      hideDropdown()
    }

    const copyReference= (text) =>{
      const $i = cash("#"+props.article.id+"-input")
      if(!$i.length){
        return
      }
      $i.val(text)
      $i[0].select()

      var successful = document.execCommand('copy');
      if(successful){
        Toastify({ text: "Copied \n"+$i.val(), duration: 2000, newWindow: true, close: true, gravity: "bottom", position: "middle", backgroundColor: "#38484e", stopOnFocus: true }).showToast()
      }
      hideDropdown()
    }

    const newQuery= () => {
      hideDropdown()
      return false
    }
    const showWordCloud = () => {
      hideDropdown()
      store.dispatch("wordcloud/FETCH", props.article.id)
    }

    const timeago = inject<any>('ta')

    const time = (dateStr )=>{
      return timeago.format(moment(dateStr).toDate())
    }


    return {
      t,
      time,
      viewState,
      showState,
      entities,
      refreshKey,
      setBookmark,
      copyReference,
      newQuery,
      hideDropdown,
      showWordCloud,
      similarUrl
    }

  }
})
</script>
