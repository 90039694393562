// Create initial state
export const state = {
  menu: [
    {
      icon: "CheckCircleIcon",
      pageName: "everything",
      title: "Everything",
      alt: "Everything - discover without limits",
      active: true
    },
    {
      icon: "TargetIcon",
      pageName: "backing-evidence",
      title: "Backing & Evidence",
      alt: "Quantitative evidence (e.g. numbers and measures)",
    },

    {
      icon: "UserCheckIcon",
      pageName: "individual",
      title: "Individual",
      alt: "Who are the key players? The viewport of an individual",
    },

    {
      icon: "RotateCcwIcon",
      pageName: "causal",
      title: "Causal",
      alt: "The background, the history, what caused something",
    },

    {
      icon: "RotateCwIcon",
      pageName: "ramification",
      title: "Ramification",
      alt: "What for the future?",
    },


    {
      icon: "PieChartIcon",
      pageName: "data-visualization",
      title: "Data Visualization",
      alt: "Charts and infographics",
    },
  ]

};

// Declare state
export type State = typeof state;
