
import {defineComponent, computed, onMounted, onUnmounted, ref} from "vue";
import ArticleComponent from "./Article.vue";
import LandingCard from "./cards/Landing.vue";
import IntelligenceCard from "./cards/Intelligence.vue";
import CombinationCard from "./cards/Combination.vue";
import {useStore} from "@/store";
import {linkTo} from "@/layouts/side-menu";
import {filters} from "@/views/dimensions/filters";
import  AccountDetails from "@/components/account-details/Main.vue";
import DropdownFilter from "@/components/dropdown/Filter.vue";
import LanguagePicker from "@/components/i18n/LanguagePicker.vue";
import {useI18n} from "vue-i18n";
import MobileMenu from "@/components/mobile-menu/Main.vue";

export default defineComponent({
  components: {
    ArticleComponent,
    LandingCard,
    IntelligenceCard,
    CombinationCard,
    LanguagePicker,
    MobileMenu,
    AccountDetails,
    DropdownFilter
  },
  setup() {
    const store = useStore();
    const dimensions = computed(() => store.state.main.dimensions)
    const scrollComponent = ref(null)


    const landingCard = computed(() => store.state.main.search.dimension ?
      store.state.main.dimensions[store.state.main.search.dimension].data.cards.landing || null
      : null
    )

    const intelligenceCard = computed(() => store.state.main.search.dimension ?
        store.state.main.dimensions[store.state.main.search.dimension].data.cards.intelligence || null
        : null
    )

    const scamperCard = computed(() => store.state.main.search.dimension ?
      store.state.main.dimensions[store.state.main.search.dimension].data.cards.scamper || null
      : null
    )

    const combinationsCard = computed(() => store.state.main.search.dimension ?
      store.state.main.dimensions[store.state.main.search.dimension].data.cards.combinations || null
      : null
    )
    const selectedDimension = computed(() => store.state.main.search.dimension)
    const query = computed(() => {
      return store.state.main.search.query
    })
    const selectedTime = computed(() => {

      return store.state.main.search.time
    })

    const isSearching = computed(() => {
      return store.state.main.loading
    })

    const hasResults = computed(() => {
      return store.getters['main/hasResults']
    })

    const handleScroll = () => {
      const element = scrollComponent.value
      if (element.getBoundingClientRect().bottom - 300 < window.innerHeight) {
        store.dispatch('main/LOAD_MORE', null)
      }
    }


    onMounted(() => {
      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })



    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const selectDimension = (dimension:string) => {
      if(!store.getters['main/isEnabled'](dimension)){
        return
      }
      store.dispatch("main/SELECT_DIMENSION", dimension);
    }

    const isEnabled = (dimensions:string)=>{
      return store.getters['main/isEnabled'](dimensions)
    }


    return {
      query,
      dimensions,
      selectedDimension,
      scrollComponent,
      landingCard,
      intelligenceCard,
      scamperCard,
      combinationsCard,
      selectedTime,
      selectDimension,
      isEnabled,
      filters,
      linkTo,
      isSearching,
      hasResults,
      t
    }
  }

});
