
// 1. Ready translated locale messages
// The structure of the locale message is the hierarchical object structure with each locale as the top property
import {createI18n} from "vue-i18n";

import {message as en} from "@/i18n/locale/en";
import {message as de} from "@/i18n/locale/de";
import {message as fr} from "@/i18n/locale/fr";
import {message as el} from "@/i18n/locale/el";
import {message as it} from "@/i18n/locale/it";
import {message as nl} from "@/i18n/locale/nl";
import {message as no} from "@/i18n/locale/no";

const messages = {
  en: en,
  de: de,
  fr: fr,
  el: el,
  it: it,
  nl: nl,
  no: no
}

// 2. Create i18n instance with options
export const i18n = createI18n({
  locale: 'en', // set locale,
  legacy:false,
  warnHtmlMessage:false,
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

