import axios, {AxiosPromise} from "axios";
import moment from "moment";
import {App} from "vue";
import {useStore} from "@/store";

let auth

console.log("Backend ", process.env.VUE_APP_BACKEND)
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND,
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 30000
})


// apiClient.interceptors.request.use(request => {
//   console.log('Starting Request', JSON.stringify(request.url, null, 2))
//   return request
// })

// Add a request interceptor
apiClient.interceptors.request.use(function (config) {
  // Do something before request is sent


  return  new Promise((resolve:any)=>{

    const store =useStore()
    if(store.getters['user/token']){
        config.headers.common.Accept = 'application/json';
        config.headers.common['Content-Type'] = 'application/json';
        config.headers.common['X-JECTAI-PLUGIN-TOKEN'] = store.getters['user/token'];
        return resolve(config)

    }else{

      auth.getTokenSilently({}).then((token) => {
        config.headers.common.Authorization = `Bearer ${token}`;
        config.headers.common.Accept = 'application/json';
        config.headers.common['Content-Type'] = 'application/json';
        return resolve(config)

      })
    }
  })

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const dateRangeForTime = (time:string):string => {

  if(time === 'year'){
    const to = moment()
    const from = moment(to).subtract(12,'months')
    return `&published_after=${from.toISOString()}&published_before=${to.toISOString()}`
  }


  if(time === 'month'){
    const to = moment()
    const from = moment(to).subtract(1,'months')
    return `&published_after=${from.toISOString()}&published_before=${to.toISOString()}`
  }


  if(time === 'week'){
    const to = moment()
    const from = moment(to).subtract(7,'days')
    return `&published_after=${from.toISOString()}&published_before=${to.toISOString()}`
  }


  if(time === 'four-eight'){
    const to = moment()
    const from = moment(to).subtract(2,'days')
    return `&published_after=${from.toISOString()}&published_before=${to.toISOString()}`
  }


  if(time === 'breaking'){
    const to = moment()
    const from = moment(to).subtract(12,'hours')
    return `&published_after=${from.toISOString()}&published_before=${to.toISOString()}`
  }

  return ''
}


const search = (query:string,dimension:string, time:string, category:string, sources:string, countries:string, sort:string, lang:string):AxiosPromise => {
  let params = ''

  // if(time.trim() !== ''){
  //   params = params+'&published_after=&published_before='
  // }


  // if(category.trim() !== ''){
  //   params = params+'&category='+category.trim()
  // }
  //
  //
  // params = params+'&category=news|archive'

  if(sources.trim() !== ''){
    params = params+'&sources='+sources.trim()
  }
  if(countries.trim() !== ''){
    params = params+'&countries='+countries.trim()
  }


  if(sort.trim() !== ''){
    params = params+'&sort='+sort.trim()
  }

  params = params+dateRangeForTime(time)
  return apiClient.get('/articles?lang='+lang+'&source=web|presser&query='+query+"&dimension="+dimension+params)
}

const article = (id:string):AxiosPromise => {
  return apiClient.get(`/articles/${id}`)
}


const cards = (id:string):AxiosPromise => {
  return apiClient.get(`/analysis/${id}/cards`)
}

const analysis = (id:string):AxiosPromise => {
  return apiClient.get(`/analysis/${id}/summaries`)
}

const graph= ( type:string, query:string, time:string ,tries=0):Promise<any> => {


  // hack this to give postgres time to evaluate the data
  return new Promise((resolve,reject)=>{

    apiClient.get(`/graph/${type}?id=${query}&time=${time}`,{
      timeout: 1000000
    }).then(resolve)
      .catch(()=>{
        if(tries < 4){
          return graph(type,query,time,tries+1)
        }else{
          return reject(Error("Couldn't fetch graph data"))
        }
      })

  })

}

const bookmarked = (id:string):Promise<any> => {
  return apiClient.get(`/bookmarks/${id}`)
}


const addBookmark = (article:any, query:string):Promise<any> => {

  article.bookmarked = true
  return apiClient.put(`/bookmarks/${article.id}`,
    {
      query: query,
      article: article
    })

}

const removeBookmark = (id:string):Promise<any> => {
  return apiClient.delete(`/bookmarks/${id}`)
}


const bookmarks = ():Promise<any> => {
  return apiClient.get(`/bookmarks`)

}



const spark = (
        label:string,
        type:string,
        size:string,
        query:string,
        lang:string
    ):AxiosPromise => {

  return apiClient.post(`/sparks/creativity?lang=${lang}`,

    {
      "label": label,
      "type": type,
      "size": parseInt(size),
      "hasWiki": true,
      "query": query
    }
  )
}


const personSpark = (
        type:string,
        concept:any,
        lang:string
    ):AxiosPromise => {

  return apiClient.post(`/sparks/people?lang=${lang}&type=${type}`,concept)
}

const entities = (
        entity:string,
        query:string,
        lang:string
    ):AxiosPromise => {
  return apiClient.get(`/entities?lang=${lang}&entity=${entity}&q=${query}`)
}


const wordcloud = (
        id:string
    ):AxiosPromise => {
  return apiClient.get(`/articles/${id}/wordcloud`)
}


const user = ():AxiosPromise => {
  return apiClient.get(`/user`)

}

const extendedUser = ():AxiosPromise => {
  return apiClient.get(`/user/extended`)

}


const token = ():AxiosPromise => {
  return apiClient.get(`/user/token`)

}


const quest = (quest:string):AxiosPromise => {
  return apiClient.get(`/quest/${quest}`)

}

const historyAdd = (history:any):Promise<any> => {
  return apiClient.put(`/history`,history)
}

const historyDelete = (
  id:string
):Promise<any> => {
  return apiClient.delete(`/history/${id}`)
}

const history = ():AxiosPromise<any> => {
  return apiClient.get(`/history`)
}


const sources = (lang,countries):AxiosPromise<any> => {

  return apiClient.get(`/sources?lang=${lang}&countries=${countries}`)
}


const sourcesLanguages = ():AxiosPromise<any> => {
  return apiClient.get(`/sources/languages`)
}


const sourcesCountries = (lang):AxiosPromise<any> => {
  return apiClient.get(`/sources/countries?lang=${lang}`)
}

const source = (id):AxiosPromise<any> => {
  return apiClient.get(`/sources/${id}`)
}


export const client= {
  search,
  article,
  spark,
  personSpark,
  entities,
  addBookmark,
  removeBookmark,
  cards,
  analysis,
  bookmarks,
  bookmarked,
  wordcloud,
  user,
  extendedUser,
  graph,
  token,
  history,
  historyAdd,
  historyDelete,
  quest,
  sources,
  sourcesLanguages,
  sourcesCountries,
  source
}

export const install =  (app: App) => {

  auth = app.config.globalProperties.$auth
  console.log("Auth ",auth)
  app.provide('ject', {
    search,
    article,
    spark,
    personSpark,
    entities,
    addBookmark,
    removeBookmark,
    cards,
    analysis,
    bookmarks,
    bookmarked,
    wordcloud,
    user,
    extendedUser,
    graph,
    token,
    history,
    historyAdd,
    historyDelete,
    quest,
    sources,
    sourcesLanguages,
    sourcesCountries,
    source

  })

}


