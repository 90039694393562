<template>
  <div
    v-for="obj in data"
    :key="obj.name"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box"
    id="{{obj.name}}"
  >
    <div
      class="flex justify-between border-b border-gray-200 dark:border-dark-5 px-5 py-4"
    >
    </div>
    <div class="p-2 text-base">
      <div class="h-40 xxl:h-56">
        <img
          v-if="obj.backgroundImage"
          :alt="obj.snippet"
          class="rounded-md"
          :src="require(`@/assets/images/quest/quality-indicators/`+obj.backgroundImage)"
        />
      </div>
      <span class="block font-medium text-base mt-5"
      >{{ obj.snippet }}
      </span>
      <div class="text-gray-700 dark:text-gray-600 mt-2 mb-5 " v-html="obj.description">
      </div>
      <div class="text-big font-medium">
        <ul class="list-disc ml-5">
          <li v-for="(cg, key) in obj.creativeGuidance" :key="key">{{cg.description}}</li>
        </ul>
      </div>

    </div>
  </div>

</template>
<script lang="ts">

import {defineComponent, onMounted, ref} from "vue";
import {client as JECT} from "@/utils/ject";

export default defineComponent({
  setup() {


    const data = ref({})

    onMounted(()=>{

      JECT.quest('indicators').then((response)=>{
        data.value = response.data
      }).catch((error)=>{
        console.error(error)
      })

    })
    return {
      data
    }
  }
})


</script>
