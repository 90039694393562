import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";


// Getters types
export type Getters = {

};


// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  auth: (state: LocalState) => {
    return state.auth
  },

  isAuthenticated: (state: LocalState) => {
    return state.auth.isAuthenticated
  },
  loading: (state: LocalState) => {
    return state.loading
  },
  user: (state: LocalState) => {
    return state.auth.user
  },
  token: (state: LocalState) => {
    return state.token
  },




};
