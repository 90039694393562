

import {computed, defineComponent, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "@/store";
import {useRouter} from "vue-router";

export default defineComponent({
  props:['error'],
  setup(props){

    const {t} = useI18n()
    const token=ref('')
    const store= useStore()
    const router= useRouter()
    const hasError= computed(()=> props.error )
    const url=computed(()=> process.env.VUE_APP_AUTH0_REDIRECT_URL)

    const login=()=>{
      console.log("Setting plugin token ",token.value)
      if(token.value){
        store.commit('user/SET_TOKEN',token.value)
        router.push("/")
      }
    }

    return {
      t,
      url,
      login,
      token,
      hasError
    }
  }
});
