<template>
  <div >

    <!-- BEGIN: Top Bar -->
    <div class="sticky sticky__top top-0  bg-theme-1">
      <div
        class="border-b border-white -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0"
     >
        <div class="top-bar-boxed flex items-center">
          <!-- BEGIN: Logo -->
          <router-link
            :to="{ name: 'dimension' }"
            tag="a"
            class="-intro-x hidden md:flex md:justify-between"
          >
            <img
              alt="JECT.AI"
              class="w-6 h-7 flex-grow-0"
              src="@/assets/images/logo.svg"
            />
            <span class="text-white text-lg ml-3 h-7 flex-grow-0">
              JECT.AI
            </span>
          </router-link>
          <!-- END: Logo -->
          <!-- BEGIN: Search -->

          <div class="-intro-x searchbar searchbar--light relative mr-auto"
          >
            <SearchHistory/>
            <div class="search hidden sm:block plugin:block" v-show="!questActive">
              <input
                type="text"
                v-model="searchQuery"
                @keyup.enter="doSearch()"
                class="search__input input dark:bg-dark-1 placeholder-theme-13"
                :placeholder="t('PLACEHOLDER_SEARCH_BOX')"
              />

              <SearchIcon class="search__icon dark:text-gray-300"
                          @click.once="doSearch()"/>


            </div>
          </div>
          <!-- END: Search -->
          <Tippy
            v-if="questActive"
            :content="t('TOOLTIP_QUEST_DASHBOARD')"
          >
            <div class="mx-6 text-theme-12">
              <router-link
                to="/"
              >
                <GridIcon/>
              </router-link>
            </div>
          </Tippy>

          <Tippy
            v-if="!questActive"
            class="plugin:hidden"
            :content="t('TOOLTIP_QUEST_EXPLORE')"
          >
            <div class="mx-6 text-white">
              <router-link
                to="/quest"
                class="hover:text-theme-12"
              >
                <GridIcon/>
              </router-link>
            </div>
          </Tippy>

          <!-- END: Search -->
          <Notifications class="plugin:hidden"/>
          <div class="intro-x pt-3 mx-6 plugin:mx-1">
            <LanguagePicker/>
          </div>
          <div class="intro-x intro-x w-8 h-8 relative  plugin:hidden" >
            <AccountDetails />
          </div>
        </div>
      </div>

      <div class="search_filters sm:flex-row sm:-mx-8"
           v-show="!questActive"
      >
        <div class="flex flow-grow plugin:grid plugin:grid-cols-1 plugin:my-3" v-show="searchQuery !=''">
          <div class="flex flex-shrink mr-5 plugin:block plugin:m-1 "
               v-for="filter in f" :key="filter.id"
          >
            <DropdownFilter
              :title="filter.title"
              :id="filter.id"
              :data="filter.options"
              :selected="filter.selected"
              @selected="filterSelected"
            ></DropdownFilter>
          </div>
          <div class="flex flex-shrink mr-5 plugin:hidden">
            <SourcesFilter
              @selected="sourcesFilterSelected"/>
          </div>
        </div>
      </div>

    </div>

    <div class="z-10" id="parent">
      <Wordcloud />
      <router-view/>
    </div>

  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "@/store";
import {linkTo} from "@/layouts/side-menu";
import {filters} from "@/views/dimensions/filters";
import AccountDetails from "@/components/account-details/Main.vue";
import LanguagePicker from "@/components/i18n/LanguagePicker.vue";
import DropdownFilter from "@/components/dropdown/Filter.vue";
import SourcesFilter from "@/components/dropdown/SourcesFilter.vue";
import Notifications  from "@/components/notifications/Notifications.vue";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import Wordcloud from "@/components/wordcloud/Wordcloud.vue";
import SearchHistory from "@/components/searchhistory/SearchHistory.vue";
import AdvancedLanguageCountryPicker from "@/components/i18n/AdvancedLanguageCountryPicker.vue";

export default defineComponent({
  components: {
    LanguagePicker,
    DropdownFilter,
    SourcesFilter,
    AccountDetails,
    Notifications,
    Wordcloud,
    SearchHistory
  },
  props:[
    'time',
    'lang',
    'category',
    'sources',
    'sourceLang',
    'countries',
    'sort',
    'dimension',
    'q'],

  setup(props) {


    const store = useStore();
    const router = useRouter();
    const { t,locale } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const dimensions = computed(() => store.state.main.dimensions)
    const search = computed(() => {
      return store.getters['main/search']
    })

    const f = filters(t)
    const questActive = computed(() => router.currentRoute.value.name === "quest" )
    const searchQuery = ref('')
    const sources = ref({
      "language":locale.value,
      "countries":[],
      "sources":[]
    })

    const filterSelected = (data) => {
      const filter = f.value.find((x) => x.id === data.id)
      if(filter && filter.selected !== data.selection){
        filter.selected = data.selection
        doSearch()
      }
    }

    const sourcesFilterSelected = (data) => {
      sources.value = data
      doSearch()
    }

    watch(locale,(newLocale) =>{
      store.dispatch("main/SELECT_LANGUAGE",newLocale)
      searchQuery.value=''
    })

    const doSearch= (save=true) => {
      if (search.value ===''){
        return
      }

      const q = Object.assign({},search.value)
      q.query = searchQuery.value
      q.time = f.value[0].selected
      q.sort = f.value[1].selected
      q.category = "news|science"
      q.countries = sources.value.countries.join("|")
      q.sources = sources.value.sources.join("|")
      q.sourceLang = sources.value.language
      q.lang  = locale.value

      store.dispatch("main/SEARCH",q);

      if(save){
        store.dispatch('searchHistory/ADD',q)
      }
    }

    watch(search, (newValue)=>{
      router.push(
        store.getters['main/searchPath']("/",newValue)
      )
    })

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");

      if(props.q){
        searchQuery.value = props.q
        const q = {
          query: props.q,
            dimension: props.dimension,
          time: props.time,
          sort: props.sort,
          category: 'news|science',
          sourceLang : props.sourceLang,
          countries :  props.countries,
          sources : props.sources,
          lang: props.lang
        }
        store.dispatch("main/SEARCH",q);
      }

    });


    return {
      questActive,
      search,
      dimensions,
      filters,
      filterSelected,
      sourcesFilterSelected,
      linkTo,
      searchQuery,
      doSearch,
      t,
      f
    }
  }

});
</script>
