<template>
  <DropdownLanguage
    id="ui-language-dropdown"
    title=""
    :data="locales"
    :selected="i18nSelected"
    @selected="languageSelected"
  />
</template>
<script lang="ts">

import {computed, defineComponent} from "vue";
import {useI18n} from "vue-i18n";
import DropdownLanguage from "@/components/dropdown/Language.vue";

export default defineComponent({

  components:{
    DropdownLanguage
  },
  setup() {

    const { locale } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
    const locales = [
       {'id':'en', 'name': 'EN'}
      ,{'id':'no', 'name': 'NO'}
      ,{'id':'nl', 'name': 'NL'}
      ,{'id':'de', 'name': 'DE'}
      ,{'id':'fr', 'name': 'FR'}
      ,{'id':'it', 'name': 'IT'}
      ,{'id':'el', 'name': 'EL'}
    ]


    const i18nSelected = computed( () => locale.value )

    const languageSelected = (data) => {
      locale.value = data.selection
    }

    return {
      locales,
      i18nSelected,
      languageSelected
    }



  }
})
</script>
