export const message = {
  "MENU_EVERYTHING" : "Tout",
  "MENU_BACKING" : "Fondement et Preuve",
  "MENU_INDIVIDUAL" : "Individual",
  "MENU_CASUAL" : "Casual",
  "MENU_RAMIFICATION" : "Ramification",
  "FILTER_YEAR" : "Temps",
  "FILTER_ORDER" : "Ordre",
  "COPY_PLUGIN_TOKEN" : "Copy Plugin Token",
  "USER_LOGOUT_TITLE" : "Logout",
  "NOTIFICATIONS_TITLE" : "Notifications",
  "BTN_EXPLORE_MORE" : "Explorer plus",
  "PILL_MODAL_WIKI" : "Explorez les articles suivants reliant <em>{ titre }</em> à vos sujets",
  "BTN_BOOKMARK" : "Bookmark",
  "EXPLANATION_LABEL_TITLE" : "Qu'est-ce que cette carte ?",
  "CARD_ARTICLE_DROPDOWN_COPY" : "Copier la référence de l'article",
  "CARD_ARTICLE_DROPDOWN_REFRESH" : "Rafraîchir les angles possibles",
  "CARD_ARTICLE_DROPDOWN_WC" : "Afficher le nuage de mots",
  "CARD_ARTICLE_DROPDOWN_SIMILAR" : "Articles similaires via JECT.AI",
  "CARD_ARTICLE_DROPDOWN_SIMILAR_GOOGLE" : "Articles similaires via Google",
  "CARD_ARTICLE_DROPDOWN_EXPLAIN" : "Expliquer cette carte",
  "CARD_COMBINATION_DROPDOWN_EXPLAIN" : "Expliquer cette carte",
  "CONTENT_COMBINATIONS_TITLE" : "La JECT.AI a identifié des combinaisons intéressantes de sujets de réflexion",
  "CARD_COMBINATION_EXPLORE" : "Explorez les angles d'actualités qui pourraient impliquer <em>{ entités }</em> et <em>{ sujet }</em>",
  "CARD_COMBINATION_EXPLAIN" : "JECT.AI a découvert ces sujets à combiner à partir de millions d'articles qu'elle a découverts et indexés. Les articles sont récupérés en raison des similarités calculées entre des termes tels que <em>{topic}</em> et les sujets rapportés dans les articles. JECT.AI a calculé que chaque article est suffisamment similaire en fonction de la stratégie que vous avez sélectionnée et des paramètres que vous avez définis. Il a ensuite sélectionné et présenté les sujets rapportés dans la plupart des articles récupérés",
  "CARD_INTELLIGENCE_DROPDOWN_EXPLAIN" : "Expliquer cette carte",
  "CARD_INTELLIGENCE_TITLE" : "JECT.AI a découvert des personnes apparentées, équilibrées par sexe et par ethnie",
  "CARD_INTELLIGENCE_JOURNALISTS" : "Explorez les divers journalistes qui écrivent sur des histoires connexes",
  "CARD_INTELLIGENCE_SCHOLARS" : "Engagez-vous auprès de l'ensemble des scientifiques qui ont contribué à des recherches connexes",
  "CARD_INTELLIGENCE_TWITTER" : "Les experts qui sont actifs sur Twitter",
  "CARD_INTELLIGENCE_EXPLAIN_A" : "JECT.AI a découvert ces journalistes, scientifiques et autres experts qui ont écrit sur des thèmes liés à votre ou vos sujets. Les journalistes sont les noms des journalistes qui ont écrit des articles découverts parmi les millions d'articles qu'il a découverts et indexés. Les scientifiques et autres experts sont les noms des scientifiques nommés comme auteurs dans des sources telles que Google Scholar.",
  "CARD_INTELLIGENCE_EXPLAIN_B" : "De plus, JECT.AI encourage des voix plus diverses dans les actualités. Elle cherche à équilibrer chaque ensemble de journalistes et de scientifiques présentés par sexe et par origine ethnique, sur la base de leurs noms.",
  "CARD_LANDING_DROPDOWN_ABOUT" : "A propos de cette carte",
  "CARD_LANDING_DROPDOWN_MORE" : "More angles few write about",
  "CARD_LANDING_DROPDOWN_EXPLORE" : "Explore wild card angles",
  "CARD_LANDING_TITLE" : "JECT a découvert plus de {total} histoires liées",
  "CARD_LANDING_SUBTITLE" : "<em>{total}</em> d'entre elles sont positives",
  "CARD_LANDING_CONTENT_MANY" : "Explorer de nouveaux angles sur lesquels beaucoup écrivent",
  "CARD_LANDING_CONTENT_SOME" : "Explorer de nouveaux angles sur lesquels certains écrivent",
  "CARD_LANDING_CONTENT_FEW" : "Et explorer de nouveaux angles sur lesquels seuls quelques uns écrivent",
  "CARD_LANDING_EXTRA_CONTENT" : "<em>{pastWeek}</em> ont été publiés la semaine dernière,\n<em>{pastMonth}</em> le mois dernier et\n{pastYear} l'année dernière, la plupart ont été publiés dans <em>{mostSources}</em>",
  "CARD_LANDING_EXTRA_SUBTITLE" : "En outre, JECT propose également un ensemble d'angles de joker, dont certains pourraient vous être utiles :",
  "CARD_LANDING_EXPLAIN_A" : "JECT.AI a découvert ces sujets à partir de millions d'articles qu'elle a découverts et indexés. Les articles sont récupérés en raison des similitudes calculées entre des termes tels que <em>{query }</em> et les sujets rapportés dans les articles.",
  "CARD_LANDING_EXPLAIN_B" : "JECT.AI a calculé que chaque article est suffisamment similaire en fonction de la stratégie que vous avez sélectionnée et des paramètres que vous avez définis. Elle a ensuite calculé les sujets rapportés dans plusieurs des articles, dans certains de ces articles et dans quelques-uns seulement",
  "TOOLTIP_QUEST_DASHBOARD" : "Revenir aux angles découverts",
  "TOOLTIP_QUEST_EXPLORE" : "Voir les caractéristiques du journalisme scientifique",
  "DISCOVER_LOADING_MESSAGE" : "JECT.AI découvre du contenu pour vous",
  "TOOLTIP_DIMENSION_EVIDENCE": "FONDEMENT ET PREUVE - Preuve quantitative (p. Ex. statistiques et mesures)",
  "TOOLTIP_DIMENSION_INDIVIDUAL": "INDIVIDUAL - Qui sont les acteurs clés? Le point de vue d'un individu",
  "TOOLTIP_DIMENSION_CAUSAL": "CAUSAL - Le fond, l'histoire, ce qui a causé quelque chose",
  "TOOLTIP_DIMENSION_QUIRKY": "DECALE - Satire, ce qui n'est pas évident (caricatures)",
  "TOOLTIP_DIMENSION_RAMIFICATION": "RAMIFICATION - Ce que nous réserve l'avenir? (Bientôt disponible)",
  "TOOLTIP_DIMENSION_VISUALISATION": "VISUALISATION DES DONNÉES - Graphiques et infographies (bientôt disponible)",
  "TOOLTIP_DIMENSION_ANY": "TOUT - Découvrez sans limites",
  "QUEST_ABOUT_TITLE" : " À propos ",
  "QUEST_ABOUT_CONTENT" : "<p>JECT.AI fournit des conseils spécialisés aux journalistes qui écrivent sur la science. Ces conseils comprennent :</p>\n<ul class=\"list-disc ml-4 mb-5\">\n<li>Des publics types pour les articles scientifiques, de l'enthousiaste au désengagé. Connaître son public est la clé d'une communication efficace</li>\n<li>Des métaphores testées et éprouvées pour communiquer rapidement sur la science. Les métaphores actuelles communiquent sur les vaccins, le changement climatique et l'IA</li>\n<li>Indicateurs d'un bon journalisme sur la science. Utilisez-les pour réfléchir à de nouveaux articles, pour les affiner et les améliorer, avant de les publier</li>\n<li>Différents rôles que la science et le public peuvent jouer dans vos histoires. Utilisez-les pour élaborer des récits plus convaincants</li>\n<li>Des explicatifs simples pouvant être utilisés pour décrire des processus scientifiques à des publics non scientifiques</li>\n</ul>\n<p>Ces conseils spécialisés ont été élaborés par le projet QUEST H2020 financé par l'UE. Pour en savoir plus sur ses conseils, sa boîte à outils et ses podcasts, cliquez ici [<a href=\"https://questproject.eu\" target=\"_blank\">https://questproject.eu</a>]</p>",
  "QUEST_PERSONAS_TITLE" : "Audiences scientifiques",
  "QUEST_PERSONAS_PILL_MORE" : "Plus",
  "QUEST_METAPHORS_TITLE" : "Métaphores",
  "QUEST_STORY_TITLE" : "Rôles des histoires",
  "QUEST_EXPLAINERS_TITLE" : "Explicateurs",
  "QUEST_INDICATORS_TITLE" : "Indicateurs",
  "TOOLTIP_QUEST_FEATURE_PERSONA" : "PERSONAS - série de personnages de lecteurs rejetant la science",
  "TOOLTIP_QUEST_FEATURE_STORY_ROLE" : "STORY ROLES - rôles de narration adaptés au journalisme scientifique",
  "TOOLTIP_QUEST_FEATURE_EXPLAINER" : "EXPLAINERS - explications sur la recherche multisource, sur la manière dont les revues et l'examen par les pairs peuvent être fiables, etc.",
  "TOOLTIP_QUEST_FEATURE_METAPHOR" : "METAPHORS - métaphores pour communiquer efficacement 1 ou 2 idées simples",
  "TOOLTIP_QUEST_FEATURE_INDICATOR" : "INDICATEURS DE QUALITÉ - éléments de performance de la pratique utilisés pour évaluer la qualité",
  "LABEL_OPTIONS_PUBLISH_DATE" : "Date de publication",
  "LABEL_OPTIONS_PUBLISH_DATE_ANY" : "Ces dernières années",
  "LABEL_OPTIONS_PUBLISH_DATE_1YEAR" : "Année passée",
  "LABEL_OPTIONS_PUBLISH_DATE_6MONTHS" : "6 derniers mois",
  "LABEL_OPTIONS_PUBLISH_DATE_1MONTH" : "Mois dernier",
  "LABEL_OPTIONS_PUBLISH_DATE_1WEEK" : "Semaine passée",
  "LABEL_OPTIONS_PUBLISH_DATE_2DAYS" : "Dernière 48 heures",
  "LABEL_FILTER_PUBLISH_DATE_ANY" : "années",
  "LABEL_FILTER_PUBLISH_DATE_1YEAR" : "année",
  "LABEL_FILTER_PUBLISH_DATE_1MONTH" : "mois",
  "LABEL_FILTER_PUBLISH_DATE_1WEEK" : "semaine",
  "LABEL_FILTER_PUBLISH_DATE_2DAYS" : "48 hrs",
  "LABEL_FILTER_PUBLISH_BREAKING" : "Breaking",
  "LABEL_OPTIONS_SORT_TIME" : "Temps",
  "LABEL_OPTIONS_SORT_RELEVANCE" : "Pertinence",
  "LABEL_OPTIONS_SORT_RANDOM" : "Aléatoire",
  "LABEL_OPTIONS_SORT_SENTIMENT_POS" : "Positif",
  "LABEL_OPTIONS_SORT_SENTIMENT_NEG" : "Négatif",
  "LABEL_OPTIONS_SOURCE_SCIENCE" : "Science",
  "LABEL_OPTIONS_SOURCE_NEWS" : "Nouvelles",
  "LABEL_OPTIONS_SOURCE_ALL" : "Tous",
  "LABEL_OPTIONS_SOURCE" : "La source",
  "PLACEHOLDER_SEARCH_BOX" : "Décrivez votre sujet d'actualité avec des sujets",
  "PAIEMENT" : {
    "TITLE" : "Prix",
    "PRICE" : "&euro;/mo",
    "ACHAT" : "ACHETER MAINTENANT",
    "FREELANCERS" : {
      "TITLE" : "FREELANCERS",
      "FEATURES" : "<li>Agrégation de nouvelles en temps réel &amp ; recherche</li>\n<li>Enrichissement NLP</li>\n<li>Support de la créativité et de l'intelligence</li>\n<li>Support prioritaire par email</li>\n<li>Accès au centre d'aide</li>"
    },
    "NEWSROOMS" : {
      "TITLE" : "NEWSROOMS",
      "FEATURES" : "<li>Plan de rééquilibrage</li>\n<li>Intégrations dans les principaux CMS ( Wordpress, et.al)</li>\n<li><em>inclut jusqu'à 10 licences utilisateur</em></li>"
    },
    "EDUCATION" : {
      "TITLE" : "ÉTABLISSEMENTS D'ENSEIGNEMENT",
      "FEATURES" : "<li>Plan de refinancement</li>\n<li>Intégrations dans les principaux CMS ( Wordpress, et.al)</li>\n<li><em>inclut jusqu'à 10 licences utilisateur</em></li>"
    }
  },
  "ACCOUNT_BOOKMARKS_MENU": "Signets",
  "BOOKMARKS_EMPTY": "Signets vides",
  "BOOKMARK_META_DESCRIPTION": "Marqué d'un signet <em>{date}</em> en utilisant la requête <strong>{query}</strong>",
  "PILL_MODAL_EMTPY_WIKI": "Il n'y a plus rien à explorer pour le <em>{title}</em>"
}


