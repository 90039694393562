import { App } from "vue";
import _ from "lodash";

const install = (app: App): void => {
  app.config.globalProperties.$_ = _;

  app.provide('lodash',_);
};

export { install as default };
