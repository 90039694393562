

import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";


export default defineComponent({

  props: ['id','query','source_id'],
  setup(props){

    const store= useStore();
    const loading=ref(false)
    const source=ref(null)

    onMounted(() => {
      loading.value=true
      store.dispatch('sources/GET_SOURCE',props.source_id).then((response)=>{
        source.value= response.data
      }).finally(()=>{
        loading.value=false
      })
    })

    return {
      loading,
      source
    }
  }
})
