import {ActionContext, ActionTree} from "vuex";
import {State as RootState} from "@/store";
import {State as LocalState} from "./state";
import {Mutations} from "./mutations";
import {MutationTypes} from "./mutation-types";
import {client as JECT} from "@/utils/ject";
import _ from "lodash";

// Action enums
enum ActionTypes {
  GET_SOURCE = "GET_SOURCE",
  GET_LANGUAGES = "GET_LANGUAGES",
  GET_COUNTRIES = "GET_COUNTRIES",
  GET_SOURCES = "GET_SOURCES"
}

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.GET_SOURCE]({state,commit}: AugmentedActionContext, id:string): void;
  [ActionTypes.GET_LANGUAGES]({state,commit}: AugmentedActionContext): void;
  [ActionTypes.GET_COUNTRIES]({state,commit}: AugmentedActionContext,lang:string): void;
  [ActionTypes.GET_SOURCES]({state,commit}: AugmentedActionContext, {lang, countries}): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.GET_SOURCE]({state,commit}: AugmentedActionContext,id) {
    if(state.cache[id]){
      return new Promise(resolve => { resolve(state.cache[id])})
    }else{
      return JECT.source(id)
    }
  },
  [ActionTypes.GET_LANGUAGES]({state,commit}: AugmentedActionContext) {
      return JECT.sourcesLanguages()
  },
  [ActionTypes.GET_COUNTRIES]({state,commit}: AugmentedActionContext, lang) {
      return JECT.sourcesCountries(lang)
  },
  [ActionTypes.GET_SOURCES]({state,commit}: AugmentedActionContext, {lang, countries}) {
      return JECT.sources(lang,countries.join('|'))
  },
};
