<template>
    <a href="#"
       class="mt-3 px-7 mb-0 underline hover:text-theme-3"
    >{{ state ? 'Deselect All':'Select All'}}</a>
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue';

export default defineComponent({
    props:['select','state'],
    setup(props){
      return {
      }
    }
  })

</script>
