<template>
  <div
    v-for="obj in metaphors"
    :key="obj.name"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box"
    id="{{obj.name}}"
  >
    <div
      class="flex justify-between border-b border-gray-200 dark:border-dark-5 px-5 py-4"
    >
    </div>
    <div class="p-5 text-base">
      <div class="h-40 xxl:h-56 image-fit">
        <img
          v-if="obj.image.url"
          :alt="obj.name"
          class="rounded-md"
          :src="obj.image.name"
        />
        <span
          class="text-tiny p-1 bg-theme-1 text-theme-12 absolute bottom-0 right-0 ">
          Metaphor
        </span>
      </div>
      <span class="block font-medium text-base mt-5"
      >{{ obj.name }}
        </span>
      <div class="text-gray-700 dark:text-gray-600 mt-2 mb-5 ">
        {{ obj.description }}
      </div>
      <div v-if="obj.sourceObjects || obj.targetObjects">

        <CardPill
          v-for="(item) in obj.sourceObjects"
          :key="item"
          :title="item"
          size="1"
          type="metaphor-source"
          hasWiki="false"
          hideExplore="true"
          :id="item"
        />
        <CardPill
          v-for="(item) in obj.targetObjects"
          :key="item"
          :title="item"
          size="1"
          hasWiki="false"
          hideExplore="true"
          type="metaphor-target"
          :id="item"
        />

      </div>
    </div>
  </div>

</template>
<script lang="ts">

import {defineComponent,ref,onMounted} from "vue";
// import metaphors from '../data/metaphors.json';
import {client as JECT} from "@/utils/ject";

export default defineComponent({
  setup() {

    const metaphors = ref({})
    onMounted(()=>{

      JECT.quest('metaphors').then((response)=>{
        metaphors.value = response.data
      }).catch((error)=>{
        console.error(error)
      })

    })


    return {
      metaphors
    }
  }
})


</script>
