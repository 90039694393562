<template>
  <div class="inline-block mr-3 text-gray-500">
    {{ time }}
  </div>

</template>
<script lang="ts">

import {computed,defineComponent,inject} from 'vue';
import moment from 'moment';


export default defineComponent({
  props:['date'],
  setup(props) {

    const timeago = inject<any>('ta')
    const time = computed(() => {
      try{
        return timeago.format(moment(props.date).toDate())
      }catch (Error){
        return ''
      }
    })


    return {
      time
    }

  }

})

</script>
