// Mutations enums
export enum MutationTypes {
  LOADING = "LOADING",
  SET_TOKEN = "SET_TOKEN",
  SET_USER = "SET_USER",
  SET_POPUP = "SET_POPUP",
  SET_AUTHENTICATED = "SET_AUTHENTICATED",
  SET_ERROR = "SET_ERROR",
  SET_AUTH_LOADING = "SET_AUTH_LOADING",
  SET_STATUS = "SET_STATUS"
}

