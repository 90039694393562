export const message = {
  "MENU_EVERYTHING": "Everything",
  "MENU_BACKING": "Backing & Evidence",
  "MENU_INDIVIDUAL": "Individual",
  "MENU_CASUAL": "Causal",
  "MENU_RAMIFICATION": "Ramification",
  "FILTER_YEAR": "Time",
  "FILTER_ORDER": "Order",
  "COPY_PLUGIN_TOKEN": "Copy Plugin Token",
  "USER_LOGOUT_TITLE": "Logout",
  "NOTIFICATIONS_TITLE": "Notifications",
  "BTN_EXPLORE_MORE": "Explore More",
  "PILL_MODAL_WIKI": "Explore the following articles linking <em>{ title }</em> to your topics",
  "BTN_BOOKMARK": "Bookmark",
  "EXPLANATION_LABEL_TITLE": "What is this card?",
  "CARD_ARTICLE_DROPDOWN_COPY": "Copy article reference",
  "CARD_ARTICLE_DROPDOWN_REFRESH": "Refresh possible angles",
  "CARD_ARTICLE_DROPDOWN_WC": "Show as word cloud",
  "CARD_ARTICLE_DROPDOWN_SIMILAR": "Similar stories via JECT.AI",
  "CARD_ARTICLE_DROPDOWN_SIMILAR_GOOGLE": "Similar stories via Google",
  "CARD_ARTICLE_DROPDOWN_EXPLAIN": "Explain this card",
  "CARD_COMBINATION_DROPDOWN_EXPLAIN": "Explain this card",
  "CONTENT_COMBINATIONS_TITLE": "JECT.AI has identified interesting combinations of topics to think about",
  "CARD_COMBINATION_EXPLORE": "Explore news angles that might involve <em>{ entities }</em> and <em>{ topic }</em>.",
  "CARD_COMBINATION_EXPLAIN": "JECT.AI has discovered these topics to combine from millions of articles that it has discovered and indexed. The articles are retrieved because of computed similarities between query terms and topics reported in the articles. JECT.AI computed that each article is sufficiently similar based on the strategy you selected and settings you defined. It then selected and presented topics reported in most of the retrieved articles",
  "CARD_INTELLIGENCE_DROPDOWN_EXPLAIN": "Explain this card",
  "CARD_INTELLIGENCE_TITLE": "JECT.AI has discovered related people, balanced by gender and ethnicity",
  "CARD_INTELLIGENCE_JOURNALISTS": "Explore the diverse journalists who are writing about related stories",
  "CARD_INTELLIGENCE_SCHOLARS": "Engage with the breadth of scientists who have contributed to related research",
  "CARD_INTELLIGENCE_TWITTER": "Experts who are active on Twitter",
  "CARD_INTELLIGENCE_EXPLAIN_A": "JECT.AI has discovered these journalists, scientists and other experts who have written on published on\nthemes related to your topic or topics. The journalists are the names of journalists who have written articles\ndiscovered from the millions of articles that it has discovered and indexed. The scientists and other experts\nare the names of scientists named as authors in sources such as Google Scholar.",
  "CARD_INTELLIGENCE_EXPLAIN_B": "Furthermore, JECT.AI encourages more diverse voices in news. It seeks to balance each set of presented\\njournalists and scientists by gender and by ethnicity, based on their names.",
  "CARD_LANDING_DROPDOWN_ABOUT": "About this card",
  "CARD_LANDING_DROPDOWN_MORE": "More angles few write about",
  "CARD_LANDING_DROPDOWN_EXPLORE": "Explore wild card angles",
  "CARD_LANDING_TITLE": "JECT has discovered more than {total} related stories",
  "CARD_LANDING_SUBTITLE": "<em>{total}</em> of these are positive",
  "CARD_LANDING_CONTENT_MANY": "Explore new angles that many write about",
  "CARD_LANDING_CONTENT_SOME": "Explore new angles that some write about",
  "CARD_LANDING_CONTENT_FEW": "And explore new angles that only a few write about",
  "CARD_LANDING_EXTRA_CONTENT": "<em>{pastWeek}</em> were published in the last week,\n<em>{pastMonth}</em> in the last month and\n{pastYear} in the last year, most were published in <em>{mostSources}</em>",
  "CARD_LANDING_EXTRA_SUBTITLE": "Furthermore, JECT also offers a set of wildcard angles, some of which you might find useful:",
  "CARD_LANDING_EXPLAIN_A": "JECT.AI has discovered these topics from millions of articles that it has\ndiscovered and indexed. The articles are retrieved because of computed\nsimilarities between terms such as <em>{ query }</em> and topics reported in the articles.",
  "CARD_LANDING_EXPLAIN_B": "JECT.AI computed that each article is sufficiently similar based on the strategy you selected and settings you defined. It then computed topics reported in many of the articles, some of these articles, and just a few",
  "TOOLTIP_QUEST_DASHBOARD": "Switch back to discovered angles",
  "TOOLTIP_QUEST_EXPLORE": "See science journalism features",
  "DISCOVER_LOADING_MESSAGE": "JECT.AI is discovering content for you",
  "TOOLTIP_DIMENSION_EVIDENCE": "BACKING AND EVIDENCE - Quantitative evidence (e.g. numbers and measures)",
  "TOOLTIP_DIMENSION_INDIVIDUAL": "INDIVIDUAL – Who are the key players? The viewpoint of an individual",
  "TOOLTIP_DIMENSION_CAUSAL": "CAUSAL – The background, the history, what caused something",
  "TOOLTIP_DIMENSION_QUIRKY": "QUIRKY – Satire, not the obvious (cartoons)",
  "TOOLTIP_DIMENSION_RAMIFICATION": "RAMIFICATION – What for the future?",
  "TOOLTIP_DIMENSION_VISUALISATION": "DATA VISUALISATION – Charts and infographics (coming soon)",
  "TOOLTIP_DIMENSION_ANY": "EVERYTHING - Discover without limits",
  "QUEST_ABOUT_TITLE": "About",
  "QUEST_ABOUT_CONTENT": "<p>JECT.AI provides specialist guidance to journalists writing about science. This guidance includes:</p>\n<ul class=\"list-disc ml-4 mb-5\">\n<li>Typical audiences for science stories, from the enthusiastic to the disengaged. Knowing your audience is key to communicating effectively</li>\n<li>Tried-and-tested metaphors for communicating science quickly. The current metaphors communicate about vaccines, climate change and AI</li>\n<li>Indicators of good journalism about science. Use them to reflect on new articles, to refine and enhance them, before publishing</li>\n<li>Different roles that science and the public can play in your stories. Use them to develop more compelling narratives</li>\n<li>Simple explainers that can be used to describe scientific processes to non-science audiences</li>\n</ul>\n<p>This specialist guidance was developed by the EU-funded H2020 QUEST project. Learn more about its guidance, toolbox and podcasts here [<a href=\"https://questproject.eu\" target=\"_blank\">https://questproject.eu</a>]</p>",
  "QUEST_PERSONAS_TITLE": "Science Audiences",
  "QUEST_PERSONAS_PILL_MORE": "More",
  "QUEST_METAPHORS_TITLE": "Metaphors",
  "QUEST_STORY_TITLE": "Story Roles",
  "QUEST_EXPLAINERS_TITLE": "Explainers",
  "QUEST_INDICATORS_TITLE": "Indicators",
  "TOOLTIP_QUEST_FEATURE_PERSONA": "PERSONAS - series of science-rejecting reader personas ",
  "TOOLTIP_QUEST_FEATURE_STORY_ROLE": "STORY ROLES - storytelling roles adapted to science journalism ",
  "TOOLTIP_QUEST_FEATURE_EXPLAINER": "EXPLAINERS - explanations of multi-source research, of how journals and peer-review can be trusted, etc.",
  "TOOLTIP_QUEST_FEATURE_METAPHOR": "METAPHORS - these communicate 1 or 2 simple ideas effectively",
  "TOOLTIP_QUEST_FEATURE_INDICATOR": "QUALITY INDICATORS - elements of practice performance used to assess the quality",
  "LABEL_OPTIONS_PUBLISH_DATE": "Publish date",
  "LABEL_OPTIONS_PUBLISH_DATE_ANY": "Past few years",
  "LABEL_OPTIONS_PUBLISH_DATE_1YEAR": "Past year",
  "LABEL_OPTIONS_PUBLISH_DATE_6MONTHS": "Past 6 months",
  "LABEL_OPTIONS_PUBLISH_DATE_1MONTH": "Past month",
  "LABEL_OPTIONS_PUBLISH_DATE_1WEEK": "Past week",
  "LABEL_OPTIONS_PUBLISH_DATE_2DAYS": "Past 48 hours",
  "LABEL_FILTER_PUBLISH_DATE_ANY": "years",
  "LABEL_FILTER_PUBLISH_DATE_1YEAR": "year",
  "LABEL_FILTER_PUBLISH_DATE_1MONTH": "month",
  "LABEL_FILTER_PUBLISH_DATE_1WEEK": "week",
  "LABEL_FILTER_PUBLISH_DATE_2DAYS": "48 hrs",
  "LABEL_FILTER_PUBLISH_BREAKING": "Breaking",
  "LABEL_OPTIONS_SORT_TIME": "Time",
  "LABEL_OPTIONS_SORT_RELEVANCE": "Relevance",
  "LABEL_OPTIONS_SORT_RANDOM": "Random",
  "LABEL_OPTIONS_SORT_SENTIMENT_POS": "Positive",
  "LABEL_OPTIONS_SORT_SENTIMENT_NEG": "Negative",
  "LABEL_OPTIONS_SOURCE_SCIENCE": "Science",
  "LABEL_OPTIONS_SOURCE_NEWS": "News",
  "LABEL_OPTIONS_SOURCE_ALL": "All",
  "LABEL_OPTIONS_SOURCE": "Source",
  "PLACEHOLDER_SEARCH_BOX": "Describe your news story with topics",
  "PAYMENT": {
    "TITLE": "Pricing",
    "PRICE": "&euro;/mo",
    "PURCHASE": "PURCHASE NOW",
    "FREELANCERS": {
      "TITLE": "FREELANCERS",
      "FEATURES": "<li>Real-time news aggregation &amp; search</li>\n<li>NLP enrichment</li>\n<li>Creativity and Intelligence support</li>\n<li>Priority email support</li>\n<li>Help center access</li>"
    },
    "NEWSROOMS": {
      "TITLE": "NEWSROOMS",
      "FEATURES": "<li>Freelancing Plan</li>\n<li>Integrations into major CMS ( Wordpress, et.al)</li>\n<li><em>includes up to 10 user licenses</em></li>"
    },
    "EDUCATIONAL": {
      "TITLE": "EDUCATIONAL INSTITUTIONS",
      "FEATURES": "<li>Freelancing Plan</li>\n<li>Integrations into major CMS ( Wordpress, et.al)</li>\n<li><em>includes up to 10 user licenses</em></li>"
    }
  },
  "ACCOUNT_BOOKMARKS_MENU": "Bookmarks",
  "BOOKMARKS_EMPTY": "Bookmarks Empty",
  "BOOKMARK_META_DESCRIPTION": "Bookmarked <em>{date}</em> using <strong>{query}</strong> query",
  "PILL_MODAL_EMTPY_WIKI": "There is nothing more to explore for <em>{title}</em>",
  "BTN_SHOW_PERSONA_DESCRIPTION": "Show persona description",
  "PLUGIN":{
    "LOGIN":"Login",
    "INPUT_TOKEN_PLACEHOLDER":"Paste your token"
  }
}


