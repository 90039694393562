<template>
  <nav class="pill-nav">
    <ul>
      <template v-for="(quest,key) in quests" :key="key">

        <li>
          <Tippy
            tag="a"
            href="javascript;"
            class="top-menu top-menu-quest"
            :content="t(quest.alt)"
            :class="{
                  'top-menu--active': isSelected(key)
                }"
            @click.prevent="select(key)"
          >
            <div class="top-menu__icon">
              <font-awesome-icon :icon="quest.icon" class="fa-1x"/>
            </div>

            <div class="top-menu__title">
              <span class="mr-2">{{ t(quest.title) }}</span>
            </div>
          </Tippy>
        </li>
      </template>
      <li>
        <Tippy
          tag="a"
          v-if="questPdfUrl"
          :href="questPdfUrl"
          class="top-menu top-menu-quest"
          content="Download Quality Indicators PDF"
          id="quest-download-pdf"
          target="_blank"
        >
          <FileIcon class=" w-5 h-5"/>
        </Tippy>

      </li>
      <li>
        <a href="javascript:;"
           data-toggle="modal"
           data-target="#quest-about-modal"
           class="top-menu top-menu-quest"
        >
          <div class="top-menu__icon">
            <InfoIcon/>
          </div>
        </a>
      </li>


    </ul>
  </nav>

  <div class="content">
    <div ref='scrollComponent'>
      <div class="intro-y grid grid-cols-12 gap-6 mt-5">
        <component :is="selectedComponent"/>
      </div>
    </div>
    <div id="quest-about-modal" class="modal">
      <div class="modal__content model__content--quest modal__content--xl p-10">
        <h1 class="text-3xl text-theme-1 uppercase mt-5 mb-5">{{ t('QUEST_ABOUT_TITLE') }}</h1>
        <div class="h-auto mb-7" v-html="t('QUEST_ABOUT_CONTENT')">
        </div>
      </div>
    </div>
  </div>

</template>
<script lang="ts">
import {defineComponent, computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import PersonasComponent from "./pages/Personas.vue";
import MetaphorsComponent from "./pages/Metaphors.vue";
import StoryComponent from "./pages/Story.vue";
import ExplainerComponent from "./pages/Explainers.vue";
import IndicatorsComponent from "./pages/Indicators.vue";

export default defineComponent({
  components: {
    PersonasComponent,
    MetaphorsComponent,
    StoryComponent,
    ExplainerComponent,
    IndicatorsComponent,
  },
  setup() {

    const {t, locale} = useI18n()

    const quests =
      {
        'personas': {
          'component': 'PersonasComponent',
          'title': 'QUEST_PERSONAS_TITLE',
          'icon': 'user-friends',
          'alt': 'TOOLTIP_QUEST_FEATURE_PERSONA'
        },
        'metaphors': {
          'component': 'MetaphorsComponent',
          'title': 'QUEST_METAPHORS_TITLE',
          'icon': 'lightbulb',
          'alt': 'TOOLTIP_QUEST_FEATURE_METAPHOR'
        },
        'story': {
          'component': 'StoryComponent',
          'title': 'QUEST_STORY_TITLE',
          'icon': 'theater-masks',
          'alt': 'TOOLTIP_QUEST_FEATURE_STORY_ROLE'
        },
        'explainers': {
          'component': 'ExplainerComponent',
          'title': 'QUEST_EXPLAINERS_TITLE',
          'icon': 'bullhorn',
          'alt': 'TOOLTIP_QUEST_FEATURE_EXPLAINER'
        },
        'certificate': {
          'component': 'IndicatorsComponent',
          'title': 'QUEST_INDICATORS_TITLE',
          'icon': 'certificate',
          'alt': 'TOOLTIP_QUEST_FEATURE_INDICATOR'
        }

      }

    const selected = ref('personas')
    const isSelected = (key) => {
      return selected.value === key
    }
    const select = (key) => {
      selected.value = key
    }
    const selectedComponent = computed(() => {
      return quests[selected.value].component
    })

    const questPdfUrl = computed(() => {
      let ret = null
      if (['en', 'it'].indexOf(locale.value) >= 0) {
        ret = '/pdf/ject-ai-quest-' + locale.value + '.pdf'
      }
      return ret
    })

    return {

      quests,
      questPdfUrl,
      selected,
      select,
      isSelected,
      selectedComponent,
      t
    }
  }
});
</script>
