import {ActionContext, ActionTree} from "vuex";
import {State as RootState} from "@/store";
import {State as LocalState} from "./state";
import {Mutations} from "./mutations";
import {MutationTypes} from "./mutation-types";
import {client as JECT} from "@/utils/ject";
import _ from "lodash";

// Action enums
enum ActionTypes {
  ADD = "ADD",
  DELETE = "DELETE",
  READ = "READ",
}

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.READ]({commit}: AugmentedActionContext): void;
  [ActionTypes.ADD]({commit}: AugmentedActionContext, body: any): void;
  [ActionTypes.DELETE]({commit}: AugmentedActionContext, id: string): void;

}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.READ]({commit}: AugmentedActionContext) {
    JECT.history().then((response)=>{
      commit(MutationTypes.SET, response.data)
    })
  },
  [ActionTypes.DELETE]({commit}: AugmentedActionContext, id) {
    JECT.historyDelete(id).then((response)=>{
      commit(MutationTypes.SET, response.data)
    })
  },
  [ActionTypes.ADD]({commit}: AugmentedActionContext, body) {

    JECT.historyAdd(
      _.extend({
        "created": new Date(),
      },body)
    ).then((response)=>{
      commit(MutationTypes.SET, response.data)
    })
  }

};
