
import { defineComponent} from "vue";
export default defineComponent({
  props:{
    'bookmarked':{
      type:Boolean,
      default:false
    }
  }
})

