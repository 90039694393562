import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

// Getters types
export type Getters = {
  unread(state:LocalState):any
  total(state:LocalState):any
};


// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  unread: (state: LocalState) => {
    return state.notifications.filter(x => x.read === false).length || 0
  },
  total: (state: LocalState) => {
    return state.notifications.length || 0
  }

};
