

import {ref, defineComponent, computed, inject} from 'vue';
import {slugify} from "transliteration";
import AuthorPill from "@/views/dimensions/pill/AuthorPill.vue";
import {useI18n} from "vue-i18n";
import VisualizationDialog from "@/views/dimensions/cards/VisualizationDialog.vue";


export default defineComponent({
  props: ['type', 'card', 'query'],
  components: {
    AuthorPill,
    VisualizationDialog
  },
  setup(props) {
    const {t} = useI18n()
    const lodash = inject<any>('lodash')

    const getPeople = function(people, limit){

      let ret = people

      if (!ret) {
        return []
      }

      if (people.length > 10) {
        ret = []
        for (let i = 0; i < 10; i++) {
          ret.push(people[i])
        }
      }

      return ret


    }
    const journalists = computed(() => {
      return getPeople(props.card.primary.journalists,10)

    })
    const scholars = computed(() => {
      return getPeople(props.card.primary.scholars,10)
    })
    const experts = computed(() => {
      return getPeople(props.card.primary.experts,10)
    })


    const viewState = ref('primary')
    const showState = (state) => {
      viewState.value = state
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }


    const _ethnicityData = (entities,type)=>{

      const ret = {
        'title':   type === 'all' ? 'jounalists & sientists' : type,
        'data': {
          labels: ['ethnicity'],
          datasets: [{
            backgroundColor: [],
            hoverBackgroundColor: [],
            borderWidth: 5,
            borderColor: "#ffff",
            data: [1]
          }]
        },
        'options': {}
      }



      const ethnicityData = lodash.countBy(entities,(e)=> e.ethnicity)
      ret.data.labels= lodash.sortBy(Object.keys(ethnicityData))
      ret.data.datasets[0].data= ret.data.labels.map(key => ethnicityData[key]);

      for(const i in ret.data.labels){
        const color = Math.floor(Math.random()*16777215).toString(16)
        ret.data.datasets[0].backgroundColor.push("#"+color)
      }
      return ret
    }

    const _genderData = (entities, type)=>{
      const ret = {
        'title':   type === 'all' ? 'jounalists & sientists' : type,
        'data': {
          labels: ['Female','Male','N/A'],
          datasets: [{
            title:'Journalists',
            backgroundColor: ['#d18d96','#517fa4','#a0aec0'],
            hoverBackgroundColor: ['#ca253b','#295470','#64666b'],
            data: []
          }]
        },
        'options': {}
      }

      const genderCount = lodash.countBy(entities,(e)=> e.gender)

      ret.data.datasets[0].data = [
        genderCount.male,
        genderCount.female,
        entities.length - (genderCount.male + genderCount.female)
      ]

      return ret

    }

    const showGraph = (graph:string) => {
      cash(`#${graph}-intelligence-modal-size-preview`).modal("show");
    }

    const graphData = ref({
      'title':''
    })

    const showEthinicityGraph = (entities, type:string) => {
      graphData.value = _ethnicityData(
        type === 'all' ?  props.card.primary.journalists : entities,type
      )
      showGraph('graph-ethnicity')
    }

    const showGenderDistributionGraph = (entities, type:string) => {
      graphData.value = _genderData(
        type === 'all' ?  props.card.primary.journalists : entities,type
      )
      showGraph('graph-gender')
    }

    return {
      t,
      viewState,
      showState,
      journalists,
      scholars,
      experts,
      graphData,
      showEthinicityGraph,
      showGenderDistributionGraph,
      slugify
    }

  }
})

