import { createStore } from "vuex";
import { main, Store as MainStore, State as MainState } from "./main";
import { simpleMenu, Store as SimpleMenuStore, State as SimpleMenuState, } from "./simple-menu";
import { topMenu, Store as TopMenuStore, State as TopMenuState, } from "./top-menu";
import { notifications, Store as NotificationsStore, State as NotificationsState, } from "./notifications";
import { history as searchHistory, Store as SearchHistoryStore, State as SearchHistoryState, } from "./history";
import {wordcloud, Store as WordcloudStore, State as WordcloudState, } from "./wordcloud";
import { user, Store as UserInfoStore, State as UserInfoState, } from "./user";
import { sources, Store as SourcesStore, State as SourcesState, } from "./source";


export type State = {
  main: MainState;
  notifications: NotificationsState;
  simpleMenu: SimpleMenuState;
  topMenu: TopMenuState;
  wordcloud: WordcloudState;
  user: UserInfoState;
  searchHistory: SearchHistoryState;
  sources: SourcesState;
};

export type Store = MainStore<Pick<State, "main">> &
  SimpleMenuStore<Pick<State, "simpleMenu">> &
  TopMenuStore<Pick<State, "topMenu">> &
  NotificationsStore<Pick<State, "notifications">> &
  WordcloudStore<Pick<State, "wordcloud">> &
  UserInfoStore<Pick<State, "user">> &
  SearchHistoryStore<Pick<State, "searchHistory">> &
  SourcesStore<Pick<State, "sources">>;

export const store = createStore({
  modules: {
    main,
    notifications,
    simpleMenu,
    topMenu,
    wordcloud,
    user,
    searchHistory,
    sources
  },
});

export function useStore(): Store {
  return store as Store;
}

export default store;
