

import {defineComponent,ref,onMounted} from "vue";
// import metaphors from '../data/metaphors.json';
import {client as JECT} from "@/utils/ject";

export default defineComponent({
  setup() {

    const metaphors = ref({})
    onMounted(()=>{

      JECT.quest('metaphors').then((response)=>{
        metaphors.value = response.data
      }).catch((error)=>{
        console.error(error)
      })

    })


    return {
      metaphors
    }
  }
})


