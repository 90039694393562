
import {defineComponent, computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import PersonasComponent from "./pages/Personas.vue";
import MetaphorsComponent from "./pages/Metaphors.vue";
import StoryComponent from "./pages/Story.vue";
import ExplainerComponent from "./pages/Explainers.vue";
import IndicatorsComponent from "./pages/Indicators.vue";

export default defineComponent({
  components: {
    PersonasComponent,
    MetaphorsComponent,
    StoryComponent,
    ExplainerComponent,
    IndicatorsComponent,
  },
  setup() {

    const {t, locale} = useI18n()

    const quests =
      {
        'personas': {
          'component': 'PersonasComponent',
          'title': 'QUEST_PERSONAS_TITLE',
          'icon': 'user-friends',
          'alt': 'TOOLTIP_QUEST_FEATURE_PERSONA'
        },
        'metaphors': {
          'component': 'MetaphorsComponent',
          'title': 'QUEST_METAPHORS_TITLE',
          'icon': 'lightbulb',
          'alt': 'TOOLTIP_QUEST_FEATURE_METAPHOR'
        },
        'story': {
          'component': 'StoryComponent',
          'title': 'QUEST_STORY_TITLE',
          'icon': 'theater-masks',
          'alt': 'TOOLTIP_QUEST_FEATURE_STORY_ROLE'
        },
        'explainers': {
          'component': 'ExplainerComponent',
          'title': 'QUEST_EXPLAINERS_TITLE',
          'icon': 'bullhorn',
          'alt': 'TOOLTIP_QUEST_FEATURE_EXPLAINER'
        },
        'certificate': {
          'component': 'IndicatorsComponent',
          'title': 'QUEST_INDICATORS_TITLE',
          'icon': 'certificate',
          'alt': 'TOOLTIP_QUEST_FEATURE_INDICATOR'
        }

      }

    const selected = ref('personas')
    const isSelected = (key) => {
      return selected.value === key
    }
    const select = (key) => {
      selected.value = key
    }
    const selectedComponent = computed(() => {
      return quests[selected.value].component
    })

    const questPdfUrl = computed(() => {
      let ret = null
      if (['en', 'it'].indexOf(locale.value) >= 0) {
        ret = '/pdf/ject-ai-quest-' + locale.value + '.pdf'
      }
      return ret
    })

    return {

      quests,
      questPdfUrl,
      selected,
      select,
      isSelected,
      selectedComponent,
      t
    }
  }
});
