import { createApp } from "vue";
import {i18n} from './i18n';


import {setupAuth, callbackRedirect, routeGuard} from '@/auth/auth.plugin'
// import {setupAuth, routeGuard, callbackRedirect} from '@/auth/auth.plugin.store'


import App from "./App.vue";
import router from "./router";
import store from "./store";




import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFrown,
  faSmile,
  faMeh,
  faLightbulb,
  faUserFriends,
  faTheaterMasks,
  faBullhorn, faCertificate
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";

// Midone Theme
import "./assets/sass/app.scss";


import moment from 'moment'
const app = createApp(App)
                .use(store)
                .use(router)
                .use(i18n);


library.add(faFrown)
library.add(faSmile)
library.add(faMeh)
library.add(faLightbulb)
library.add(faUserFriends)
library.add(faTheaterMasks)
library.add(faBullhorn)
library.add(faCertificate)

app.config.globalProperties.$filters = {
  format(value, format='DD/MM/YYYY hh:mm') {
    if (value) {
      return moment(String(value)).format(format)
    }
    return ''
  }
}

router.beforeEach(routeGuard)

setupAuth({
  "domain": process.env.VUE_APP_AUTH0_DOMAIN,
  "client_id": process.env.VUE_APP_AUTH0_CLIENT_ID,
  "redirect_uri":  process.env.VUE_APP_AUTH0_REDIRECT_URL,
  "audience":"https://backend.ject.ai/"
}, callbackRedirect).then((auth) => {
  app.use(auth)
  app.component('font-awesome-icon', FontAwesomeIcon)
  globalComponents(app);
  utils(app);
  app.mount('#app')
})




