

import {defineComponent, ref, onMounted} from "vue";
import {slugify} from 'transliteration';
import {client as JECT} from "@/utils/ject";
import {useI18n} from "vue-i18n";


export default defineComponent({
  setup() {

    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    const personas = ref({})
    const to = (key,persona)=>{
      return slugify(key+'-'+persona.name+'-'+persona.type)
    }

    onMounted(()=>{

      JECT.quest('personas').then((response)=>{
        personas.value = response.data
      }).catch((error)=>{
        console.error(error)
      })

    })

    return {
      t,
      to,
      personas,
      slugify
    }


  }
})


