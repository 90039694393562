import {computed} from "vue";

const definition = ():any => {

  return {

    props: ['id', 'title', 'selected', 'data'],
    emits: ['selected'],

    setup(props, ctx) {

      const setSelected = (id) => {
        cash('#' + props.id).dropdown('hide')
        ctx.emit('selected', {'id': props.id, 'selection': id})
      }
      const isSelected = (id) => {
        return props.selected === id
      }

      const selectedName = computed(() => {

        const sel = props.data.find((option) => option.id === props.selected)
        if (sel) {
          return sel.name
        }
        return "Select"
      })

      return {
        setSelected,
        isSelected,
        selectedName
      }
    }

  }
}

export {
  definition
}
