export const freshData = ():any=>{
  return {
    total:0,
    numberOfPages: 0,
    currentPage: 1,
    displayed:[],
    cards:[],
    graph:{},
    queryData: {
      articles:[]
    },
  }
}
export const freshBookmarks = ():any=>{
  return {
    total:0,
    numberOfPages: 0,
    currentPage: 1,
    displayed:[],
    data:[]
  }
}

export const defaultSearch = ():any=>{
  return {
    query: '',
    time: 'month',
    sort: 'relevance',
    lang: 'en',
    sourceLang: 'en',
    countries: '',
    sources: '',
    caegory: 'news|science',
    dimension: 'all',
  }
}

export const state = {
  search: defaultSearch(),
  lang:'en',
  loading: false,
  hasResults: false,
  dimensions:{
    'all':{
      icon: "CheckCircleIcon",
      alt: "TOOLTIP_DIMENSION_ANY",
      title: "MENU_EVERYTHING",
      state: "idle",
      data: freshData()

    },
    'evidence': {
      icon: "TargetIcon",
      title: "MENU_BACKING",
      alt: "TOOLTIP_DIMENSION_EVIDENCE",
      state: "idle",
      data: freshData()
    },
    'individual':{
      icon: "UserCheckIcon",
      title: "MENU_INDIVIDUAL",
      alt: "TOOLTIP_DIMENSION_INDIVIDUAL",
      state: "idle",
      data: freshData()
    },
    'casual':{
      icon: "RotateCcwIcon",
      title: "MENU_CASUAL",
      alt: "TOOLTIP_DIMENSION_CAUSAL",
      state: "idle",
      data: freshData()
    },
    'ramification':{
      icon: "RotateCwIcon",
      title: "MENU_RAMIFICATION",
      alt: "TOOLTIP_DIMENSION_RAMIFICATION",
      state: "idle",
      data: freshData()
    },


  },
  bookmarks:freshBookmarks()
};

// Declare state
export type State = typeof state;
