import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {App} from "vue";


TimeAgo.addLocale(en)
const timeago =new TimeAgo()

const install = (app: App): void => {
  app.config.globalProperties.$timeago = timeago
  app.provide("ta", timeago);
};

export { install as default };
