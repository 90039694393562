<template>
  <div class="content">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-3xl font-medium text-theme-5 mr-auto">{{ t('PAYMENT.TITLE') }}</h2>
    </div>
    <!-- BEGIN: Pricing Layout -->
    <div class="intro-y box flex flex-col lg:flex-row mt-5">
      <div class="intro-y flex-1 px-5 py-16">
        <div class="text-2xl font-medium text-center mt-10">{{t('PAYMENT.FREELANCERS.TITLE')}}</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5 h-1/4">
          <ul class="list-unstyled mt-3 mb-4" v-html="t('PAYMENT.FREELANCERS.FEATURES')">
          </ul>
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            35.00
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-16 mt-1"
            v-html="t('PAYMENT.PRICE')"></span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 hover:bg-theme-3 rounded-full mx-auto mt-8"
        >
          {{t('PAYMENT.PURCHASE')}}
        </button>
      </div>
      <div
        class="intro-y border-b border-t lg:border-b-0 lg:border-t-0 flex-1 p-5 lg:border-l lg:border-r border-gray-200 dark:border-dark-5 py-16"
      >
        <div class="text-2xl font-medium text-center mt-10">{{t('PAYMENT.NEWSROOMS.TITLE')}}</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5 h-1/4">
          <ul class="list-unstyled mt-3 mb-4" v-html="t('PAYMENT.NEWSROOMS.FEATURES')">

          </ul>
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            40.00
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-16 mt-1"
              v-html="t('PAYMENT.PRICE')"></span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 hover:bg-theme-3 rounded-full mx-auto mt-8"
        >
          {{t('PAYMENT.PURCHASE')}}
        </button>

      </div>
      <div class="intro-y flex-1 px-5 py-16">
        <div class="text-2xl font-medium text-center mt-10">{{t('PAYMENT.EDUCATIONAL.TITLE')}}</div>
        <div class="text-gray-700 dark:text-gray-600 text-center mt-5 h-1/4">
          <ul class="list-unstyled mt-3 mb-4" v-html="t('PAYMENT.EDUCATIONAL.FEATURES')">

          </ul>
        </div>
        <div class="flex justify-center">
          <div class="relative text-5xl font-semibold mt-8 mx-auto">
            500.00
            <span
              class="absolute text-2xl top-0 right-0 text-gray-500 -mr-16 mt-1"
              v-html="t('PAYMENT.PRICE')"></span
            >
          </div>
        </div>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 hover:bg-theme-3 rounded-full mx-auto mt-8"
        >
          {{t('PAYMENT.PURCHASE')}}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import {defineComponent} from "vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
  setup(){

    const {t} = useI18n()

    return {t}
  }
});
</script>
