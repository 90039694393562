import createAuth0Client from '@auth0/auth0-spa-js'
import { computed, reactive, watchEffect } from 'vue'
import router from "@/router";
import {tokenAuth} from "./auth.token"
import {useStore} from "@/store";

const store=useStore()

let client
const state = reactive({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null,
})

async function handleRedirectCallback() {
  state.loading = true

  try {
    await client.handleRedirectCallback()
    state.user = await client.getUser()
    state.isAuthenticated = true
  } catch (e) {
    state.error = e
  } finally {
    state.loading = false
  }
}

function loginWithRedirect(o) {
  return client.loginWithRedirect(o)
}


function getTokenSilently(o) {
  return client.getTokenSilently(o)
}


function logout() {
  const o = {
    returnTo: window.location.origin
  }
  console.log("Auth Logout",o)
  store.dispatch('user/TOKEN_LOGIN','')
  return client.logout(o)
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getTokenSilently,
  handleRedirectCallback,
  loginWithRedirect,
  logout
}

export const routeGuard = (to, from, next) => {
  const { isAuthenticated, user, loading, loginWithRedirect } = authPlugin
  const verify = () => {
    // If the user is authenticated, continue with the route

    if(to.path === '/plugin'){
      return next()
    }

    if (isAuthenticated.value) {
      return next()
    }

    if(store.getters['user/token']){
      state.isAuthenticated=false
      state.user=false
      return tokenAuth.verify().then((response)=>{

        if(!response){
          return next({name:'plugin', props:{error:true}})
        }
        state.isAuthenticated=true
        state.user=response
        state.loading=false
        return next()
      }).catch(()=>{
        return next({name:'plugin', props:{error:true}})
      })
    }else{
      // Otherwise, log in
      loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    }
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify()
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify()
    }
  })

}
export const callbackRedirect = (appState) => {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
}

export const setupAuth = async (options, callbackRedirect) => {
  client = await createAuth0Client({
    ...options,
  })

  try {
    // If the user is returning to the app after authentication

    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      // handle the redirect and retrieve tokens
      const { appState } = await client.handleRedirectCallback()

      // Notify subscribers that the redirect callback has happened, passing the appState
      // (useful for retrieving any pre-authentication state)
      callbackRedirect(appState)
    }
  } catch (e) {
    console.error(e)
    state.error = e
  } finally {
    // Initialize our internal authentication state
    state.isAuthenticated = await client.isAuthenticated()
    state.user = await client.getUser()
    state.loading = false
  }

  return {
    install: (app) => {
      app.config.globalProperties.$auth = authPlugin
      app.provide('authPlugin',authPlugin)
    },
  }
}
