
import {defineComponent} from 'vue';

export default defineComponent({
  props: {
    identifier: {
      type : String,
      required: true
    },
    title: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },

  },
   setup(){
      return {}
    }
  })

