<template>
  <div class="content">
    <h2 class="text-3xl font-medium text-theme-5 block my-6 text-center">JECT.ai</h2>
    <div class="w-12 mx-auto">
      <img
        class="block"
        :src="require(`@/assets/images/logo.svg`)"
      />
    </div>
    <!-- BEGIN: Pricing Layout -->
    <div class="intro-y box flex flex-col lg:flex-row mt-5">
      <div class="intro-y flex-1 px-5 py-12">
        <input
          v-model="token" type="password"
          :class="'input w-full border mt-2 ' + (hasError ? 'border-color-red' : '')"
          :placeholder="t('PLUGIN.INPUT_TOKEN_PLACEHOLDER')"/>
        <button
          type="button"
          class="button button--lg block text-white bg-theme-1 hover:bg-theme-3 rounded-full mx-auto mt-8"
          @click.prevent="login"
        >
          {{t('PLUGIN.LOGIN')}}
        </button>
        <p
          class="my-6"
        >Use your JECT.ai token to access the Extension</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import {computed, defineComponent, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "@/store";
import {useRouter} from "vue-router";

export default defineComponent({
  props:['error'],
  setup(props){

    const {t} = useI18n()
    const token=ref('')
    const store= useStore()
    const router= useRouter()
    const hasError= computed(()=> props.error )
    const url=computed(()=> process.env.VUE_APP_AUTH0_REDIRECT_URL)

    const login=()=>{
      console.log("Setting plugin token ",token.value)
      if(token.value){
        store.commit('user/SET_TOKEN',token.value)
        router.push("/")
      }
    }

    return {
      t,
      url,
      login,
      token,
      hasError
    }
  }
});
</script>
