import {ActionContext, ActionTree} from "vuex";
import {State as RootState} from "@/store";
import {State as LocalState} from "./state";
import {Mutations} from "./mutations";
import {MutationTypes} from "./mutation-types";
import {client as JECT} from "@/utils/ject";

// Action enums
enum ActionTypes {
  SET = "SET",
  FETCH = "FETCH"
}

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET]({commit}: AugmentedActionContext, body:any): void;
  [ActionTypes.FETCH]({commit}: AugmentedActionContext, id:string): void;

}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET]({commit}: AugmentedActionContext, body) {
    commit(MutationTypes.DATA, body)
  },
  [ActionTypes.FETCH]({commit}: AugmentedActionContext, id) {

    JECT.wordcloud(id).then((response)=>{
      if(response.data.length){
        commit(MutationTypes.DATA, response.data)
      }
    })
  }


};
