<template>
  <div
    :key="card.id"
    class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box card card-combination"
    id="cards-{{ type }}-key"
  >
    <div
      class="flex justify-between border-b border-gray-200  px-5 py-4"
    >
      <div>
      </div>
      <div>
        <div class=" pt-1 ml-3"
             v-if="viewState !== 'primary'">
          <a
            href=""
            class="w-5 h-5 text-gray-600"
            @click.prevent="showState('primary')"
          >
            <XIcon class="w-5 h-5"/>
          </a>
        </div>
        <div class="dropdown pt-1 ml-3"
             :id="type+'-dropdown'"
             v-if="viewState === 'primary'">
          <a

            href="javascript:;"
            class="dropdown-toggle w-5 h-5 text-gray-600"
          >
            <MoreVerticalIcon class="w-5 h-5"/>
          </a>
          <div class="dropdown-box w-80">
            <div class="dropdown-box__content box p-2">
              <a
                href="#"
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white hover:bg-theme-3 hover:text-white rounded-md"
                @click.prevent="showState('explain')"
              >
                {{t('CARD_COMBINATION_DROPDOWN_EXPLAIN')}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-5">
      <h1>{{t('CONTENT_COMBINATIONS_TITLE')}}</h1>

      <div v-if="viewState === 'primary'">

        <div
          v-for="(combination,key) in card.primary.combinations"
          :key="key" class="mb-5">

          <p class="text-base" v-html="t('CARD_COMBINATION_EXPLORE', {'entities': joinEntities(combination.entities), 'topic':combination.topic})"></p>

          <div class="card-pills">
            <CardPill
              v-for="(concept,conceptKey) in  filterConcepts(combination.entities)"
              :key="conceptKey"
              :title="concept.label"
              :type="concept['generic-type']"
              size="3"
              hasWiki="true"
              :id="`${type}-${key}-${conceptKey}`"
            />


          </div>

        </div>

      </div>
      <div v-if="viewState === 'explain'">
        <h3 class="block font-bold mb-5">{{ t('EXPLANATION_LABEL_TITLE')}}</h3>
        <p> {{ t('CARD_COMBINATION_EXPLAIN', {'topic': 'topic'})}}</p>
      </div>
    </div>

  </div>
</template>
<script lang="ts">

import {ref, defineComponent} from 'vue';
import {useI18n} from "vue-i18n";

export default defineComponent({
  props: ['type', 'card', 'query'],
  setup(props) {

    const { t  } = useI18n()

    const combinationKeys = ["person","organisation","place","thing"]
    const viewState = ref('primary')

    const showState = (state) => {
      viewState.value = state
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }


    const filterConcepts = (entities) =>{

      const ret = []

      for(const key of combinationKeys){
        if(entities[0][key]){
          ret.push(
            entities[0][key]
          )
        }
      }

      return ret

    }

    const joinEntities = (entities) =>{

      const ret = []

      for(const key of combinationKeys){
        if(entities[0][key]){
          ret.push(
            entities[0][key].label
          )
        }
      }

      return ret.join(", ")

    }


    return {
      t,
      viewState,
      showState,
      joinEntities,
      combinationKeys,
      filterConcepts

    }

  }
})

</script>
