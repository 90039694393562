import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import {defaultSearch, freshBookmarks, freshData, State as LocalState} from "./state";
import {client as JECT} from "@/utils/ject";



const PAGE_SIZE=10
// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.CLEAR_DATA](state: S): void;
  [MutationTypes.SET_SEARCH_LOADING](state: S, loading:boolean): void;
  [MutationTypes.DIMENSION_SET_STATE](state: S, {dimension:string , s}): void;
  [MutationTypes.CLEAR_DIMENSION_DATA](state: S, dimension:string): void;
  [MutationTypes.SET_DIMENSION_DATA](state: S, {dimension:string, data: any}): void;
  [MutationTypes.SET_GRAPH](state: S, {dimension, type, data }): void;
  [MutationTypes.SELECT_DIMENSION](state: S, dimension:string): void;
  [MutationTypes.SELECT_LANGUAGE](state: S, locale:string): void;
  [MutationTypes.SET_CURRENT_PAGE](state: S, page:number): void;
  [MutationTypes.SET_SEARCH](state: S, search): void;
  [MutationTypes.BOOKMARK](state: S, {id:string,bookmark:boolean}): void;
  [MutationTypes.BOOKMARKS](state: S, data): void;
  [MutationTypes.BOOKMARKS_PAGE](state: S, page): void;
  [MutationTypes.BOOKMARKS_CLEAR](state: S): void;
  [MutationTypes.SET_CARD_DATA](state: S, {dimension, type,data}): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.CLEAR_DATA](state: LocalState){
    state.search= defaultSearch()
    for(const key in state.dimensions){
      state.dimensions[key].data = freshData()
    }

  },
  [MutationTypes.SET_SEARCH_LOADING](state: LocalState, loading){
    state.loading =loading
  },

  [MutationTypes.DIMENSION_SET_STATE](state: LocalState, object){
    state.dimensions[object.dimension].state=object.s
  },
  [MutationTypes.CLEAR_DIMENSION_DATA](state: LocalState, dimension:string){
    state.dimensions[dimension].data = freshData()
  },
  [MutationTypes.SET_DIMENSION_DATA](state: LocalState, {dimension,data}){

    let total = 0
    if(data.articles){
      total = data.articles.length
    }

    let pages = 1
    if(total > 0){
      pages = Math.ceil(total/PAGE_SIZE)
    }

    state.dimensions[dimension].data.total= total
    state.dimensions[dimension].data.numberOfPages= pages
    state.dimensions[dimension].data.currentPage = 1
    state.dimensions[dimension].data.queryData = data
  },

  [MutationTypes.SET_CURRENT_PAGE](state: LocalState,page){

    const dimension = state.search.dimension;

    state.dimensions[dimension].data.currentPage = page
    const newData = state.dimensions[dimension].data.queryData.articles.slice(
      (page-1)*PAGE_SIZE,
      ((page-1)*PAGE_SIZE)+PAGE_SIZE
    );
    state.dimensions[dimension].data.displayed = state.dimensions[dimension].data.displayed.concat(newData)

    for (const article of newData) {

      JECT.article(
        article.id
      ).then(response => {

        const a = state.dimensions[dimension].data.displayed.find(x => x.id === article.id)
        a.content = response.data
      });

      JECT.bookmarked(
        article.id
      ).then(response => {
        article.bookmarked = response.data
      }).catch((error) => {
        console.error(error)
        article.bookmarked = false
      })

    }
  },
  [MutationTypes.SET_SEARCH](state: LocalState, search){
    state.search = search
  },
  [MutationTypes.SELECT_DIMENSION](state: LocalState, dimension:string){
    const newSearch = Object.assign({},state.search)
    newSearch.dimension=dimension
    state.search= newSearch
  },

  [MutationTypes.SELECT_LANGUAGE](state: LocalState, locale:string){
    const newSearch = Object.assign({}, defaultSearch())
    newSearch.lang=locale
    state.search= newSearch
    for(const key in state.dimensions){
      state.dimensions[key].data = freshData()
    }

  },

  [MutationTypes.BOOKMARK](state: LocalState, {id,bookmark}){

    const a = state.dimensions[state.search.dimension].data.displayed.find(x => x.id === id)
    if(a){
      a.bookmarked = bookmark
    }

    state.bookmarks.displayed = state.bookmarks.displayed.filter(x => x.content.article.id !== id)

  },
  [MutationTypes.SET_CARD_DATA](state: LocalState, {dimension, type, data}){
    state.dimensions[dimension].data.cards[type] = data
  },
  [MutationTypes.SET_GRAPH](state: LocalState, {dimension, type, data}){
    state.dimensions[dimension].data.graph[type] = data
  },

  [MutationTypes.BOOKMARKS](state: LocalState, bookmarks){
    state.bookmarks = freshBookmarks()
    state.bookmarks.data = bookmarks
    const total = bookmarks.length
    let pages = 1
    if(total > 0){
      pages = Math.ceil(total/PAGE_SIZE)
    }
    state.bookmarks.total = total
    state.bookmarks.numberOfPages = pages
  },
  [MutationTypes.BOOKMARKS_PAGE](state: LocalState, page){

    const newData = state.bookmarks.data.slice(
      (page-1)*PAGE_SIZE,
      ((page-1)*PAGE_SIZE)+PAGE_SIZE
    );
    state.bookmarks.displayed = state.bookmarks.displayed.concat(newData)
  },
  [MutationTypes.BOOKMARKS_CLEAR](state: LocalState){
    state.bookmarks = freshBookmarks()
  },




};
