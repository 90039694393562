

import {inject, ref, defineComponent, computed, onMounted} from 'vue';
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import moment from "moment";
import VisualizationDialog from "@/views/dimensions/cards/VisualizationDialog.vue";
import VisualizationControls from "@/views/dimensions/cards/VisualizationControls.vue";


export default defineComponent({
  components: {VisualizationControls, VisualizationDialog},
  props: ['type', 'card', 'query'],
  component: [
    VisualizationDialog,
    VisualizationControls
  ],
  setup(props) {
    const {t} = useI18n()
    const store = useStore()
    const lodash = inject<any>('lodash')
    const topEntities = computed(() => props.card.primary.entities.top)
    const middleEntities = computed(() => props.card.primary.entities.middle)
    const bottomEntities = ref([])
    const timeFilter = computed(() => store.state.main.search.time)


    const setRandomEntities = () => {
      bottomEntities.value = lodash.sampleSize(props.card.primary.entities.bottom, 9);
    }
    const refreshKey = () => {
      setRandomEntities()
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }


    const viewState = ref('primary')
    const showState = (state) => {
      viewState.value = state
      cash("#" + props.type + "-dropdown").dropdown("hide")
    }

    const mostSources = computed(() => {
      return props.card.secondary.mostSourceNames.slice(0, 3).join(", ")
    })

    onMounted(() => {
      setRandomEntities()
    })

    const graphData = computed(() => {
      const ret = {
        labels: [],
        datasets: [{
          label: 'Frequency',
          barPercentage: 2,
          barThickness: 6,
          maxBarThickness: 8,
          hidden:false,
          minBarLength: 2,
          backgroundColor: [],
          data: []
        },
        ]
      }

      const pos = ["top", "middle", "bottom"]
      pos.forEach((a) => {

        ret.labels = ret.labels.concat(lodash.map(props.card.primary.entities[a], (e) => e.label))
        ret.datasets[0].data = ret.datasets[0].data.concat(lodash.map(props.card.primary.entities[a], (e) => e.count))
      })

      for (const i in ret.labels) {
        const color = Math.floor(Math.random() * 16777215).toString(16)
        ret.datasets[0].backgroundColor.push("#" + color)
      }

      return ret

    })
    const entitiesGraphData = ref(
      {
        "data": {'datasets':[]},
        "options": {
          legend: {
            display: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        }
      }
    );
    const showGraph = (graph: string) => {
      graphSelected.value=true
      cash(`#${graph}-superlarge-modal-size-preview`).modal("show");
    }
    const showEntitiesGraph = (entities, graph: string) => {

      const data = {
        labels: [],
        datasets: [],
      };

      // Add all the timestamps for all the entities in this section
      entities.forEach((e) => {
        Object.keys(e.ts).forEach((k) => {

          let key = k
          if (timeFilter.value.startsWith('year')) {
            const parts = k.split('-')
            key = parts[0] + '-' + parts[1]
          }

          if (data.labels.indexOf(key) < 0) {
            data.labels.push(key)
          }
        })
      })


      data.labels = lodash.sortBy(data.labels)
      entities.forEach((e) => {

        const d = {
          label: e.label,
          data: [],
          borderWidth: 2,
          borderColor: "#" + (Math.floor(Math.random() * 16777215).toString(16)),
          backgroundColor: "transparent",
          pointBorderColor: "transparent",
        }


        const dates = Object.keys(e.ts)
        const groupedTs = {}

        dates.forEach((ts) => {

          let key = ts
          if (timeFilter.value.startsWith('year')) {
            const parts = ts.split('-')
            key = parts[0] + '-' + parts[1]
          }
          if (key in groupedTs) {
            groupedTs[key] = groupedTs[key] + e.ts[ts]
          } else {
            groupedTs[key] = e.ts[ts]
          }
        })

        data.labels.forEach((k) => {
          d.data.push(
            groupedTs[k] || 0
          )
        })

        data.datasets.push(d)
      })
      entitiesGraphData.value = {
        "data": data,
        "options": {
          legend: {
            display: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
        }
      }
      cash('#graph-entities-superlarge-modal-size-preview').modal("show");

    }

    const sourcesGraph = computed(() => {

      let sources = null

      try {
        sources = store.state.main.dimensions[store.state.main.search.dimension].data.cards.visualization.sources
      } catch (e) {

      }


      const data = {
        labels: [],
        datasets: [{
          label: "Sources",
          backgroundColor: [],
          hoverBackgroundColor: [],
          borderWidth: 5,
          borderColor: "#ffff",
          hidden:false,
          data: []
        }]
      }

      if (sources == null) {
        return {
          data: data,
          options: {
            cutoutPercentage: 40,
          }
        }
      }

      const totalSum = lodash.sumBy(sources.items, (e) => {
        return e.quantity
      })

      lodash.reduce(sources.items, (e, n) => {
        if (n.quantity / totalSum > 0.03) {
          e.push(n.name)
        }
        return e
      }, data.labels)

      data.labels.forEach((name) => {
        data.datasets[0].data.push(
          lodash.find(sources.items, (i) => i.name === name).quantity || 0
        )
      })

      for (const i in data.labels) {
        const color = Math.floor(Math.random() * 16777215).toString(16)
        data.datasets[0].backgroundColor.push("#" + color)
      }


      return {
        data: data,
        options: {
          cutoutPercentage: 40,
        }
      }


    })
    const countriesGraph = computed(() => {

      let countryData = null

      try {
        countryData = store.state.main.dimensions[store.state.main.search.dimension].data.graph.country || null

      } catch (e) {
      }

      if (countryData == null) {
        return {
          data: {datasets:[]},
          countries: [],
          lowCountries: [],
          options: {
            cutoutPercentage: 40,
          }
        }
      }

      const data = {
        labels: lodash.map(countryData, (e) => {
          if (e.key === null) {
            return 'None'
          }
          return e.key
        }),
        datasets: [{
          backgroundColor: [
            "#FF8B26",
            "#FFC533",
            "#285FD3",
            "#c8447f",
            "#20272d",
            "#E6F3FF",
            "#2449AF",
            "#284EB2",
            "#395EC1"
          ],
          hoverBackgroundColor: [
            "#FF8B26",
            "#FFC533",
            "#285FD3",
            "#c8447f",
            "#20272d",
            "#E6F3FF",
            "#2449AF",
            "#284EB2",
            "#395EC1"
          ],
          hidden:false,
          borderWidth: 5,
          borderColor: "#fff",
          data: lodash.map(countryData, (e) => {
            return parseInt(e.value)
          })
        },
        ]
      }

      const countries = data.labels.filter((e) => {
        return e !== 'None'
      })

      return {
        data: data,
        countries: countries,
        lowCountries: [countries.pop(), countries.pop(), countries.pop()],
        options: {
          cutoutPercentage: 40,
        }
      }


    })
    const sentimentGraph = computed(() => {

      const sentimentData = store.state.main.dimensions[store.state.main.search.dimension].data.graph.sentiment || null

      const data = {
        labels: lodash.map(sentimentData, (e) => {
          return moment(e.time).format("DD/MM/YYYY");
        }),
        datasets: [{
          label: 'Sentiment',
          barPercentage: 0.5,
          barThickness: 6,
          fill: false,
          maxBarThickness: 8,
          minBarLength: 2,
          backgroundColor: "#3160d8",
          data: lodash.map(sentimentData, (e) => {
            return e.value
          })

        },
        ]
      }

      return {
        data: data,
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                fontSize: "12",
                fontColor: "#777777",
                callback: function (value) {
                  return value;
                }
              },
              gridLines: {
                color: "#D8D8D8",
                zeroLineColor: "#D8D8D8",
                borderDash: [2, 2],
                zeroLineBorderDash: [2, 2],
                drawBorder: false
              }
            }]
          }
        }
      }


    })
    const graphSelected=ref(true)

    const toggleGraphSelection = (graph) => {

      if(graph === 'graphData'){
        // graphData.value.datasets.forEach( e=>{
        //   e.hidden=graphSelected.value
        // })
      }

      if(graph === 'entitiesGraphData'){
        entitiesGraphData.value.data.datasets.forEach( e=>{
          e.hidden=graphSelected.value
        })
      }

      if(graph === 'sourcesGraph'){
        // sourcesGraph.value.data.datasets[0].labels.forEach( e=>{
        //
        //   console.log(e)
        //   e.hidden=graphSelected.value
        // })
      }

      if(graph === 'countriesGraph'){
        // countriesGraph.value.data.datasets.forEach( e=>{
        //   e.hidden=graphSelected.value
        // })
      }


      graphSelected.value = !graphSelected.value
    }


    return {
      t,
      refreshKey,
      viewState,
      showState,
      topEntities,
      middleEntities,
      bottomEntities,
      mostSources,
      sourcesGraph,
      countriesGraph,
      sentimentGraph,
      showGraph,
      showEntitiesGraph,
      entitiesGraphData,
      graphSelected,
      toggleGraphSelection,
      graphData,
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              fontSize: "12",
              fontColor: "#777777",
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              fontSize: "12",
              fontColor: "#777777",
              callback: function (value) {
                return value;
              }
            },
            gridLines: {
              color: "#D8D8D8",
              zeroLineColor: "#D8D8D8",
              borderDash: [2, 2],
              zeroLineBorderDash: [2, 2],
              drawBorder: false
            }
          }]
        }
      }
    }
  }
})
