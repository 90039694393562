export const message = {
  "MENU_EVERYTHING": "Everything",
  "MENU_BACKING": "Backing & Bewijs",
  "MENU_INDIVIDUAL": "Individueel",
  "MENU_CASUAL": "Casual",
  "MENU_RAMIFICATION": "Ramification",
  "FILTER_YEAR": "Tijd",
  "FILTER_ORDER": "Order",
  "COPY_PLUGIN_TOKEN": "Copy Plugin Token",
  "USER_LOGOUT_TITLE": "Logout",
  "NOTIFICATIONS_TITLE": "Meldingen",
  "BTN_EXPLORE_MORE": "Meer ontdekken",
  "PILL_MODAL_WIKI": "Verken de volgende artikelen die <em>{ titel }</em> linken aan uw onderwerpen",
  "BTN_BOOKMARK": "Bladwijzer",
  "EXPLANATION_LABEL_TITLE": "Wat is deze kaart?",
  "CARD_ARTICLE_DROPDOWN_COPY": "Artikelverwijzing kopiëren",
  "CARD_ARTICLE_DROPDOWN_REFRESH": "Vernieuw mogelijke invalshoeken",
  "CARD_ARTICLE_DROPDOWN_WC": "Tonen als woordwolk",
  "CARD_ARTICLE_DROPDOWN_SIMILAR": "Vergelijkbare verhalen via JECT.AI",
  "CARD_ARTICLE_DROPDOWN_SIMILAR_GOOGLE": "Vergelijkbare verhalen via Google",
  "CARD_ARTICLE_DROPDOWN_EXPLAIN": "Leg deze kaart uit",
  "CARD_COMBINATION_DROPDOWN_EXPLAIN": "Leg deze kaart uit",
  "CONTENT_COMBINATIONS_TITLE": "JECT.AI heeft interessante combinaties van onderwerpen geïdentificeerd om over na te denken",
  "CARD_COMBINATION_EXPLORE": "Verken nieuwsinvalshoeken waarbij <em>{ entiteiten }</em> en <em>{onderwerp }</em> betrokken kunnen zijn.",
  "CARD_COMBINATION_EXPLAIN": "JECT.AI heeft deze onderwerpen ontdekt om te combineren uit miljoenen artikelen die het heeft ontdekt en geïndexeerd. De artikelen zijn teruggevonden vanwege berekende overeenkomsten tussen termen zoals <em>{topic}</em> en onderwerpen die in de artikelen worden gemeld. JECT.AI berekende dat elk artikel voldoende gelijkenis vertoont op basis van de door u geselecteerde strategie en de door u gedefinieerde instellingen. Vervolgens selecteerde en presenteerde het onderwerpen die in de meeste opgehaalde artikelen werden gerapporteerd.",
  "CARD_INTELLIGENCE_DROPDOWN_EXPLAIN": "Leg deze kaart uit",
  "CARD_INTELLIGENCE_TITLE": "JECT.AI heeft verwante personen ontdekt, gebalanceerd naar geslacht en etniciteit",
  "CARD_INTELLIGENCE_JOURNALISTS": "Ontdek de diverse journalisten die over gerelateerde verhalen schrijven",
  "CARD_INTELLIGENCE_SCHOLARS": "Ga de dialoog aan met de breedte van wetenschappers die hebben bijgedragen aan gerelateerd onderzoek",
  "CARD_INTELLIGENCE_TWITTER": "Experts die actief zijn op Twitter",
  "CARD_INTELLIGENCE_EXPLAIN_A": "JECT.AI heeft deze journalisten, wetenschappers en andere experts ontdekt die hebben geschreven over gepubliceerde thema's die gerelateerd zijn aan jouw onderwerp of onderwerpen. De journalisten zijn de namen van journalisten die artikelen hebben geschreven die zijn ontdekt uit de miljoenen artikelen die het heeft ontdekt en geïndexeerd. De wetenschappers en andere experts zijn de namen van wetenschappers die als auteur worden genoemd in bronnen zoals Google Scholar.",
  "CARD_INTELLIGENCE_EXPLAIN_B": "Bovendien moedigt JECT.AI meer diverse stemmen in het nieuws aan. Het streeft ernaar om elke set van gepresenteerde journalisten en wetenschappers in evenwicht te brengen naar geslacht en naar etniciteit, op basis van hun namen.",
  "CARD_LANDING_DROPDOWN_ABOUT": "Over deze kaart",
  "CARD_LANDING_DROPDOWN_MORE": "Meer hoeken waar weinig over geschreven wordt",
  "CARD_LANDING_DROPDOWN_EXPLORE": "Verken de hoeken van de wilde kaart",
  "CARD_LANDING_TITLE": "JECT heeft meer dan {totaal} gerelateerde verhalen ontdekt",
  "CARD_LANDING_SUBTITLE": "<em>{totaal}</em> hiervan zijn positief",
  "CARD_LANDING_CONTENT_MANY": "Verken nieuwe invalshoeken waar velen over schrijven",
  "CARD_LANDING_CONTENT_SOME": "Verken nieuwe invalshoeken waar sommigen over schrijven",
  "CARD_LANDING_CONTENT_FEW": "En verken nieuwe invalshoeken waar slechts enkelen over schrijven",
  "CARD_LANDING_EXTRA_CONTENT": "<em>{pastWeek}</em> werden gepubliceerd in de afgelopen week,<em>{pastMonth}</em> in de afgelopen maand en <em>{pastYear} in het afgelopen jaar, de meeste werden gepubliceerd in <em>{mostSources}</em>",
  "CARD_LANDING_EXTRA_SUBTITLE": "Verder biedt JECT ook een set van wildcard invalshoeken, waarvan u er een aantal nuttig kunt vinden:",
  "CARD_LANDING_EXPLAIN_A": "JECT.AI heeft deze onderwerpen ontdekt uit miljoenen artikelen die het heeft ontdekt en geïndexeerd. De artikelen zijn opgehaald vanwege berekende overeenkomsten tussen termen zoals <em>{ query }</em> en onderwerpen die in de artikelen worden gemeld.",
  "CARD_LANDING_EXPLAIN_B": "JECT.AI berekende dat elk artikel voldoende gelijksoortig is op basis van de strategie die je hebt geselecteerd en de instellingen die je hebt gedefinieerd. Vervolgens berekende het onderwerpen gerapporteerd in veel van de artikelen, sommige van deze artikelen, en slechts een paar.",
  "TOOLTIP_QUEST_DASHBOARD": "Schakel terug naar ontdekte hoeken",
  "TOOLTIP_QUEST_EXPLORE": "Bekijk de wetenschapsjournalistieke kenmerken",
  "DISCOVER_LOADING_MESSAGE": "JECT.AI is content voor u aan het ontdekken",
  "TOOLTIP_DIMENSION_EVIDENCE": "FEITEN EN STATISTIEKEN – Kwantitatieve informatie (bijv. data en statistieken) (coming soon)",
  "TOOLTIP_DIMENSION_INDIVIDUAL": "INDIVIDUËN – Wie zijn de hoofdrolspelers? Het perspectief van een individu (coming soon)",
  "TOOLTIP_DIMENSION_CAUSAL": "CAUSAAL – De achtergrond, de geschiedenis, de oorzaken (coming soon)",
  "TOOLTIP_DIMENSION_QUIRKY": "OPMERKELIJK – Satire, niet het voor de hand liggende (cartoons) (coming soon)",
  "TOOLTIP_DIMENSION_RAMIFICATION": "ENIGE INHOUD - Gebruik dit voor uw zoekopdracht",
  "TOOLTIP_DIMENSION_VISUALISATION": "DATA VISUALISATIES – Grafieken en infographics (coming soon)",
  "TOOLTIP_DIMENSION_ANY": "ALLES - Ontdekken zonder grenzen",
  "QUEST_ABOUT_TITLE": "Over",
  "QUEST_ABOUT_CONTENT": "<p>JECT.AI biedt gespecialiseerde begeleiding aan journalisten die over wetenschap schrijven. Deze begeleiding omvat:</p><ul class=\"list-disc ml-4 mb-5\"><li>Typische doelgroepen voor wetenschapsverhalen, van de enthousiaste tot de afhakende. Je publiek kennen is de sleutel tot effectief communiceren</li><li>Geproefde en geteste metaforen om wetenschap snel over te brengen. De huidige metaforen communiceren over vaccins, klimaatverandering en AI</li><li>Indicatoren van goede journalistiek over wetenschap. Gebruik ze om na te denken over nieuwe artikelen, om ze te verfijnen en te verbeteren, voordat je ze publiceert</li><li>Verschillende rollen die wetenschap en het publiek kunnen spelen in je verhalen. Gebruik ze om meer overtuigende verhalen te ontwikkelen</li><li>Eenvoudige uitleg die kan worden gebruikt om wetenschappelijke processen te beschrijven aan een niet-wetenschappelijk publiek</li></ul><p>Deze gespecialiseerde leidraad is ontwikkeld door het door de EU gefinancierde H2020 QUEST project. Meer informatie over de begeleiding, toolbox en podcasts vindt u hier [<a href=\"https://questproject.eu\" target=\"_blank\">https://questproject.eu</a>]</p>",
  "QUEST_PERSONAS_TITLE": "Wetenschappelijk Publiek",
  "QUEST_PERSONAS_PILL_MORE": "Meer",
  "QUEST_METAPHORS_TITLE": "Metaforen",
  "QUEST_STORY_TITLE": "Verhaalrollen",
  "QUEST_EXPLAINERS_TITLE": "Uitleggers",
  "QUEST_INDICATORS_TITLE": "Indicatoren",
  "TOOLTIP_QUEST_FEATURE_PERSONA": "PERSONAS - serie van wetenschap-afwijzende lezer persona's ",
  "TOOLTIP_QUEST_FEATURE_STORY_ROLE": "STORY ROLES - storytelling rollen aangepast aan wetenschapsjournalistiek ",
  "TOOLTIP_QUEST_FEATURE_EXPLAINER": "EXPLAINERS - uitleg over multi-source onderzoek, over hoe journals en peer-review te vertrouwen zijn, etc",
  "TOOLTIP_QUEST_FEATURE_METAPHOR": "METAPOREN - metaforen om 1 of 2 eenvoudige ideeën effectief te communiceren",
  "TOOLTIP_QUEST_FEATURE_INDICATOR": "KWALITEITSINDICATOREN - elementen van de praktijkprestaties die worden gebruikt om de kwaliteit te beoordelen",
  "LABEL_OPTIONS_PUBLISH_DATE": "Publicatiedatum",
  "LABEL_OPTIONS_PUBLISH_DATE_ANY": "Afgelopen jaren",
  "LABEL_OPTIONS_PUBLISH_DATE_1YEAR": "Afgelopen jaar",
  "LABEL_OPTIONS_PUBLISH_DATE_6MONTHS": "Afgelopen 6 maanden",
  "LABEL_OPTIONS_PUBLISH_DATE_1MONTH": "Afgelopen maand",
  "LABEL_OPTIONS_PUBLISH_DATE_1WEEK": "Afgelopen week",
  "LABEL_OPTIONS_PUBLISH_DATE_2DAYS": "Afgelopen 48 uur",
  "LABEL_FILTER_PUBLISH_DATE_ANY": "jaren",
  "LABEL_FILTER_PUBLISH_DATE_1YEAR": "jaar",
  "LABEL_FILTER_PUBLISH_DATE_1MONTH": "maand",
  "LABEL_FILTER_PUBLISH_DATE_1WEEK": "week",
  "LABEL_FILTER_PUBLISH_DATE_2DAYS": "48 uur",
  "LABEL_FILTER_PUBLISH_BREAKING": "Brekend",
  "LABEL_OPTIONS_SORT_TIME": "Tijd",
  "LABEL_OPTIONS_SORT_RELEVANCE": "Relevantie",
  "LABEL_OPTIONS_SORT_RANDOM": "Willekeurig",
  "LABEL_OPTIONS_SORT_SENTIMENT_POS": "Positief",
  "LABEL_OPTIONS_SORT_SENTIMENT_NEG": "Negatief",
  "LABEL_OPTIONS_SOURCE_SCIENCE": "Wetenschap",
  "LABEL_OPTIONS_SOURCE_NEWS": "Nieuws",
  "LABEL_OPTIONS_SOURCE_ALL": ",,Alle",
  "LABEL_OPTIONS_SOURCE": "Bron",
  "PLACEHOLDER_SEARCH_BOX": "Beschrijf uw nieuwsverhaal met onderwerpen",
  "PAYMENT": {
    "TITLE": "Pricing",
    "PRICE": "&euro;/mo",
    "PURCHASE": "NU KOPEN",
    "FREELANCERS": {
      "TITLE": "FREELANCERS",
      "FEATURES": "<li>Real-time nieuws aggregatie &amp; search</li><li>NLP verrijking</li><li>Creativity and Intelligence support</li><li>Priority email support</li><li>Help center toegang</li>"
    },
    "NEWSROOMS": {
      "TITLE": "NEWSROOMS",
      "FEATURES": "<li>Freelancing Plan</li><li>Integratie in belangrijkste CMS (Wordpress, etc.al)</li><li><em>Inclusief tot 10 gebruikerslicenties</em></li>"
    },
    "EDUCATIONAL": {
      "TITLE": "ONDERWIJSINSTELLINGEN",
      "FEATURES": "<li>Freelancing Plan</li><li>Integratie in belangrijkste CMS ( Wordpress, et.al)</li><li><em>Inclusief tot 10 gebruikerslicenties</em></li>"
    }
  },
  "ACCOUNT_BOOKMARKS_MENU": "Bladwijzers",
  "BOOKMARKS_EMPTY": "Bladwijzers Leeg",
  "BOOKMARK_META_DESCRIPTION": "Bladwijzer gemaakt <em>{date}</em> met behulp van query <strong>{query}</strong>",
  "PILL_MODAL_EMTPY_WIKI": "Er is niets meer te onderzoeken voor <em>{title}</em>"
}


