import { App } from "vue";
import helper from "./helper";
import lodash from "./lodash";
import timeago from "./timeago";
import pluginBus from "./eventbus";
import {install as ject} from "@/utils/ject";

export default (app: App): void => {
  app.use(helper);
  app.use(lodash);
  app.use(timeago);
  app.use(ject);
  app.use(pluginBus);
};
