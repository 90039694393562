

import {computed, defineComponent, inject, onMounted, ref, watch} from "vue";
import {useStore} from "@/store";
import SentimentComponent from "@/components/sentiment/Sentiment.vue";
import TimeAgoComponent from "@/components/timeago/TimeAgo.vue";
import SourceTag from "@/components/article/SourceTag.vue";
import Toastify from "toastify-js";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import moment from "moment";

export default defineComponent({
  props: ['article','dimension','query','bookmarkDate'],
  components: {
    SentimentComponent,
    TimeAgoComponent,
    SourceTag
  },
  setup(props) {
    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
    const store = useStore()
    const router = useRouter()
    const lodash = inject<any>('lodash')
    const entities = ref([])
    const viewState = ref('primary')

    // console.log(props.article)


    const hideDropdown = () => {
      cash("#"+props.article.id+"-dropdown").dropdown("hide")
    }
    const similarUrl = computed( ()=>{
      const similarSearch = Object.assign({},store.state.main.search)
      similarSearch.query=props.article.title
      return store.getters['main/searchPath'](router.currentRoute.value.path,similarSearch)
    })


    watch(
      () => props.article.content,
      () => {
        setRandomEntities()
      }
    )

    const refreshKey = () =>{
      setRandomEntities()
      hideDropdown()
    }

    const setRandomEntities = () => {


      entities.value = []
      if (props.article && props.article.content && props.article.content.concepts) {
        if (props.dimension === "individual"){
          let build = []
          const people = lodash.filter(props.article.content.concepts,(e) => e['generic-type'] ==='http://dbpedia.org/ontology/Person')
          const rest = lodash.filter(props.article.content.concepts,(e) => e['generic-type'] !=='http://dbpedia.org/ontology/Person')
          build = build.concat(people)
          if ( people.length < 7){
            build =  build.concat(lodash.sampleSize(rest, 7 - people.length))
          }

          entities.value = build

        }else{
          entities.value = lodash.sampleSize(props.article.content.concepts, 7)
        }
      }

    }
    const setBookmark = (id, bookmark) => {
      store.dispatch('main/SET_BOOKMARKED', {
        id: id,
        bookmarked: bookmark
      })
    }
    onMounted(() => {
      setRandomEntities()
    })

    const showState = (state) =>{
      viewState.value = state
      hideDropdown()
    }

    const copyReference= (text) =>{
      const $i = cash("#"+props.article.id+"-input")
      if(!$i.length){
        return
      }
      $i.val(text)
      $i[0].select()

      var successful = document.execCommand('copy');
      if(successful){
        Toastify({ text: "Copied \n"+$i.val(), duration: 2000, newWindow: true, close: true, gravity: "bottom", position: "middle", backgroundColor: "#38484e", stopOnFocus: true }).showToast()
      }
      hideDropdown()
    }

    const newQuery= () => {
      hideDropdown()
      return false
    }
    const showWordCloud = () => {
      hideDropdown()
      store.dispatch("wordcloud/FETCH", props.article.id)
    }

    const timeago = inject<any>('ta')

    const time = (dateStr )=>{
      return timeago.format(moment(dateStr).toDate())
    }


    return {
      t,
      time,
      viewState,
      showState,
      entities,
      refreshKey,
      setBookmark,
      copyReference,
      newQuery,
      hideDropdown,
      showWordCloud,
      similarUrl
    }

  }
})
