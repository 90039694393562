

import {defineComponent, onMounted, ref} from "vue";
import {client as JECT} from "@/utils/ject";

export default defineComponent({
  setup() {


    const data = ref({})

    onMounted(()=>{

      JECT.quest('indicators').then((response)=>{
        data.value = response.data
      }).catch((error)=>{
        console.error(error)
      })

    })
    return {
      data
    }
  }
})


